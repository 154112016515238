import { useReducer } from "react";

const initialState = { isError: false, isLoading: false };

const reducer = (state, action) => {
  switch (action.type) {
    case "SUCCESS":
      return { ...state, isLoading: false, isError: false };
    case "LOADING":
      return { ...state, isLoading: true, isError: false };
    case "ERROR":
      return { ...state, isLoading: false, isError: true };
    default:
      return state;
  }
};

function useMutate() {
  const [state, dispatch] = useReducer(reducer, initialState);

  const mutate = async (call) => {
    dispatch({ type: "LOADING" });
    const result = await call();

    // check if error
    if (!!result?.error || Object.keys(result?.data).includes("err")) {
      dispatch({ type: "ERROR" });
      if (Object.keys(result?.data).includes("err")) throw result?.data?.err;
      else throw new Error("Error while mutating");
    } else dispatch({ type: "SUCCESS" });
    return result;
  };

  return { mutate, isLoading: state.isLoading, isError: state.isError };
}
export default useMutate;
