import i18next from "i18next";
import { initReactI18next } from "react-i18next";
const resources = {
  en: {
    translation: {
      General: {
        forbidden: "Your are not allowed to view this page",
        cancel: "Cancel",
        delete: "Delete",
        update: "Update",
        created_by: "Created by",
        updated_by: "Updated by",
        show_inactive: "Show Inactive",
        active: "Active",
        inactive: "Inactive",
        date: "Date",
        status: "Status",
        name: "Name",
        edit: "Edit",
        description: "Description",
        type: "Type",
        content: "Content",
        save: "Save",
        upload: "Upload",
        reset: "Reset",
        error: "Error",
        no_data: "No data found",
        close: "Close",
        unset: "Unset",
        at: "at",
        the: "the",
        filter: "Filter",
        "choose-a-file": "Choose a file",
        "choose-multiple-files": "Choose one or multiple files",
        infinite: "Infinite",
        more: "More",
        next: "Next",
        forgot_password: "Forgot password?",
        create_new_password: "Create new password",
        copy_your_password: "Make sure to copy the password before resetting",
        reset_password: "Reset password",
        reset_success: "password reset successfully",
        reset_your_password: "Reset your password",
        enter_new_password: "Please enter a new password:",
        enter_your_email:
          "Please enter your email address to reset your password",
        check_your_email:
          "Your password has been reset. Please check your email to continue.",
        ok: "Ok",
        id: "Id",
        import: "Import",
        difficulty: {
          1: "Easy",
          2: "Medium",
          3: "Hard",
        },
        quiz: {
          1: "Easy Quiz",
          2: "Medium Quiz",
          3: "Hard Quiz",
        },
        question: {
          1: "Easy Question",
          2: "Medium Question",
          3: "Hard Question",
        },
        message: {
          create: {
            success: "Creation completed successfully",
            fail: "Creation failed",
          },
          update: {
            success: "Change made successfully",
            fail: "Change failed",
          },
          add: {
            success: "Added successfully",
            fail: "Add failed",
          },
          remove: {
            success: "Deletion completed successfully",
            fail: "Deletion failed",
          },
          delete: {
            success: "Deletion completed successfully",
            fail: "Deletion failed",
          },
          email: {
            sent: "An email has been sent to the instructor with his identifiers",
            not_sent: "Failed to send email",
            already_in_use: "Email already used",
          },
          import: {
            success: "Import completed successfully",
            fail: "Import failed",
          },
        },
      },
      CRM: "CRM",
      Shop: "Shop",
      Chat: "Chat",
      Sales: "Sales",
      Results: "Results",
      Invoice: "Invoice",
      Payment: "Payment",
      Profile: "Profile",
      Account: "Account",
      Pricing: "Pricing",
      Checkout: "Checkout",
      Sessions: "Sessions",
      Ecommerce: "Ecommerce",
      Dashboards: "Dashboards",
      "Enrolled Courses": "Enrolled Courses",
      "Course Completed": "Course Completed",
      "Course in Progress": "Course in Progress",
      "Learning Management": "Learning Management",
      "Welcome Back! Watson": "Welcome Back! Watson",
      "Job Management": "Job Management",
      "User & Contact": "User & Contact",
      "User List": "User List",
      "User Grid": "User Grid",
      "Contact List": "Contact List",
      "Contact Grid": "Contact Grid",
      "Invoice List": "Invoice List",
      "Curriculum List": "Curriculum List",
      "Invoice Details": "Invoice Details",
      "Create Invoice": "Create Invoice",
      "Product Details": "Product Details",
      "Billing Address": "Billing Address",
      "Payment Complete": "Payment Complete",
      "Admin Ecommerce": "Admin Ecommerce",
      "Product List": "Product List",
      "Product Grid": "Product Grid",
      "Create Product": "Create Product",
      "Upcoming Task": "Upcoming Task",
      "Study Time Last Week": "Study Time Last Week",
      "Your Downloads": "Your Downloads",
      "Course Status": "Course Status",
      Name: "Name",
      Cancel: "Cancel",
      Add: "Add",
      Import: {
        download_excel_file: "Download Excel file",
        instructions: "Instructions",
        import_instructions: "Import Instructions",
        instructions_desc: "Your Excel file must contain the following data:",
        required_fields: "*Required fields",
        import_success: "Import successful",
        import_fail: "Import failed",
        row: "Row",
        records: "Recordings",
        errors: "Errors",
        file_not_valid: "Invalid file",
        file_is_valid: "Valid file",
        import_success_result: "The import of your data was successful",
        download_template: "Download Excel template",
        download_template_desc: "to know which fields to provide.",
      },
      filter: {
        title: "Filter",
        status: {
          label: "Status",
          placeholder: "All",
          false: "Inactive",
          true: "Active",
        },
        curriculum: {
          label: "Curriculum",
          placeholder: "Filter by curriculum",
        },
        module: {
          label: "Modules",
          placeholder: "Filter by modules",
        },
        group: {
          label: "Groups",
          placeholder: "Filter by groups",
        },
      },
      Menu: {
        training: "Training",
        roles: "Roles",
        admin: "Administration",
        settings: "Settings",
        instructors: "Instructors",
        access_management: "Access management",
      },
      Dashboard: {
        dashboard: "Dashboard",
      },
      Roles: {
        title: "Roles & Permissions",
        roles: "Roles",
        new: "New role",
        edit: "Edit role",
        save: "Save",
        add: "Add",
        name: "Name",
        update_success: "Roles successfully updated!",
        update_error: "Unable to update role!",
        create_success: "Roles successfully added!",
        create_error: "Unable to add role!",
        delete_success: "Roles successfully removed!",
        delete_error: "Unable to remove role!",
        delete_error_role_has_members:
          "Can't delete this role because it has members",
        delete_dialog_title: "Delete role?",
        delete_dialog_text: "Are you sure you want to delete this role?",
        create_new_accounts_info:
          "The employees below did not have an existing account. We have created an account for each of them and sent their login information via email.",
        permissions: {
          permissions: "Permissions",
          title: "Permissions for role:",
          description: "Configure permissions for each platform feature",
          update_success: "Permission updated successfully!",
          update_error: "Permission update failed!",
          no_permissions: "Employee role has no permissions",
          no_instructor_permissions:
            "Instructor permissions are predefined and cannot be changed",
          administrator: "Administrator",
        },
        members: {
          tab_title: "Members",
          title: "Members of role:",
          description: "Add and remove members of this role",
          delete_success: "Member successfully removed!",
          delete_error: "Server error: unable to remove member",
          delete_error_has_modules:
            "Can't remove an instructor with associated modules",
          delete_dialog_title: "Remove this member?",
          delete_dialog_text: "Are you sure you wish to remove this member?",
          add_success: "Members successfully added!",
          add_error: "Server error: unable to add member(s)",
          last_admin_error: "You can't delete the last admin member!",
          add_members_title: "Add members to role",
          list: {
            columns: {
              name: "Name",
              job: "Job",
              group: "Group",
              delete: "Supprimer",
            },
          },
        },
      },
      Reports: {
        errors: {
          no_quiz_for_mod_cur: "This curriculum has no modules with a quiz",
          no_quiz_for_mod: "The module has no quiz",
          quiz_not_processed: "This quiz has not been corrected yet",
        },
        excel: {
          name: "Name",
          employee_id: "Employee Id",
          job: "Job",
          group: "Group",
          score: "Score",
          rank: "Rank",
        },
        export: "Export",
        menu: "Reports",
        title: "Reports",
        fields: {
          per_quiz_title: "Results per quiz",
          per_group_title: "Results per group",
          per_curriculum_title: "Results per curriculum",
          curriculum: "Curriculum",
          module: "Module",
          group: "Training Group",
        },
      },
      Student: {
        login: {
          employee_id: "Employee Id",
          dob: "Date of birth",
          title: "Skill Assessment",
          enter: "Enter",
          employee_id_or_email: "Employee Id or Email",
          password_error:
            "Password must contain at least 8 characters, one uppercase, one number and one special case character",
          password: "Password",
          confirm_password: "Confirm password",
          password_created: "Your password has been successfully created",
          create_new_password: "Please create a new password",
          password_reset_success: "Password successfully reset",
          check_your_results_here: "Participant? Check your results here",
        },
        quiz_status: {
          bad_code: "Quiz code not valid",
          no_code: "Quiz code is missing",
          expired: "Quiz expired",
          expired_at: "Expired on",
          starts_at: "Starts on",
          pending: "Quiz has not started yet",
        },
        quiz: {
          student: "Student",
          quiz: "Quiz",
          instructor: "Instructors",
          curriculum: "Curriculum",
          module: "Module",
          start: "Start Quiz",
          questions: "questions",
          minutes: "minutes",
          by_question: "By question",
          no_limit: "Open",
          taken: "You have already taken this quiz!",
          not_participant: "You are not part of this quiz group!",
          next_question: "Next Question",
          previous_question: "Previous Question",
          your_answer: "Your answer",
          one_answer: "You must choose at least one answer",
          two_answers: "You must choose at least two answers",
          question: "Question",
          time_over: "Time over!",
          finish_button: "Finish",
          rating_desc: "Please rate this course before submitting your answers",
          rating: "Course Rating",
          send_answers: "Send your answers",
          quiz_complete: "Quiz completed",
          quiz_complete_desc:
            "You have finished this quiz. Click on the button below to submit your answers.",
          quiz_submit_conf:
            "Your answers have been successfully recorded. Thank you for your participation!",
          quiz_conf_code: "Your confirmation code is:",
          quiz_results_url: "You can view your results on this URL:",
          quiz_results_unavailable:
            "Quiz results will be communicated later by your instructor",
        },
        results: {
          list: {
            title: "Quiz Results",
            columns: {
              answers: "Answers",
              group: "Group",
              curriculum: "Curriculum",
              module: "Module",
              date: "Date",
              score: "Score",
              grade: "Decision",
              status: "Status",
              instructor: "Instructor",
            },
            row_click_pending: "Quiz being corrected...Try later!",
          },
          status: {
            pending: "Pending",
            corrected: "Corrected",
          },
        },
        answers: {
          title: "Quiz Answers",
          correct: "Correct answer!",
          incorrect: "Incorrect answer!",
          points: "Pts.",
          correction: "Correction",
          additional_information: "Additional Information",
          view_document: "View document",
          watch_video: "Watch video",
          stats: {
            correct_answers: "Correct Answers",
            score: "Score",
            grade: "Decision",
          },
        },
      },
      Curriculum: {
        curriculum: "Curriculum",
        delete_title: "Delete curriculum?",
        delete_description: "Are you sure you want to delete this curriculum?",
        add_curriculum: "Add Curriculum",
        edit_curriculum: "Edit Curriculum",
        create_curriculum: "Create Curriculum",
        select_curriculum: "Please choose a curriculum to view modules",
        select_curriculum_drop: "Select curriculum...",
        name: "Curriculum name",
        description: "Curriculum description",
        search_by_name_placeholder: "Search by name...",
        fields: {
          status: {
            title: "Status",
            false: "Inactive",
            true: "Active",
          },
        },
        delete: {
          title: "Delete this curriculum ?",
          description: "Are you sure you want to delete this curriculum?",
          success: "Curriculum deleted successfully",
          fail: "Curriculum deletion failed",
        },
        filter: {
          "button-text": "Filter",
          inactive: "Inactive curriculums",
          created_by: "Created by",
          update_by: "Updated by",
          active_placeholder: "All curriculums",
        },
        import: {
          title: "Import curriculum",
          description: "Import curriculum data from an Excel file.",
          instructions: {
            name: "Curriculum name",
            description: "Curriculum description",
          },
        },
      },
      Module: {
        module: "Module",
        delete_title: "Delete module?",
        module_list: "Module List",
        add_module: "Add Module",
        module_name: "Module name",
        module_description: "Module description",
        create_module: "Create Module",
        edit_module: "Edit Module",
        update_module: "Update Module",
        associated_curriculum: "Associated Curriculum",
        associated_instructor: "Associated Instructors",
        delete_description: "Are you sure you want to delete this module?",
        delete_not_allowed:
          "This module cannot be removed because it either contains topics or belongs to a training group!",
        select_module_drop: "Select module...",
        search_by_name_placeholder: "Search by name...",
        unlink_topic: "Unlink topic",
        filter: {
          "button-text": "Filter",
          inactive: "Inactive modules",
          curriculum: "Curriculum",
          curriculum_placeholder: "Filter by curriculums",
          active_placeholder: "All modules",
        },
        fields: {
          status: {
            title: "Status",
            false: "Inactive",
            true: "Active",
          },
        },
        "table-columns": {
          id: "Id",
          name: "Name",
          date: "Date",
          status: "Status",
          editing: "Editing",
        },
        remove_topic: {
          title: "Unlink this topic?",
          description: "Are you sure you want to unlink this topic?",
        },
        import: {
          title: "Import modules",
          description: "Import module data from an Excel file.",
          instructions: {
            name: "Module name",
            description: "Module description",
          },
        },
        import_association: {
          title: "Import module-curriculum association",
          description: "Associate modules with curriculums from an Excel file.",
          instructions: {
            moduleId: "Module Id",
            curriculumId: "Curriculum Id",
          },
        },
      },
      Topic: {
        topic: "Topic",
        delete_title: "Delete topic?",
        topic_list: "List of Topics",
        add_topic: "Add topic",
        add_topics: "Add Topics",
        topics_added_success:
          "Topics have been added to the module successfully",
        topic_removed_success:
          "The topic has been successfully deleted from the module",
        topic_name: "Topic Name",
        topic_description: "Topic Description",
        create_topic: "Create Topic",
        edit_topic: "Edit Topic",
        delete_description: "Are you sure you want to delete this Topic?",
        delete_not_allowed:
          "This Topic cannot be deleted because it contains questions!",
        select_topic_drop: "Choose topic...",
        search_by_name_placeholder: "Search by name...",
        filter: {
          "button-text": "Filter",
          inactive: "Inactive Topics",
          curriculum: "Curriculum",
          curriculum_placeholder: "Filter by curriculum",
          active_placeholder: "All Topics",
        },
        fields: {
          status: {
            title: "State",
            false: "Inactive",
            true: "Active",
          },
        },
        "table-columns": {
          id: "Id",
          name: "Name",
          date: "Date",
          status: "Status",
          editing: "Edit",
        },
        import: {
          title: "Import Topics",
          description: "Import Topics data from an Excel file.",
          instructions: {
            name: "Topic name",
            description: "Topic description",
            moduleId: "Module Id",
          },
        },
      },
      Question: {
        question: "Question",
        classification: "Classification",
        fields: {
          type: {
            MULTIPLE_CHOICE: "Multiple choice",
            SINGLE_CHOICE: "Single choice",
            OPEN: "Open",
          },
          difficulty: {
            1: "Easy",
            2: "Medium",
            3: "Hard",
          },
          duration: {
            infinite: "Infinite",
          },
          secret: {
            false: "Public",
            true: "Secret",
          },
          required: {
            false: "Not required",
            true: "Required",
          },
          status: {
            title: "Status",
            false: "Inactive",
            true: "Active",
          },
        },
        delete_description: "Are you sure you want to delete this question?",
        delete_title: "Delete question?",
        add_question: "Add Question",
        edit_question: "Edit Question",
        type: "Type",
        difficulty: "Difficulty",
        secret: "Secret",
        required: "Required",
        difficulty_easy: "Easy",
        difficulty_medium: "Medium",
        difficulty_hard: "Hard",
        description: "Question Description",
        add_answers: "Add Answers",
        save: "Save Question",
        is_correct: "Correct",
        additional_information: "Additional information",
        answer: "Answer",
        answers: "Answers",
        reset: "Reset",
        time: "Time",
        duration: "Duration",
        add_error: "You must mark at least one answer as the correct option!",
        list: "Question list",
        single_choice: "Single choice",
        multiple_choice: "Multiple choice",
        open: "Open",
        select_module: "No questions. Please select a module",
        add_success: "Question has been added successfuly",
        update_success: "Question has been updated successfuly",
        attachment_video_url: "Video URL",
        attachment_text_content: "Text content",
        search_by_name_placeholder: "Search by description...",
        filter: {
          "button-text": "Filter",
          inactive: "Inactive questions",
          type_placeholder: "Filter by type",
          quiz_type_placeholder: "Filter by classification",
          difficulty_placeholder: "Filter by difficulty",
          secret_placeholder: "All questions",
          required_placeholder: "All questions",
          active_placeholder: "All questions",
          modules_placeholder: "Filter by modules",
        },
        delete: {
          success: "Question deleted successfully",
          fail: "Question deletion failed",
        },
        import: {
          title: "Import questions",
          description: "Import question data from an Excel file.",
          instructions: {
            topic_id: "Theme identifier",
            description: "question description",
            kind: {
              desc: "Question type (MULTIPLE_CHOICE, SINGLE_CHOICE, OPEN)",
              MULTIPLE_CHOICE: "Multiple choice",
              SINGLE_CHOICE: "Single choice",
              OPEN: "Open",
            },
            difficulty: {
              desc: "Question difficulty (EASY, MEDIUM, HARD)",
              EASY: "Easy",
              MEDIUM: "Medium",
              HARD: "Difficult",
            },
            quiz_type: {
              desc: "Question classification (COLD, HOT)",
              COLD: "cold",
              HOT: "hot",
            },
            duration: "question duration (mm:ss)",
            required: "is the question required (true, false)",
            answer:
              "Saisir les réponses non correctes de la question dans tous les colonnes qui commencent par 'ANSWER_'",
            correct_answer:
              "Saisir les réponses correctes de la question dans tous les colonnes qui commencent par 'CORRECT_ANSWER_'",
          },
        },
      },
      Instructor: {
        singular: "Instructor",
        plural: "Instructors",
        list: {
          add_modal: {
            title: "Instructor type",
            description: "Please select the type of instructor you want to add",
            button: {
              internal: "Internal instructor",
              external: "External Instructor",
            },
          },
          title: "Instructor list",
          columns: {
            name: "Name",
            email: "Email",
            type: {
              internal: "Internal",
              external: "External",
            },
            firm: "Firm",
            employeeId: "Matricule",
            job: "Fonction",
            entity: "Entité",
          },
        },
        create: {
          title: "Add an external instructor",
          submit_button: "Create",
        },
        edit: {
          title: "Edit an instructor",
          submit_button: "Save",
          modules: {
            add: {
              title: "Add modules",
              selected_modules: "Selected modules",
              empty_selected_modules: "No module selected",
              submit_button: "Add",
              fields: {
                modules: "Modules",
              },
            },
          },
        },
        delete: {
          title: "Delete instructor ?",
          description: "Are you sure you want to delete this instructor?",
          internal:
            "To remove an internal instructor please remove them from role members",
        },
        update: "Edit instructor",
        fields: {
          lastName: {
            label: "Last name",
          },
          firstName: {
            label: "First Name",
          },
          email: {
            label: "Email",
          },
          firm: {
            label: "Coaching Firm",
          },
          status: {
            title: "Status",
            false: "Inactive",
            true: "Active",
          },
        },
        filter: {
          active: {
            placeholder: "All instructors",
          },
          firms: {
            placeholder: "All firms",
          },
          modules: {
            placeholder: "All modules",
          },
          search: {
            placeholder: "name / employee id...",
          },
        },
      },
      TrainingGroup: {
        singular: "Training Group",
        plural: "Training Groups",
        list: {
          title: "Training Group list",
          columns: {
            name: "Name",
            curriculum: "Curriculum",
            modules: "Modules",
            participants: "Participants",
          },
        },
        create: {
          title: "Add training group",
          submit_button: "Create",
          modules: {
            title: "Training modules",
            empty: "No module selected",
          },
          participants: {
            title: "Participants",
            empty: "No participant selected",
            dialog: {
              title: "Import participants",
            },
          },
        },
        edit: {
          title: "Edit a training group",
          submit_button: "Save",
          participants: {
            name: "Name",
            employee_id: "Employee Id",
            group: "Group",
          },
        },
        delete: {
          title: "Delete the training group ?",
          description: "Are you sure you want to delete this training group?",
          error: {
            "has-modules": "Cannot delete a training group containing modules",
            "has-participants":
              "Cannot delete a training group containing participants",
          },
        },
        update: "Edit the training group",
        fields: {
          name: {
            label: "Name",
          },
          curriculum: {
            label: "Curriculum",
          },
          status: {
            title: "Status",
            false: "Inactive",
            true: "Active",
          },
        },
        filter: {
          active: {
            placeholder: "All groups",
          },
          curriculums: {
            placeholder: "All curriculums",
          },
          search: {
            placeholder: "Search by name...",
          },
        },
        not_enough_questions: {
          title: "Not enough questions to generate the quiz",
          desc: "We only found {{nb_questions}} compatible questions. Please add more questions or adjust your quiz settings.",
          question_list:
            "Here is the list of questions compatible with your quiz settings:",
        },
      },
      EmployeeDirectory: {
        list: {
          columns: {
            name: "Name",
            job: "Job",
            role: "Role",
            entity: "Entity",
            employeeId: "Registration number",
            email: "Email",
          },
        },
        empty_checked_employees: "No employees have been selected",
        save_button: "Add",
        placeholder: "Choose entities...",
        search: {
          placeholder: "Search by name/ employee id...",
        },
      },
      DateRangePicker: {
        start: "Start",
        end: "End",
        to: "to",
      },
      Corrections: {
        table: {
          name: "Name",
          question: "Question",
          answer: "Answer",
          action: "Action",
          correct: "Correct",
          tip_approve: "Mark correct",
          tip_disapprove: "Mark incorrect",
        },
        title: "Quiz correction",
        update_success: "Response treated successfully",
        update_error: "Error while treating response",
      },
      Quiz: {
        singular: "Quiz",
        plural: "Quiz",
        add: "New Quiz",
        pending: "Correct Answers",
        corrections: {
          title: "Answer correction",
        },
        list: {
          title: "Quiz list",
          columns: {
            pending: "Pending",
            name: "Name",
            instructor: "Instructor",
            group: "Group",
            module: "Module",
            curriculum: "Curriculum",
            date: "Quiz date",
            validity: "Validity",
            status: {
              pending: "Pending",
              ongoing: "Ongoing",
              completed: "Completed",
            },
          },
        },
        participants: {
          show: "Participants",
        },
        questions: {
          show: "Questions",
        },
        create: {
          title: "Add quiz",
          submit_button: "Create",
        },
        edit: {
          title: "Edit quiz",
          submit_button: "Save",
        },
        delete: {
          title: "Delete the quiz ?",
          description: "Are you sure you want to delete this quiz?",
          error: {
            "already-started": "Cannot delete a quiz that has already started",
          },
        },
        generate_url: "Generate quiz URL",
        error_not_enough_questions:
          "You don't have enough questions that match your quiz settings!",
        copy_url: "Copy",
        copy_url_success: "Quiz url successfuly copied to clipboard",
        copy_results_url_success: "Results url successfuly copied to clipboard",
        quiz_exist_error:
          "You can only create one quiz per module / training group",
        fields: {
          type: {
            label: "Type",
          },
          date: {
            label: "Date",
          },
          time: {
            label: "Hour",
          },
          curriculum: {
            label: "Curriculum",
          },
          module: {
            label: "Module",
          },
          group: {
            label: "Training group",
            tooltip:
              "Groups whose selected training module does not have a trainer are deactivated",
          },
          difficulty: {
            label: "Difficulty",
          },
          question_count: {
            label: "Questions",
            tooltip: "Number of questions",
          },
          timing: {
            label: "Timing",
            options: {
              null: "Not defined",
              PER_QUESTION: "By question",
              PER_QUIZ: "By quiz",
            },
            helper: "Questions without timing will be ignored",
          },
          validity: {
            label: "Validity (hours)",
            tooltip: "Duration of validity (in hours)",
          },
          duration: {
            label: "Duration (minutes)",
            tooltip: "Quiz duration (in minutes)",
          },
          status: {
            title: "Status",
            false: "Active",
            true: "Inactive",
          },
          url: "Quiz URL",
          results_url: "Results URL",
        },
        filter: {
          active: {
            placeholder: "All groups",
          },
          curriculums: {
            placeholder: "All curriculums",
          },
          search: {
            placeholder: "Search by name...",
          },
          status: {
            placeholder: "All quiz",
            label: "Status",
            pending: "Pending",
            ongoing: "Ongoing",
            completed: "Completed",
          },
        },
      },
      Settings: {
        settings: "Settings",
        tabs: {
          0: "Level score",
          1: "Question distribution",
          2: "Quiz grades",
        },
        LevelScore: {
          title: "Levels Score",
          1: "Easy",
          2: "Medium",
          3: "Hard",
        },
        question_score: {
          title: "Level score by quiz",
          update: {
            success: "Question score has been successfully updated",
            fail: "Error while updating question score",
          },
        },
        question_distribution: {
          title: "Question distribution by quiz",
          update: {
            success: "Question distribution has been successfully updated",
            fail: "Error while updating question distribution",
          },
        },
        quiz_grades: {
          title: "Quiz grades by quiz type",
          update: {
            success: "Quiz grades has been successfully updated",
            fail: "Error while updating quiz grades",
          },
        },
      },
      ProfileMenu: {
        switch_profile: "Switch role",
        switch_profile_success: "You are now using this role: ",
      },
      Login: {
        signin: "Sign in",
        select_role_title: "Role Selection",
        select_role_desc: "Please select a role to use",
        role: "Role",
        enter: "Enter",
        mfa: "Authentification code",
        type: "User profile",
        admin: "Administrator",
        instructor: "Instructor",
        internal: "Internal",
        external: "External",
        instructor_type: "Instructor Type",
        code_sent:
          "An email with a unique authentification code has been sent to your email. Please enter it",
        invalid_code: "Invalid authentification code!",
        send: "Send",
        verify: "Verify code",
        signout: "Signout",
        hi: "Hi",
        invalid_login: "Invalid login",
        valid_login: "You have successfully logged in!",
        has_no_access:
          "You do not have access to this platform. Please check out the other platforms",
        here: "here",
      },
      Home: {
        title: "Home",
        welcome: "Welcome",
        message:
          "Welcome to the skill assessment platform! Please use the menu to your left to navigate",
      },
    },
  },
  fr: {
    translation: {
      General: {
        forbidden: "Vous n'êtes pas autorisé à voir cette page",
        cancel: "Annuler",
        delete: "Supprimer",
        update: "Sauvegarder",
        show_inactive: "Afficher Inactif",
        created_by: "Crée par",
        updated_by: "Modifié par",
        active: "Active",
        inactive: "Inactif",
        date: "Date",
        status: "Statut",
        name: "Nom",
        edit: "Edition",
        description: "Description",
        type: "Type",
        content: "Contenu",
        save: "Sauvegarder",
        upload: "Telecharger",
        reset: "Réinitialiser",
        error: "Erreur",
        no_data: "Aucune donnée trouvée",
        close: "Fermer",
        at: "à",
        the: "le",
        unset: "Non défini",
        infinite: "Infini",
        "choose-a-file": "Choisissez un fichier",
        "choose-multiple-files": "Choisissez un ou plusieurs fichiers",
        more: "Plus",
        next: "Suivant",
        forgot_password: "Mot de passe oublié?",
        create_new_password: "Créer un nouveau mot de passe.",
        copy_your_password:
          "Assurez-vous de copier le mot de passe avant de réinitialiser.",
        reset_password: "Réinitialiser le mot de passe",
        reset_success: "mot de passe réinitialisé avec succès",
        reset_your_password: "Réinitialisez votre mot de passe",
        enter_new_password: "Veuillez saisir un nouveau mot de passe:",
        enter_your_email:
          "Veuillez saisir votre adresse mail pour réinitialiser votre mot de passe",
        check_your_email:
          "Votre mot de passe a été réinitialisé. Veuillez vérifier votre email pour continuer.",
        filter: "Filtre",
        ok: "D'accord",
        id: "Id",
        import: "Importer",
        difficulty: {
          1: "Facile",
          2: "Moyen",
          3: "Difficile",
        },
        quiz: {
          1: "Quiz Facile",
          2: "Quiz Moyen",
          3: "Quiz Difficile",
        },
        question: {
          1: "Question facile",
          2: "Question Moyenne",
          3: "Question Difficile",
        },
        creation: "Création",
        modification: "Modification",
        message: {
          create: {
            success: "Création effectuée avec succès",
            fail: "La création a échoué",
          },
          update: {
            success: "Modification effectuée avec succès",
            fail: "La modification a échoué",
          },
          add: {
            success: "Ajout effectué avec succès",
            fail: "L'ajout a échoué",
          },
          remove: {
            success: "Suppression effectuée avec succès",
            fail: "La suppression a échoué",
          },
          delete: {
            success: "Suppression effectuée avec succès",
            fail: "La suppression a échoué",
          },
          email: {
            sent: "Un email a été envoyé au formateur avec ses identifiants",
            not_sent: "Echec de l'envoi du mail",
            already_in_use: "Email déjà utilisé",
          },
          import: {
            success: "Importation effectuée avec succès",
            fail: "L'importation a échoué",
          },
        },
      },
      CRM: "CRM",
      Shop: "Shop",
      Chat: "Chat",
      Sales: "Sales",
      Results: "Results",
      Invoice: "Invoice",
      Payment: "Payment",
      Profile: "Profile",
      Account: "Account",
      Pricing: "Pricing",
      Checkout: "Checkout",
      Sessions: "Sessions",
      Ecommerce: "Ecommerce",
      Dashboards: "Dashboards",
      "Enrolled Courses": "Enrolled Courses",
      "Course Completed": "Course Completed",
      "Course in Progress": "Course in Progress",
      "Learning Management": "Learning Management",
      "Welcome Back! Watson": "Welcome Back! Watson",
      "Job Management": "Job Management",
      "User & Contact": "User & Contact",
      "User List": "User List",
      "User Grid": "User Grid",
      "Contact List": "Contact List",
      "Contact Grid": "Contact Grid",
      "Invoice List": "Invoice List",
      "Curriculum List": "Liste des Cursus",
      "Invoice Details": "Invoice Details",
      "Create Invoice": "Create Invoice",
      "Product Details": "Product Details",
      "Billing Address": "Billing Address",
      "Payment Complete": "Payment Complete",
      "Admin Ecommerce": "Admin Ecommerce",
      "Product List": "Product List",
      "Product Grid": "Product Grid",
      "Create Product": "Create Product",
      "Upcoming Task": "Upcoming Task",
      "Study Time Last Week": "Study Time Last Week",
      "Your Downloads": "Your Downloads",
      "Course Status": "Course Status",
      Name: "Nom",
      Cancel: "Annuler",
      Add: "Ajouter",
      Import: {
        download_excel_file: "Télécharger le fichier Excel",
        instructions: "Instructions",
        import_instructions: "Instructions d'importation",
        instructions_desc:
          "Votre fichier Excel doit contenir les données suivantes :",
        required_fields: "* Champs obligatoires",
        import_success: "Importation réussie",
        import_fail: "Importation échouée",
        row: "Ligne",
        records: "Enregistrements",
        errors: "Erreurs",
        file_not_valid: "Fichier non valide",
        file_is_valid: "Fichier valide",
        import_success_result:
          "L'importation de vos données a été effectuée avec succès",
        download_template: "Télécharger le modèle Excel",
        download_template_desc: "pour savoir quels champs fournir.",
      },
      filter: {
        title: "Filtre",
        status: {
          label: "Statut",
          placeholder: "Tous",
          false: "Inactif",
          true: "Actif",
        },
        curriculum: {
          label: "Cursus",
          placeholder: "Filtrer par cursus",
        },
        module: {
          label: "Modules",
          placeholder: "Filtrer par modules",
        },
        group: {
          label: "Groupes",
          placeholder: "Filtrer par groupes",
        },
      },
      Dashboard: {
        dashboard: "Tableau de bord",
        training_groups: "Groupes de formation",
        students: "Etudiants",
        firms: "Cabinets de formation",
        instructors: "Formateurs",
        curriculums: "Cursus",
        modules: "Modules",
        topics: "Thèmatiques",
        questions: "Questions",
        total_quiz: "Total Quiz",
        total: "Total",
        pending: "En attente",
        ongoing: "En cours",
        completed: "Terminé",
        avg_score: "Moyenne des scores",
        quiz_correction: "Correction des quiz",
        corrected_quiz: "Quiz corrigés",
      },
      Menu: {
        training: "Formation",
        roles: "Rôles",
        admin: "Administration",
        settings: "Paramètres",
        instructors: "Formateurs",
        access_management: "Gestion d'accès",
      },
      Roles: {
        title: "Rôles & Permissions",
        roles: "Rôles",
        new: "Nouveau rôle",
        edit: "Modifier rôle",
        save: "Sauvegarder",
        add: "Ajouter",
        name: "Nom",
        update_success: "Rôles mis à jour avec succès!",
        update_error: "Impossible de mettre à jour le rôle!",
        create_success: "Rôles ajoutés avec succès!",
        create_error: "Erreur serveur: Impossible d'ajouter un rôle!",
        delete_success: "Rôles supprimés avec succès!",
        delete_error: "Erreur serveur: Impossible de supprimer le rôle!",
        delete_error_role_has_members:
          "Impossible de supprimer ce rôle car il a des membres",
        delete_dialog_title: "Supprimer le rôle?",
        delete_dialog_text: "Voulez-vous vraiment supprimer ce rôle?",
        create_new_accounts_info:
          "Les personnes ci-dessous n'avaient pas de compte existant. Nous avons créé un compte pour chacun et envoyé leurs informations de connexion par email.",
        permissions: {
          permissions: "Permissions",
          title: "Permissions pour le rôle:",
          description:
            "Configurer les permissions pour chaque fonctionnalité de la plateforme",
          update_success: "Permission mise à jour avec succès!",
          update_error: "La mise à jour de la permission a échoué!",
          no_permissions: "Le role collaborateur n'a aucune permission",
          no_instructor_permissions:
            "Les permissions du formateur sont prédéfinies et ne peuvent pas être modifiées",
          administrator: "Administrateur",
        },
        members: {
          tab_title: "Membres",
          title: "Membres du rôle:",
          description: "Ajoutez et supprimer des members de ce rôle",
          delete_success: "Membre supprimé avec succès!",
          delete_error: "Erreur serveur: Impossible de supprimer le membre!",
          delete_error_has_modules:
            "Impossible de supprimer un formateur avec des modules associés",
          delete_dialog_title: "Supprimer ce membre?",
          delete_dialog_text: "Voulez-vous vraiment supprimer ce membre?",
          add_members_title: "Ajouter des membres au rôle",
          add_success: "Membre ajoutés avec succès!",
          add_error: "Erreur serveur: Impossible d'ajouter les membres!",
          last_admin_error:
            "Vous ne pouvez pas supprimer le dernier membre administrateur!",
          list: {
            columns: {
              name: "Nom",
              job: "Fonction",
              group: "Entité",
              delete: "Supprimer",
            },
          },
        },
      },
      Reports: {
        errors: {
          no_quiz_for_mod_cur: "Ce cursus n'a aucun module avec un quiz",
          no_quiz_for_mod: "Ce module n'a aucun quiz",
          quiz_not_processed: "Ce quiz n'est pas encore corrigé",
        },
        excel: {
          name: "Nom",
          employee_id: "Matricule",
          job: "Fonction",
          group: "Entité",
          score: "Score",
          rank: "Classement",
        },
        export: "Exportez",
        menu: "Reporting",
        title: "Reporting",
        per_quiz_title: "Résultats par quiz",
        per_group_title: "Résultats par groupe de formation",
        per_curriculum_title: "Résultats par cursus",
        fields: {
          curriculum: "Cursus",
          module: "Module",
          group: "Groupe de formation",
        },
      },
      Student: {
        login: {
          employee_id: "Matricule",
          dob: "Date de naissance",
          title: "Validation des Connaissances",
          enter: "Entrer",
          employee_id_or_email: "Matricule ou E-mail",
          password_error:
            "Le mot de passe doit contenir au moins: 8 caractères, une majuscule, une miniscule, un chiffre, un caractère spécial",
          password: "Mot de passe",
          confirm_password: "Confirmer le mot de passe",
          password_created: "Votre mot de passe a été créé avec succès",
          create_new_password: "Veuillez créer un nouveau mot de passe",
          password_reset_success: "Mot de passe réinitialisé avec succès",
          check_your_results_here: "Participant? Consultez vos resultats ici",
        },
        quiz_status: {
          bad_code: "Code quiz invalide",
          no_code: "Le code du quiz est manquant",
          expired: "Quiz expiré",
          expired_at: "Expiré le",
          starts_at: "Commence le",
          pending: "Le quiz n'a pas encore commencé",
        },
        quiz: {
          student: "Apprenant",
          quiz: "Quiz",
          instructor: "Formateur",
          curriculum: "Cursus",
          module: "Module",
          start: "Commencer le Quiz",
          questions: "questions",
          minutes: "minutes",
          by_question: "Par question",
          no_limit: "Libre",
          taken: "Vous avez déjà participé à ce quiz!",
          not_participant: "Vous ne faites pas partie de ce groupe de quiz !",
          next_question: "Question Suivante",
          previous_question: "Question Précédente",
          your_answer: "Votre Réponse",
          one_answer: "Vous devez choisir au moins une réponse",
          two_answers: "Vous devez choisir au moins deux réponses",
          question: "Question",
          time_over: "Temps écoulé!",
          finish_button: "Terminer",
          rating_desc:
            "Veuillez évaluer cette formation avant de soumettre vos réponses",
          rating: "Évaluation de la formation",
          send_answers: "Envoyez vos réponses",
          quiz_complete: "Quiz terminé",
          quiz_complete_desc:
            "Vous avez terminé ce quiz. Cliquez sur le bouton ci-dessous pour soumettre vos réponses.",
          quiz_submit_conf:
            "Vos réponses ont bien été enregistrées. Merci pour votre participation!",
          quiz_conf_code: "Votre code de confirmation est:",
          quiz_results_url:
            "Vous pouvez consulter vos résultats sur le lien suivant:",
          quiz_results_unavailable:
            "Les résultats du quiz seront communiqués ultérieurement par votre formateur",
        },
        results: {
          title: "Résultats des quiz",
          list: {
            columns: {
              answers: "Réponses",
              group: "Groupe",
              curriculum: "Cursus",
              module: "Module",
              date: "Date",
              score: "Score",
              grade: "Décision",
              status: "Etat",
              instructor: "Formateur",
            },
            row_click_pending:
              "Quiz en cours do correction...Essayez plus tard!",
          },
          status: {
            pending: "En attente",
            corrected: "Corrigé",
          },
        },
        answers: {
          title: "Réponses du Quiz",
          correct: "Bonne réponse!",
          incorrect: "Réponse incorrecte!",
          points: "Pts.",
          correction: "Correction",
          additional_information: "Compléments d'information",
          view_document: "Voir document",
          watch_video: "Regarder la vidéo",
          stats: {
            correct_answers: "Réponses correctes",
            score: "Score",
            grade: "Décision",
          },
        },
      },
      Curriculum: {
        curriculum: "Cursus",
        delete_title: "Supprimer cursus?",
        delete_description: "Voulez-vous vraiment supprimer ce cursus?",
        add_curriculum: "Ajouter Cursus",
        edit_curriculum: "Modifier Cursus",
        create_curriculum: "Créer Cursus",
        select_curriculum: "Veuillez choisir un cursus pour voir les modules",
        select_curriculum_drop: "Choisir cursus...",
        name: "Nom cursus",
        description: "Description du cursus",
        search_by_name_placeholder: "Rechercher par nom...",
        fields: {
          status: {
            title: "Etat",
            false: "Inactif",
            true: "Actif",
          },
        },
        delete: {
          title: "Supprimer le cursus ?",
          description: "Voulez-vous vraiment supprimer ce cursus?",
          success: "Cursus supprimé avec succès",
          fail: "Echec de la suppression du cursus",
        },
        filter: {
          "button-text": "Filtre",
          inactive: "Cursus inactifs",
          created_by: "Crée par",
          update_by: "Modifié par",
          active_placeholder: "Tous les cursus",
        },
        import: {
          title: "Importer cursus",
          description:
            "Importer les données des cursus depuis un fichier Excel.",
          instructions: {
            name: "Nom du cursus",
            description: "Description du cursus",
          },
        },
      },
      Module: {
        module: "Module",
        delete_title: "Supprimer module?",
        module_list: "Liste des Modules",
        add_module: "Ajouter Module",
        module_name: "Nom Module",
        module_description: "Description du module",
        create_module: "Créer Module",
        edit_module: "Modifier Module",
        associated_curriculum: "Cursus Associés",
        associated_instructor: "Formateurs Associés",
        delete_description: "Voulez-vous vraiment supprimer ce module?",
        delete_not_allowed:
          "Ce module ne peut être supprimé car il contient des thématiques ou appartient à un groupe de formation!",
        select_module_drop: "Choisir module...",
        search_by_name_placeholder: "Rechercher par nom...",
        unlink_topic: "Désaccocier la thématique",
        filter: {
          "button-text": "Filtre",
          inactive: "Modules inactifs",
          curriculum: "Cursus",
          curriculum_placeholder: "Filtrer par cursus",
          active_placeholder: "Tous les modules",
        },
        fields: {
          status: {
            title: "Etat",
            false: "Inactif",
            true: "Actif",
          },
        },
        "table-columns": {
          id: "Id",
          name: "Nom",
          date: "Date",
          status: "Status",
          editing: "Edition",
        },
        remove_topic: {
          title: "Désaccocier la thématique?",
          description: "Voulez-vous vraiment désaccocier cette thématique?",
        },
        import: {
          title: "Importer modules",
          description:
            "Importer les données des modules depuis un fichier Excel.",
          instructions: {
            name: "Nom du module",
            description: "Description du module",
          },
        },
        import_association: {
          title: "Importer association module-cursus",
          description:
            "Associer les modules avec les cursus depuis un fichier Excel.",
          instructions: {
            moduleId: "Identifiant du module",
            curriculumId: "Identifiant du cursus",
          },
        },
      },
      Topic: {
        topic: "Thématique",
        delete_title: "Supprimer thématique?",
        topic_list: "Liste des Thématiques",
        add_topic: "Ajouter Thématique",
        add_topics: "Ajouter des Thématiques",
        topics_added_success:
          "Les Thématiques ont été ajoutés au module avec succès",
        topic_removed_success:
          "La thématique a été supprimée du module avec succès",
        topic_name: "Nom Thématique",
        topic_description: "Description de la thématique",
        create_topic: "Créer Thématique",
        edit_topic: "Modifier Thématique",
        delete_description: "Voulez-vous vraiment supprimer cette thématique?",
        delete_not_allowed:
          "Cette thématique ne peut être supprimée car elle contient des questions!",
        select_topic_drop: "Choisir thématique...",
        search_by_name_placeholder: "Rechercher par nom...",
        filter: {
          "button-text": "Filtre",
          inactive: "Thématiques inactives",
          curriculum: "Cursus",
          curriculum_placeholder: "Filtrer par cursus",
          active_placeholder: "Toutes les thématiques",
        },
        fields: {
          status: {
            title: "Etat",
            false: "Inactif",
            true: "Actif",
          },
        },
        "table-columns": {
          id: "Id",
          name: "Nom",
          date: "Date",
          status: "Status",
          editing: "Edition",
        },
        import: {
          title: "Importer thématiques",
          description:
            "Importer les données des thématiques depuis un fichier Excel.",
          instructions: {
            name: "Nom du thématique",
            description: "Description du thématique",
            moduleId: "Identifiant du module",
          },
        },
      },
      Topic: {
        topic: "Thématique",
        delete_title: "Supprimer thématique?",
        topic_list: "Liste des Thématiques",
        add_topic: "Ajouter Thématique",
        topic_name: "Nom Thématique",
        topic_description: "Description de la thématique",
        create_topic: "Créer Thématique",
        edit_topic: "Modifier Thématique",
        delete_description: "Voulez-vous vraiment supprimer cette thématique?",
        delete_not_allowed:
          "Cette thématique ne peut être supprimée car elle contient des questions!",
        select_topic_drop: "Choisir thématique...",
        search_by_name_placeholder: "Rechercher par nom...",
        filter: {
          "button-text": "Filtre",
          inactive: "Thématiques inactives",
          curriculum: "Cursus",
          curriculum_placeholder: "Filtrer par cursus",
          active_placeholder: "Toutes les thématiques",
        },
        fields: {
          status: {
            title: "Etat",
            false: "Inactif",
            true: "Actif",
          },
        },
        "table-columns": {
          id: "Id",
          name: "Nom",
          date: "Date",
          status: "Status",
          editing: "Edition",
        },
      },
      Question: {
        question: "Question",
        classification: "Classification",
        fields: {
          type: {
            MULTIPLE_CHOICE: "Choix multiple",
            SINGLE_CHOICE: "Choix unique",
            OPEN: "Ouverte",
          },
          difficulty: {
            1: "Facile",
            2: "Moyenne",
            3: "Difficile",
          },
          duration: {
            infinite: "Infinie",
          },
          secret: {
            false: "Publique",
            true: "Secrète",
          },
          required: {
            false: "Non requise",
            true: "Requise",
          },
          status: {
            title: "Etat",
            false: "Inactive",
            true: "Active",
          },
        },
        delete_title: "Supprimer la question?",
        delete_description: "Voulez-vous vraiment supprimer cette question?",
        add_question: "Ajouter Question",
        edit_question: "Modifier Question",
        type: "Type",
        difficulty: "Difficulté",
        secret: "Secrète",
        required: "Requise",
        difficulty_easy: "Facile",
        difficulty_medium: "Moyenne",
        difficulty_hard: "Difficile",
        description: "Description de la question",
        add_answers: "Ajouter les réponses",
        save: "Enregistrer la question",
        is_correct: "Correcte",
        additional_information: "Complément d'information",
        answer: "Réponse",
        answers: "Réponses",
        reset: "Réinitialiser",
        time: "Temps",
        duration: "Durée",
        add_error:
          "Vous devez marquer au moins une réponse comme l'option correcte!",
        list: "Liste des questions",
        single_choice: "Choix unique",
        multiple_choice: "Choix multiple",
        open: "Ouverte",
        select_module: "Aucune question. Veuillez choisir un module",
        add_success: "La question a été ajoutée avec succès",
        update_success: "La question a été modifiée avec succès",
        attachment_video_url: "URL de la video",
        attachment_text_content: "Contenu du texte",
        search_by_name_placeholder: "Recherche...",
        filter: {
          "button-text": "Filtre",
          inactive: "Questions inactives",
          type_placeholder: "Filtrer par type",
          quiz_type_placeholder: "Filtrer par classification",
          difficulty_placeholder: "Filtrer par difficulté",
          secret_placeholder: "Toutes les questions",
          required_placeholder: "Toutes les questions",
          modules_placeholder: "Filtrer par modules",
          active_placeholder: "Toutes les questions",
        },
        delete: {
          success: "Question supprimée avec succès",
          fail: "Echec de la suppression de la question",
        },
        import: {
          title: "Importer questions",
          description:
            "Importer les données des questions depuis un fichier Excel.",
          instructions: {
            topic_id: "Identifiant de la thématique",
            description: "description de la question",
            type: {
              desc: "Type de la question (MULTIPLE_CHOICE, SINGLE_CHOICE, OPEN)",
              MULTIPLE_CHOICE: "Choix multiple",
              SINGLE_CHOICE: "Choix unique",
              OPEN: "Ouverte",
            },
            difficulty: {
              desc: "Difficulté de la question (EASY, MEDIUM, HARD)",
              EASY: "Facile",
              MEDIUM: "Moyenne",
              HARD: "Difficile",
            },
            quiz_type: {
              desc: "Classification de la question (COLD, HOT)",
              COLD: "à froid",
              HOT: "à chaud",
            },
            duration: "durée de la question (mm:ss)",
            required: "est-ce que la question est requise (true, false)",
          },
        },
      },
      Firm: {
        singular: "Cabinet de formation",
        plural: "Cabinets de formation",
        list: {
          title: "Liste de cabinets de formation",
          columns: {
            name: "Identité",
            contact: "Contact",
            phone: "Téléphone",
            email: "Email",
          },
        },
        create: {
          title: "Ajouter un cabinet de formation",
          submit_button: "Créer",
          success: "Cabinet de formation créé avec succès",
          fail: "Echec de la création du cabinet de formation",
        },
        edit: {
          title: "Modifier un cabinet de formation",
          submit_button: "Sauvegarder",
          success: "Cabinet de formation modifié avec succès",
          fail: "Echec de la modification du cabinet de formation",
          avatar: {
            title: "Logo du cabinet (Facultatif)",
            instructions:
              "Les fichiers autorisés sont *.jpeg, *.jpg, *.png, *.gif",
            success: "Logo du cabinet modifié avec succès",
          },
          modules: {
            add: {
              title: "Ajouter des modules de formations",
              selected_modules: "Modules sélectionnés",
              empty_selected_modules: "Aucun module sélectionné",
              submit_button: "Ajouter",
              fields: {
                modules: "Modules",
              },
              success: "Module(s) ajouté(s) avec succès",
              fail: "Echec de l'ajout des modules",
            },
            delete: {
              success: "Module supprimé avec succès",
              fail: "Echec de la suppression du module",
            },
          },
        },
        delete: {
          title: "Supprimer le cabinet de formation ?",
          description:
            "Voulez-vous vraiment supprimer ce cabinet de formation?",
          success: "Cursus supprimé avec succès",
          fail: "Echec de la suppression du cursus",
        },
        update: "Modifier le cabinet de formation",
        search_placeholder: "Rechercher par identité...",
        fields: {
          name: {
            label: "Identité",
          },
          email: {
            label: "Email",
          },
          contactFirstName: {
            label: "Prénom du contact",
          },
          contactLastName: {
            label: "Nom du contact",
          },
          phone: {
            label: "Téléphone",
          },
          address: {
            label: "Adresse",
          },
          status: {
            title: "Etat",
            false: "Inactif",
            true: "Actif",
          },
        },
        filter: {
          active: {
            placeholder: "Tous les cabinets",
          },
        },
      },
      Instructor: {
        singular: "Formateur",
        plural: "Formateurs",
        list: {
          add_modal: {
            title: "Type du formateur",
            description:
              "Veuillez sélectionner le type du formateur vous voulez ajouter",
            button: {
              internal: "Formateur interne",
              external: "Formateur externe",
            },
          },
          title: "Liste des formateurs",
          columns: {
            name: "Nom",
            email: "Email",
            type: {
              internal: "Interne",
              external: "Externe",
            },
            firm: "Cabinet",
          },
        },
        create: {
          title: "Ajouter un formateur externe",
          submit_button: "Créer",
        },
        import: {
          title: "Importer des formateurs",
          submit_button: "Importer",
        },
        edit: {
          title: "Modifier un formateur",
          submit_button: "Sauvegarder",
          avatar: {
            title: "Avatar (Facultatif)",
            instructions:
              "Les fichiers autorisés sont *.jpeg, *.jpg, *.png, *.gif",
            success: "Avatar modifié avec succès",
          },
          modules: {
            add: {
              title: "Ajouter des modules de formations",
              selected_modules: "Modules sélectionnés",
              empty_selected_modules: "Aucun module sélectionné",
              submit_button: "Ajouter",
              fields: {
                modules: "Modules",
              },
            },
          },
        },
        delete: {
          title: "Supprimer le formateur ?",
          description: "Voulez-vous vraiment supprimer ce formateur?",
          internal:
            "Pour supprimer un formateur interne, veuillez le supprimer des membres du rôle",
        },
        update: "Modifier le formateur",
        fields: {
          lastName: {
            label: "Nom",
          },
          firstName: {
            label: "Prénom",
          },
          email: {
            label: "Email",
          },
          firm: {
            label: "Cabinet de formation",
          },
          status: {
            title: "Etat",
            false: "Inactif",
            true: "Actif",
          },
        },
        filter: {
          active: {
            placeholder: "Tous les formateurs",
          },
          firms: {
            placeholder: "Tous les cabinets",
          },
          modules: {
            placeholder: "Tous les modules",
          },
          search: {
            placeholder: "nom/matricule...",
          },
          type: {
            label: "Type du formateur",
            placeholder: "Tous les formateurs",
            internal: "Interne",
            external: "Externe",
          },
        },
      },
      TrainingGroup: {
        singular: "Groupe de formation",
        plural: "Groupes de formation",
        empty_modules: "Aucun module sélectionné",
        empty_participants: "Aucun participant sélectionné",
        list: {
          title: "Liste des groupes de formations",
          columns: {
            name: "Nom",
            curriculum: "Cursus",
            modules: "Modules",
            participants: "Participants",
          },
        },
        create: {
          title: "Ajouter un groupe de formation",
          submit_button: "Créer",
          modules: {
            title: "Modules de formation",
            empty: "Aucun module sélectionné",
          },
          participants: {
            title: "Participants",
            empty: "Aucun participant sélectionné",
            dialog: {
              title: "Importer des participants",
            },
          },
        },
        edit: {
          title: "Modifier un groupe de formation",
          submit_button: "Sauvegarder",
          participants: {
            name: "Nom",
            employee_id: "Matricule",
            group: "Entité",
          },
        },
        delete: {
          title: "Supprimer le groupe de formation ?",
          description: "Voulez-vous vraiment supprimer ce groupe de formation?",
          error: {
            "has-modules":
              "Ne peut pas supprimer un groupe de formation contenant des modules",
            "has-participants":
              "Ne peut pas supprimer un groupe de formation contenant des participants",
          },
        },
        update: "Modifier le groupe de formation",
        fields: {
          name: {
            label: "Nom",
          },
          curriculum: {
            label: "Cursus",
          },
          status: {
            title: "Etat",
            false: "Inactif",
            true: "Actif",
          },
        },
        filter: {
          active: {
            placeholder: "Tous les groupes",
          },
          curriculums: {
            placeholder: "Tous les cursus",
          },
          search: {
            placeholder: "Rechercher par nom...",
          },
        },
      },
      Corrections: {
        table: {
          name: "Nom",
          question: "Question",
          answer: "Réponse",
          correct: "Corriger",
          action: "Action",
          tip_approve: "Marquer juste",
          tip_disapprove: "Marquer faux",
        },
        title: "Correction quiz",
        update_success: "Réponse traitée avec succès",
        update_error: "Erreur lors du traitement de la réponse",
      },
      Quiz: {
        singular: "Quiz",
        plural: "Quiz",
        add: "Nouveau Quiz",
        pending: "Corriger Réponses",
        questions_distribution: "Distribution des questions par thématique",
        corrections: {
          title: "Correction des Réponses",
        },
        list: {
          title: "Liste des quiz",
          columns: {
            pending: "En attente",
            name: "Nom",
            instructor: "Formateur",
            group: "Groupe",
            module: "Module",
            curriculum: "Cursus",
            date: "Date du quiz",
            validity: "Validité",
            status: {
              pending: "En attente",
              ongoing: "En cours",
              completed: "Terminé",
            },
          },
        },
        participants: {
          show: "Participants",
        },
        questions: {
          show: "Questions",
        },
        create: {
          title: "Ajouter un quiz",
          submit_button: "Créer",
        },
        edit: {
          title: "Modifier un quiz",
          submit_button: "Sauvegarder",
        },
        delete: {
          title: "Supprimer le quiz ?",
          description: "Voulez-vous vraiment supprimer ce quiz?",
          error: {
            "already-started": "Ne peut pas supprimer un quiz déjà en cours",
          },
        },
        error_not_enough_questions:
          "Vous n'avez pas assez de questions qui correspondent aux paramètres de votre quiz !",
        generate_url: "Générer lien du quiz",
        copy_url: "Copier",
        copy_url_success:
          "Lien du quiz copié avec succès dans le presse-papiers",
        copy_results_url_success:
          "Lien de la page des résultats copié avec succès dans le presse-papiers",
        quiz_exist_error:
          "Vous ne pouvez créer qu'un seul quiz par module / groupe de formation",
        fields: {
          type: {
            label: "Type",
          },
          name: {
            label: "Nom",
          },
          date: {
            label: "Date",
          },
          time: {
            label: "Heure",
          },
          curriculum: {
            label: "Cursus",
          },
          module: {
            label: "Module de formation",
          },
          group: {
            label: "Groupe de formation",
            tooltip:
              "Les groupes dont le module de formation sélectionné n'a pas de formateur sont désactivés",
          },
          difficulty: {
            label: "Difficulté",
          },
          question_count: {
            label: "Questions",
            tooltip: "Nombre de questions",
          },
          timing: {
            label: "Timing",
            options: {
              null: "Non défini",
              PER_QUESTION: "Par question",
              PER_QUIZ: "Par quiz",
            },
            helper: "Les questions sans timing seront ignorées",
          },
          validity: {
            label: "Validité (heures)",
            tooltip: "Durée de validité (en heures)",
          },
          duration: {
            label: "Durée (min.)",
            tooltip: "Durée du quiz (en minutes)",
          },
          status: {
            title: "Etat",
            false: "Inactif",
            true: "Actif",
          },
          url: "Lien Quiz",
          results_url: "Lien Résultats",
        },
        filter: {
          active: {
            placeholder: "Tous les groupes",
          },
          curriculums: {
            placeholder: "Tous les cursus",
          },
          search: {
            placeholder: "Rechercher par nom...",
          },
          status: {
            placeholder: "Tous les quiz",
            label: "Statut",
            pending: "En attente",
            ongoing: "En cours",
            completed: "Terminé",
          },
        },
        not_enough_questions: {
          title: "Pas assez de questions pour générer le quiz",
          desc: "Nous n'avons trouvé que {{nb_questions}} questions compatibles. Veuillez ajouter davantage de questions ou ajuster les paramètres de votre quiz.",
          question_list:
            "Voici la liste des questions compatibles avec vos paramètres de quiz :",
        },
      },
      EmployeeDirectory: {
        list: {
          columns: {
            name: "Nom",
            job: "Fonction",
            role: "Rôle",
            entity: "Entité",
            employeeId: "Matricule",
            email: "Email",
          },
        },
        empty_checked_employees: "Aucun employé n'a été sélectionné",
        save_button: "Ajouter",
        placeholder: "Choisir entitées...",
        search: {
          placeholder: "Rechercher par nom/matricule...",
        },
      },
      DateRangePicker: {
        start: "Début",
        end: "Fin",
        to: "à",
      },
      ModuleSelector: {
        title: "Sélection de modules",
        list_label: "Modules",
        list_placeholder: "Modules",
        empty_selected_modules: "Aucun module sélectionné",
        save_button: "Ajouter",
        selected_modules: "Modules sélectionnés",
      },
      Settings: {
        settings: "Paramètres",
        tabs: {
          0: "Score par difficulté",
          1: "Distribution des questions",
          2: "Décision",
          apparence: "Apparence",
        },
        LevelScore: {
          title: "Score des niveaux",
          1: "Facile",
          2: "Moyen",
          3: "Difficile",
        },
        question_score: {
          title: "Score des niveaux par quiz",
          update: {
            success: "Score des niveaux mis à jour avec succès",
            fail: "Erreur lors de la mise à jour des scores des niveaux",
          },
        },
        question_distribution: {
          title: "Distribution des questions par quiz",
          update: {
            success: "Distribution des questions mise à jour avec succès",
            fail: "Erreur lors de la mise à jour de la distribution des questions",
          },
        },
        quiz_grades: {
          title: "Qualification du score",
          update: {
            success: "Qualification du score mise à jour avec succès",
            fail: "Erreur lors de la mise à jour de la qualification du score",
          },
        },
        apparence: {
          main_logo: "Logo principal",
          main_logo_description:
            "Selectionnez le logo principal de votre application",
          theme_color: "Couleur du thème",
          theme_color_description:
            "Sélectionnez une couleur sur la palette pour changer la couleur du thème",
        },
      },
      ProfileMenu: {
        switch_profile: "Changer de rôle",
        switch_profile_success: "Vous utilisez maintenant ce rôle:",
      },
      Login: {
        signin: "Connexion",
        select_role_title: "Sélection du rôle",
        select_role_desc: "Veuillez sélectionner le rôle à utiliser",
        role: "Rôle",
        enter: "Entrer",
        mfa: "Code d'authentification",
        type: "Profil de l'utilisateur",
        admin: "Gestionnaire",
        instructor: "Formateur",
        internal: "Interne",
        externe: "Externe",
        code_sent:
          "Un e-mail avec un code d'authentification unique a été envoyé à votre adresse e-mail. Veuillez le saisir",
        invalid_code: "Votre code d'authentification est invalide!",
        send: "Envoyer",
        verify: "Vérifier le code",
        signout: "Se déconnecter",
        hi: "Salut",
        invalid_login: "Login invalide",
        valid_login: "Vous êtes connecté avec succès!",
        has_no_access:
          "Vous n'avez pas accès à cette plateforme. Merci de consulter les autres plateformes",
        here: "ici",
      },
      Home: {
        title: "Accueil",
        welcome: "Bonjour",
        message:
          "Bienvenue sur la plateforme de validation des connaissances! Veuillez utiliser le menu à votre gauche pour naviguer.",
      },
    },
  },
  es: {
    translation: {
      CRM: "CRM",
      Chat: "Chat",
      Shop: "Tienda",
      Sales: "Ventas",
      Payment: "Pago",
      Account: "Cuenta",
      Profile: "Perfil",
      Pricing: "Precios",
      Invoice: "Factura",
      Sessions: "Sesiones",
      Results: "Resultados",
      Checkout: "Verificar",
      Dashboards: "Cuadros de mando",
      Ecommerce: "Comercio electrónico",
      "Upcoming Task": "Próxima tarea",
      "Your Downloads": "Tus descargas",
      "Course Status": "Estado del curso",
      "Course in Progress": "Curso en curso",
      "Enrolled Courses": "Cursos inscritos",
      "Course Completed": "Curso completado",
      "Job Management": "Gestión de trabajos",
      "User & Contact": "Usuario y contacto",
      "User List": "Lista de usuarios",
      "User Grid": "Cuadrícula de usuario",
      "Contact List": "Lista de contactos",
      "Contact Grid": "Cuadrícula de contacto",
      "Invoice List": "Lista de facturas",
      "Create Invoice": "Crear factura",
      "Billing Address": "Dirección de Envio",
      "Payment Complete": "Pago completo",
      "Product List": "Lista de productos",
      "Create Product": "Crear producto",
      "Product Details": "Detalles de producto",
      "Product Grid": "Cuadrícula de productos",
      "Invoice Details": "Detalles de la factura",
      "Learning Management": "Gestión del aprendizaje",
      "Welcome Back! Watson": "¡Bienvenido de nuevo! Watson",
      "Admin Ecommerce": "Administrador de comercio electrónico",
      "Study Time Last Week": "Tiempo de estudio la semana pasada",
    },
  },
};
i18next.use(initReactI18next).init({
  resources,
  lng: "fr",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false,
  },
});

export default i18next;
