import { useTranslation } from "react-i18next";
import FlexBetween from "components/flexbox/FlexBetween";
import { H3 } from "components/Typography";
import SaveIcon from "@mui/icons-material/Save";
import {
  Backdrop,
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  Stack,
} from "@mui/material";
import { Formik } from "formik";
import { useEffect, useState } from "react";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import toast from "react-hot-toast";
import i18next from "i18next";
import useMutate from "hooks/useMutate";
import api from "utils/api";
import uuid from "react-uuid";
import { createQuestionSchema } from "schemas/question";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import * as Fields from "page-sections/question/add";
import RouteAuth from "components/positeams/routeAuth";

export default function AddQuestion() {
  return (
    <RouteAuth>
      <Content />
    </RouteAuth>
  );
}
const Content = () => {
  const { t } = useTranslation();

  const { mutate } = useMutate();

  const initialValues = {
    module: "",
    topic: "",
    type: "",
    quizTypeId: "",
    difficulty: "",
    duration: null,
    required: false,
    description: "",
    answers: [],
    attachments: {
      text: "",
      videoURL: "",
      file: [],
    },
  };

  const handleSubmit = async (data, resetForm) => {
    // Submitting questions
    let {
      topic: topicId,
      type: typeId,
      quizTypeId,
      difficulty,
      duration,
      required,
      description,
      answers,
      attachments,
    } = data;
    let question = {
      topicId,
      typeId,
      difficulty,
      duration,
      required,
      quizTypeId,
    };
    let question_trans = [
      { locale: i18next.language.toUpperCase(), description },
    ];
    let question_object = { question, question_trans };

    try {
      const result = await mutate(() => api.addQuestion(question_object));

      // Submitting question items if exist
      for (let answer of answers) {
        let question_item_object = {
          question_item: {
            is_answer: answer.checked,
            questionId: result.data.id,
          },
          question_item_trans: [
            {
              locale: i18next.language.toUpperCase(),
              description: answer.description,
            },
          ],
        };
        await api.addQuestionItem(question_item_object);
      }

      if (!!attachments.file && attachments.file?.at(0) instanceof File) {
        const formData = new FormData();
        for (let file of attachments.file)
          formData.append("document", file, `${uuid()}.${file.name}`);
        // await api.uploadResponseDocument(formData, result.data.id);
        await mutate(() =>
          api.uploadResponseDocument(formData, result.data.id)
        );
      }

      if (!!attachments.text)
        // await api.addResponseExtraInfo({ questionId: result.data.id, text: attachments.text || null });
        await mutate(() =>
          api.addResponseExtraInfo({
            questionId: result.data.id,
            text: attachments.text || null,
          })
        );

      if (!!attachments.videoURL)
        // await api.addResponseExtraInfo({ questionId: result.data.id, video_url: attachments.videoURL || null });
        await mutate(() =>
          api.addResponseExtraInfo({
            questionId: result.data.id,
            video_url: attachments.videoURL || null,
          })
        );

      toast.success(t("Question.add_success"));

      resetForm();
    } catch (e) {
      toast.error(t("General.message.create.error"));
    }
  };

  return (
    <Box pt={2} pb={4}>
      <Formik
        initialValues={initialValues}
        validationSchema={createQuestionSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={async (values, { resetForm }) =>
          await handleSubmit(values, resetForm)
        }
      >
        {(props) => <Form {...props} />}
      </Formik>
    </Box>
  );
};

const Form = (props) => {
  const { handleSubmit, values, errors, isSubmitting, setFieldValue } = props;
  const [isOpenAdditionalInfo, setIsOpenAdditionalInfo] = useState(false);
  const toggle = () => setIsOpenAdditionalInfo((current) => !current);
  const { t } = useTranslation();

  useEffect(() => {
    if (!isSubmitting && !!errors.answers) toast.error(errors.answers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting]);

  useEffect(() => {
    setFieldValue("answers", []);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.type]);

  return (
    <Grid container spacing={3}>
      <Fields.AdditionalInformation
        open={isOpenAdditionalInfo}
        setOpen={setIsOpenAdditionalInfo}
      />
      <Grid item md={9}>
        <FlexBetween>
          <Stack direction="row" alignItems="center">
            <AddRoundedIcon sx={{ fontSize: "30" }} />
            <H3>{t("Question.add_question")}</H3>
          </Stack>
        </FlexBetween>
      </Grid>
      <Grid item md={9} xs={12}>
        <Card
          sx={{
            padding: 3,
            marginBottom: 2,
          }}
        >
          <form id="myform" onSubmit={handleSubmit}>
            <Grid container spacing={3} justify="center" alignItems="center">
              <Grid item>
                <Fields.Module />
              </Grid>
              <Grid item>
                <Fields.Topic />
              </Grid>
              <Grid item>
                {/* <SelectType /> */}
                <Fields.Type />
              </Grid>
              <Grid item>
                {/* <SelectDifficulty /> */}
                <Fields.Difficulty />
              </Grid>
              <Grid item>
                {/* <Timing /> */}
                <Fields.Timing />
              </Grid>
              <Grid item>
                {/* <SelectQuizType /> */}
                <Fields.QuizType />
              </Grid>
              <Grid item>
                <Fields.Required />
              </Grid>
              <Grid item sm={12}>
                {/* <Description /> */}
                <Fields.Description />
              </Grid>
              {/* Buttons */}
              <Grid
                container
                item
                sm={12}
                justify="flex-end"
                justifyContent="flex-end"
              >
                <Button
                  onClick={toggle}
                  variant="outlined"
                  startIcon={<AttachFileIcon />}
                  sx={{ marginRight: "10px" }}
                >
                  {t("Question.additional_information")}
                </Button>
                <Button
                  startIcon={<SaveIcon />}
                  type="submit"
                  variant="contained"
                >
                  {t("Question.save")}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Card>

        {/* {values.type === 1 && <MultiChoice />}
        {values.type === 2 && <SingleChoice />} */}
        {values.type === 1 && <Fields.MultiChoice />}
        {values.type === 2 && <Fields.SingleChoice />}
      </Grid>

      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isSubmitting}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Grid>
  );
};
