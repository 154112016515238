import React, { useEffect } from "react";
import { Grid, Box, Stack } from "@mui/material";
import { H4 } from "components/Typography";
import AssessmentIcon from "@mui/icons-material/Assessment";
import { useTranslation } from "react-i18next";
import RouteAuth from "components/positeams/routeAuth";
import ReportPerQuiz from "page-sections/reports/perQuiz";
import ReportPerGroup from "page-sections/reports/perGroup";
import ReportPerCurriculum from "page-sections/reports/perCurriculum";
import useAuth from "hooks/useAuth";
import { MANAGER_ROLE_ID } from "../../constants";

const Reports = () => {
  return (
    <RouteAuth>
      <Content />
    </RouteAuth>
  );
};
const Content = () => {
  const { t } = useTranslation();
  const { role } = useAuth();

  useEffect(() => {
    console.log("role:", role?.id);
  });

  return (
    <Box pt={2} pb={4}>
      <Stack direction="row" alignItems="center" sx={{ marginBottom: 4 }}>
        <AssessmentIcon />
        <H4 pl={2}>{t("Reports.title")}</H4>
        &nbsp;
      </Stack>

      <Grid container spacing={3}>
        <ReportPerQuiz />
        {role?.id != MANAGER_ROLE_ID && (
          <>
            <ReportPerGroup />
            <ReportPerCurriculum />
          </>
        )}
      </Grid>
    </Box>
  );
};

export default Reports;
