import moment from "moment";
import config from "config";
import skillupLogo from "assets/skillup.png";

export const companyLogoPath = "/upload/logo/";

export const searchByName = (listData, searchValue) => {
  if (searchValue.length > 0) {
    const searchResult = listData.filter((item) =>
      item.name.toLocaleLowerCase().match(searchValue.toLowerCase())
    );
    return searchResult;
  } else {
    return listData;
  }
};
export const getRoute = (pathname) => {
  const str = pathname.split("/");
  return `/${str[1]}`;
};

export const formatDate = (date, language) => {
  let result;
  if (language.toLowerCase() == "fr") {
    result = moment(date).format("DD/MM/YYYY HH:MM");
  }
  if (language.toLowerCase() == "en") {
    result = moment(date).format("MM/DD/YYYY HH:MM");
  }

  return result ? result : moment(date).format("DD/MM/YYYY HH:MM");
};

export function getCompanyLogo(cid, companyName) {
  let companyId = getLocalStorageItem("companyId") || cid;
  return config.app.server + companyLogoPath + companyId + ".png";
}

export function formatEmployeeGroup(group, root_group_id) {
  //S.T 2023-01-09
  //Show employee group and parent groups, 4 levels max (possible 6 if we need to)
  //e.g group 1 -> group 1.1 -> group 1.1.1 -> group 1.1.1.1
  if (!group) return "";
  let tmp = [];
  tmp.push(group.name);
  if (group.parentGroup && group.parentGroup.id != root_group_id) {
    tmp.push(group.parentGroup.name);
    if (
      group.parentGroup.parentGroup &&
      group.parentGroup.parentGroup.id != root_group_id
    ) {
      tmp.push(group.parentGroup.parentGroup.name);
      if (
        group.parentGroup.parentGroup.parentGroup &&
        group.parentGroup.parentGroup.parentGroup.id != root_group_id
      ) {
        tmp.push(group.parentGroup.parentGroup.parentGroup.name);
      }
    }
  }
  return tmp.reverse().join(" -> ");
}

//BEGIN: Avatar formatting: generates random avatar bg color plus name initials --------------------------------------
function stringToColor(string) {
  if (!string) return;
  let hash = 0;
  let i;

  /* eslint-disable no-bitwise */
  for (i = 0; i < string.length; i += 1) {
    hash = string.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }
  /* eslint-enable no-bitwise */

  return color;
}

export let stringAvatar = (name, size) => {
  return {
    sx: {
      bgcolor: stringToColor(name),
      width: size || 40,
      height: size || 40,
    },
    children: `${name?.split(" ")[0][0]}${name?.split(" ")[1][0]}`,
  };
}; //END: Avatar formatting --------------------------------------------------------------------------

// localStorage
const localStorageKey = "skillup-storage";

export const setLocalStorageItem = (key, value) => {
  const storage = JSON.parse(localStorage.getItem(localStorageKey)) || {};

  localStorage.setItem(
    localStorageKey,
    JSON.stringify({ ...storage, [key]: value })
  );
};

export const getLocalStorageItem = (key) => {
  const storage = JSON.parse(localStorage.getItem(localStorageKey)) || {};
  return storage[key];
};

export const removeLocalStorageItem = (key) => {
  const storage = JSON.parse(localStorage.getItem(localStorageKey)) || {};
  delete storage[key];
  localStorage.setItem(localStorageKey, JSON.stringify(storage));
};
