import { Grid, Stack } from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import { Box } from "@mui/system";
import React from "react";
// -------------------------------------------------------
const AuthenticationLayout = (props) => {
  const { children, title } = props;
  const downMd = useMediaQuery((theme) => theme.breakpoints.down("md"));
  return (
    <Grid container height="100%" justifyContent="center">
      {/* <Grid item md={6} xs={12} order={downMd ? 2 : 1}>
        <ContentSlider />
      </Grid> */}

      <Grid item md={6} xs={12} order={downMd ? 1 : 2}>
        <Stack alignItems="center" justifyContent="center" height="100%">
          <Box textAlign="center" maxWidth={550} width="100%" padding={4}>
            {children}
          </Box>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default AuthenticationLayout;
