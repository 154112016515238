import AppTextField from "components/input-fields/AppTextField";
import { useFormikContext } from "formik";
import useFetch from "hooks/useFetch";
import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import api from "utils/api";
import { Autocomplete, CircularProgress } from "@mui/material";
import i18next from "i18next";
import { useEffect } from "react";

export default function SelectModuleField() {
  const { t } = useTranslation();
  const [hack, setHack] = useState(true);

  const { touched, errors, values, setFieldValue } = useFormikContext();

  const { data: modules, isLoading } = useFetch(
    React.useCallback(() => api.getModuleList(), [])
  );

  useEffect(() => {
    if (values.moduleId === null) setHack((current) => !current);
  }, [values.moduleId]);

  return (
    <Autocomplete
      key={hack}
      name="moduleId"
      value={modules?.rows?.find((m) => m.id === values.moduleId) || null}
      onChange={(_e, value, reason) => {
        if (reason === "clear") setFieldValue("moduleId", null);
        else setFieldValue("moduleId", value?.id);
      }}
      size="small"
      sx={{ width: 250 }}
      clearOnBlur={false}
      options={modules?.rows || []}
      loading={isLoading}
      getOptionLabel={(option) =>
        option?.module_trans?.find(
          (m) => m.locale === i18next.language.toUpperCase()
        )?.name || ""
      }
      renderInput={(params) => (
        <AppTextField
          {...params}
          label={t("Module.module")}
          InputProps={{
            ...params.InputProps,
            style: { paddingRight: 40 },
            endAdornment: (
              <Fragment>
                {isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
          error={Boolean(touched.moduleId && errors.moduleId)}
          helperText={touched.moduleId && errors.moduleId}
        />
      )}
    />
  );
}
