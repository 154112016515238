import * as Yup from "yup";

export const createQuestionSchema = Yup.object().shape({
  topic: Yup.number().required("Topic is required"),
  type: Yup.string().required("Question type is required"),
  quizTypeId: Yup.string().required("Classification is required"),
  difficulty: Yup.string().required("Question difficulty is required"),
  duration: Yup.number().nullable(true),
  required: Yup.boolean(),
  description: Yup.string().required("Question description is required"),
  answers: Yup.array()
    .of(
      Yup.object().shape({
        description: Yup.string().required(),
        checked: Yup.boolean().required(),
      })
    )
    .when("type", (type, schema) => {
      switch (Number(type)) {
        case 1:
          return schema
            .min(2, "Vous devez fournir au minimum deux réponses")
            .test(
              "has-correct-answer",
              "Vous devez fournir au minimum deux réponses actives correctes",
              // needs to check if there is at least two active correct answers
              (value) =>
                value.filter(
                  (answer) =>
                    !!answer.checked === true && !!answer.active === true
                ).length >= 2
            );
        case 2:
          return schema
            .min(1, "Vous devez fournir au minimum une réponse")
            .test(
              "has-correct-answer",
              "Vous devez fournir au minimum une réponse active correcte",
              // needs to check if there is one active correct answer
              (value) =>
                value.filter(
                  (answer) =>
                    !!answer.checked === true && !!answer.active === true
                ).length === 1
            );
        default:
          return schema;
      }
    }),
  attachments: Yup.object().shape({
    videoURL: Yup.string().nullable(),
    text: Yup.string().nullable(),
    file: Yup.mixed().nullable(),
  }),
});

// (value) => !!value.find((answer) => answer.checked === true)
