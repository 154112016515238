import { useEffect, useReducer } from "react";

const initialState = { data: null, isError: false, isLoading: true, isFetching: true };

const reducer = (state, action) => {
  switch (action.type) {
    case "SUCCESS":
      const { data } = action.payload;
      return { ...state, data, isLoading: false, isFetching: false, isError: false };
    case "LOADING":
      return { ...state, isLoading: true, isFetching: true, isError: false };
    case "FETCHING":
      return { ...state, isLoading: false, isFetching: true, isError: false };
    case "ERROR":
      return { ...state, isLoading: false, isFetching: false, isError: true };
    default:
      return state;
  }
};

function useFetch(call) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const fetch = async ({ refetch }) => {
    if (!refetch) dispatch({ type: "LOADING" });
    else dispatch({ type: "FETCHING" });

    try {
      const result = await call();
      if (!!result.error || Object.keys(result?.data).includes("err")) {
        dispatch({ type: "ERROR" });
        throw new Error("Error while fetching");
      } else dispatch({ type: "SUCCESS", payload: { data: result.data } });
    } catch (e) {
      dispatch({ type: "ERROR" });
    }
    // if (!!result.error) dispatch({ type: "ERROR" });
    // check if error
  };

  const refetch = async () => {
    await fetch({ refetch: true });
  };

  useEffect(() => {
    fetch({ refetch: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [call]);

  // useEffect(() => {
  //   dispatch({ type: "LOADING" });
  //   const initFetch = async () => {
  //     const result = await call();
  //     if (!!result.error) dispatch({ type: "ERROR" });
  //     else dispatch({ type: "SUCCESS", payload: { data: result.data } });
  //   };

  //   initFetch();
  // }, [call]);

  return {
    data: state.data,
    refetch: refetch,
    status: state.status,
    isLoading: state.isLoading,
    isFetching: state.isFetching,
    isError: state.isError,
  };
}
export default useFetch;
