import LayoutBodyWrapper from "layouts/layout-parts/LayoutBodyWrapper";
import { Fragment } from "react";
import { Outlet } from "react-router";
import ProfilePopover from "./layout-parts/popovers/ProfilePopover";
import { Grid } from "@mui/material";

const LayoutStudentQuiz = ({ children }) => {
  return (
    <Fragment>
      <LayoutBodyWrapper>
        <Grid
          item
          container
          alignItems="right"
          justifyContent="right"
          justifyItems="right"
          sx={{ marginTop: 3 }}
        >
          <Grid item>
            <ProfilePopover />
          </Grid>
        </Grid>
        {children || <Outlet />}
      </LayoutBodyWrapper>
    </Fragment>
  );
};

export default LayoutStudentQuiz;
