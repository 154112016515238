import React, { useState, useEffect } from "react";
import { Card, Grid } from "@mui/material";
import { H4 } from "components/Typography";
import { useTranslation } from "react-i18next";
import AppTextField from "components/input-fields/AppTextField";
import { useFormik } from "formik";
import { KeyboardArrowDown } from "@mui/icons-material";
import { MenuItem } from "@mui/material";
import * as Yup from "yup"; // component props interface
import api from "utils/api";
import toast from "react-hot-toast";
import ExportExcel from "./exportExcel";

const ReportPerCurriculum = () => {
  const { t, i18n } = useTranslation();
  const [data, setData] = useState();
  const [curriculum, setCurriculum] = useState();
  const [coverData, setCoverData] = useState();

  const initialValues = {
    curriculum: "",
  };

  useEffect(() => {
    const fetchData = async () => {
      let result = await api.getCurriculumList();
      if (result.success) {
        const curr = result.data.rows.map((c) => ({
          id: c.id,
          name: c.curriculum_trans.find(
            (e) => e.locale.toLowerCase() == i18n.language.toLowerCase()
          )?.name,
        }));
        setCurriculum(curr);
      } else {
        toast.error("server error");
        return;
      }
    };
    fetchData();
  }, []);

  const validationSchema = Yup.object({
    curriculum: Yup.string().required("Curriculum is Required!"),
  });

  const { touched, errors, values, setFieldValue } = useFormik({
    initialValues,
    validationSchema: validationSchema,
  });

  const handleChange = (e) => {
    setFieldValue(e.target.name, e.target.value);
    const currName = curriculum.filter((f) => {
      return f.id == e.target.value;
    })[0]?.name;
    selectCurriculum(e.target.value, currName);
  };

  const selectCurriculum = async (curriculum, name) => {
    const result = await api.getReportByCurriculum(curriculum);
    if (result.status == 404) {
      toast.error(t("Reports.errors.quiz_not_processed"));
      return;
    }

    //discard groups with negative avg_scores
    //i.e contains questions not yet corrected.
    const report = result.data.filter((r) => {
      return r.avg_score_per_training_group >= 0;
    });

    let resultsFormatted = report?.map((r, index) => ({
      Groupe: r.training_group_name,
      Score: Math.round(r.avg_score_per_training_group) + "%",
    }));

    setData(resultsFormatted);
    setXLCoverData(name);
  };

  const setXLCoverData = (name) => {
    setCoverData([
      {
        Cursus: name,
      },
    ]);
  };

  return (
    <Grid item md={10} xs={12}>
      <Card sx={{ padding: 3 }}>
        <H4 pb={2}>{t("Reports.per_curriculum_title")}</H4>
        <Grid container spacing={1} alignContent="center" alignItems={"center"}>
          <Grid item>
            <AppTextField
              fullWidth
              select
              onChange={handleChange}
              name="curriculum"
              disabled={false}
              sx={{ width: 300 }}
              label={t("Reports.fields.curriculum")}
              SelectProps={{
                IconComponent: KeyboardArrowDown,
                value: values.curriculum,
              }}
              error={Boolean(touched.curriculum && errors.curriculum)}
              helperText={touched.curriculum && errors.curriculum}
            >
              <div></div>
              {curriculum?.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.name}
                </MenuItem>
              ))}
            </AppTextField>
          </Grid>
          <Grid item>
            <ExportExcel
              excelData={data}
              coverData={coverData}
              fileName={t("Reports.per_curriculum_title")}
              disabled={values.curriculum ? false : true}
            />
          </Grid>
        </Grid>
      </Card>
    </Grid>
  );
};

export default ReportPerCurriculum;
