import { Box, Button, Card, CircularProgress, IconButton } from "@mui/material";
import FlexBetween from "components/flexbox/FlexBetween";
import FlexBox from "components/flexbox/FlexBox";
import Illustration from "components/illustration";
import { H3, H6 } from "components/Typography";
import useFetch from "hooks/useFetch";
import i18next from "i18next";
import React, { useCallback } from "react";
import api from "utils/api";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { useNavigate } from "react-router-dom";
import Delete from "@mui/icons-material/Delete";
import { useTranslation } from "react-i18next";
import Add from "@mui/icons-material/Add";
import DeleteDialog from "components/DeleteDialog";
import { set } from "lodash";
import toast from "react-hot-toast";
import useMutate from "hooks/useMutate";
import AddTopicsDialog from "./addTopicsDialog";

export default function ModuleTopics({ moduleId }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { mutate } = useMutate();

  const [removeTopicDialog, setRemoveTopicDialog] = React.useState(false);
  const [selectedTopic, setSelectedTopic] = React.useState(null);
  const [addTopicsDialog, setAddTopicsDialog] = React.useState(false);

  const { data, isLoading, isError, refetch } = useFetch(
    useCallback(() => api.getTopicList({ modules: [moduleId] }), [moduleId])
  );

  let content = "";

  // What to show while loading
  if (isLoading) content = <CircularProgress />;
  // if there is an error
  else if (isError)
    content = <Illustration illustration="error" text="General.error" />;
  // if request is successful but there is no data
  else if (!!data && data.count === 0)
    content = <Illustration illustration="noResult" text="General.no_data" />;
  else
    content = data?.rows.map((item) => (
      <FlexBetween
        key={item.id}
        sx={{
          borderBottom: 1,
          padding: "1rem 1.5rem",
          borderColor: "grey.200",
          "&:last-of-type": {
            borderBottom: 0,
          },
        }}
      >
        <FlexBox alignItems="center">
          <ViewModuleIcon style={{ fontSize: "20" }}></ViewModuleIcon>

          <Box ml={1}>
            <H6>
              {item.topic_trans.find(
                (m) => m.locale === i18next.language.toUpperCase()
              )?.name || ""}
            </H6>
          </Box>
        </FlexBox>

        <FlexBox sx={{ gap: 1 }}>
          <IconButton
            aria-label="more"
            onClick={() => {
              navigate("/topic/edit", {
                state: {
                  id: item.id,
                  name:
                    item.topic_trans.find(
                      (m) => m.locale === i18next.language.toUpperCase()
                    )?.name || "",
                  translationId:
                    item.topic_trans.find(
                      (m) => m.locale === i18next.language.toUpperCase()
                    )?.id || "",
                },
              });
            }}
          >
            <OpenInNewIcon style={{ fontSize: "20" }} />
          </IconButton>

          <IconButton aria-label="more" onClick={() => onRemoveTopic(item)}>
            <Delete style={{ fontSize: "20" }} />
          </IconButton>
        </FlexBox>
      </FlexBetween>
    ));

  const onRemoveTopic = (item) => {
    setSelectedTopic(item);
    setRemoveTopicDialog(true);
  };

  const handleRemoveTopic = async () => {
    try {
      await mutate(() => api.removeTopic({ topicId: selectedTopic.id }));
      toast.success(t("Topic.topic_removed_success"));
      setRemoveTopicDialog(false);
      refetch();
    } catch (err) {
      toast.error(t("General.message.delete.fail"));
    }
  };

  return (
    <>
      <FlexBetween direction="row" alignItems="center" sx={{ mb: 2 }}>
        {/* <Edit sx={{ fontSize: "30" }} /> */}
        <H3 mb={1}>{t("Topic.topic_list")}</H3>

        <Button
          variant="contained"
          startIcon={<Add />}
          size="small"
          onClick={() => setAddTopicsDialog(true)}
          sx={{ padding: "6px 12px" }}
        >
          {t("Topic.add_topic")}
        </Button>
      </FlexBetween>
      <Card>{content}</Card>

      <DeleteDialog
        title={t("Module.remove_topic.title")}
        description={t("Module.remove_topic.description")}
        open={removeTopicDialog}
        setOpen={setRemoveTopicDialog}
        handleDelete={handleRemoveTopic}
        buttonLabel={t("Module.unlink_topic")}
        // isDeleting={isDeletingQuiz}
      />

      <AddTopicsDialog
        open={addTopicsDialog}
        setOpen={setAddTopicsDialog}
        moduleId={moduleId}
        refetch={refetch}
      />
    </>
  );
}
