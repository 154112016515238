import * as React from "react";
import AppTextField from "components/input-fields/AppTextField";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";
import { IMaskMixin } from "react-imask";

export default function Timing() {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext();

  const defaultValue = `${Math.floor(values.duration / 60)
    .toString()
    .padStart(2, "0")}:${(values.duration % 60).toString().padStart(2, "0")}`;

  const [inputValue, setInputValue] = React.useState(defaultValue);

  return (
    <IMaskTimerField
      autoFocus
      fullWidth
      mask={"00:00"}
      label={t("Question.time")}
      placeholder="mm:ss"
      placeholderChar="0"
      lazy={false}
      // defaultValue={defaultValue}
      value={inputValue}
      onAccept={(value, _mask) => {
        setInputValue(value);
        if (value !== "00:00") {
          const fields = value?.split(":");
          const duration = Number(fields[0]) * 60 + Number(fields[1]);
          setFieldValue("duration", duration);
        } else setFieldValue("duration", null);
      }}
      sx={{ width: "100px" }}
    />
  );
}

const IMaskTimerField = IMaskMixin(({ ...props }) => {
  return <AppTextField {...props} />;
});
