import { useEffect, useState } from "react";
import { getLocalStorageItem, setLocalStorageItem } from "utils/utils";

const useLocalStorage = (key, initialValue) => {
  const [data, setData] = useState(initialValue);
  useEffect(() => {
    const getData = getLocalStorageItem(key);

    if (getData) {
      setData(getData);
    }
  }, [key]);

  const storeData = (updateValue) => {
    setData(updateValue);
    setLocalStorageItem(key, updateValue);
  };

  return {
    data,
    storeData,
  };
};

export default useLocalStorage;
