import { Button, Card, Grid, RadioGroup } from "@mui/material";
import AppRadio from "components/AppRadio";
import { H3 } from "components/Typography";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Remove from "icons/Remove";
import AppTextField from "components/input-fields/AppTextField";
import Add from "icons/Add";
import { useFormikContext } from "formik";
import uuid from "react-uuid";
import FlexBetween from "components/flexbox/FlexBetween";
import FlexBox from "components/flexbox/FlexBox";

export default function SingleChoice() {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext();

  const [inputValue, setInputValue] = useState("");

  return (
    <Card
      sx={{
        padding: 3,
      }}
    >
      <Grid item sm={12}>
        <H3 pb={2}>{t("Question.answers")}</H3>
        <RadioGroup>
          {values.answers?.map((item, index) => (
            <FlexBetween
              sx={{ gap: "12px", marginBottom: "12px" }}
              key={item.id}
            >
              <FlexBox sx={{ width: "100%" }}>
                <AppRadio
                  checked={item.checked}
                  onChange={() =>
                    setFieldValue(
                      "answers",
                      values.answers.map((answer) =>
                        item.id === answer.id
                          ? { ...answer, checked: true }
                          : { ...answer, checked: false }
                      )
                    )
                  }
                />
                <AppTextField
                  fullWidth
                  value={item.description}
                  onChange={(e) =>
                    setFieldValue(
                      `answers.${index}.description`,
                      e.target.value
                    )
                  }
                  required
                />
              </FlexBox>
              <Button
                onClick={() =>
                  setFieldValue(
                    "answers",
                    values.answers.filter((answer) => answer !== item)
                  )
                }
                variant="outlined"
                size="small"
              >
                <Remove />
              </Button>
            </FlexBetween>
          ))}
        </RadioGroup>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setFieldValue("answers", [
              ...values.answers,
              {
                description: inputValue,
                checked: false,
                id: uuid(),
                active: true,
              },
            ]);
            setInputValue("");
          }}
        >
          <FlexBetween sx={{ gap: "12px", marginBottom: "12px" }}>
            <AppTextField
              fullWidth
              label={t("Question.answer")}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              required
            />
            <Button variant="contained" sx={{ flexShrink: 0 }} type="submit">
              <Add />
            </Button>
          </FlexBetween>
        </form>
      </Grid>
    </Card>
  );
}
