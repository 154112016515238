import { KeyboardArrowDown } from "@mui/icons-material";
import { MenuItem } from "@mui/material";
import AppTextField from "components/input-fields/AppTextField";
import { useFormikContext } from "formik";
import useFetch from "hooks/useFetch";
import i18next from "i18next";
import React from "react";
import { useTranslation } from "react-i18next";
import api from "utils/api";

export default function SelectQuizType({ disabled = false }) {
  const { t } = useTranslation();

  const { handleChange, touched, errors, values } = useFormikContext();

  const { data: types } = useFetch(
    React.useCallback(() => api.getQuizTypes(), [])
  );

  return (
    <AppTextField
      select
      name="quizTypeId"
      onChange={(e) => {
        handleChange(e);
      }}
      sx={{ width: 200 }}
      label={t("Question.classification")}
      SelectProps={{
        IconComponent: KeyboardArrowDown,
        value: values.quizTypeId,
      }}
      error={Boolean(touched.quizTypeId && errors.quizTypeId)}
      helperText={touched.quizTypeId && errors.quizTypeId}
    >
      <div> </div>
      {types?.map((item) => (
        <MenuItem key={item.id} value={item.id}>
          {item.quiz_types_trans.find(
            (m) => m.locale === i18next.language.toUpperCase()
          )?.type || ""}
        </MenuItem>
      ))}
    </AppTextField>
  );
}
