import SchoolIcon from "@mui/icons-material/School";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import SettingsIcon from "@mui/icons-material/Settings";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import ListAltIcon from "@mui/icons-material/ListAlt";
import CheckIcon from "@mui/icons-material/Check";
import ConstructionIcon from "@mui/icons-material/Construction";
import AssessmentIcon from "@mui/icons-material/Assessment";
import ManageAccountsIcon from "@mui/icons-material/ManageAccounts";
import DashboardIcon from "@mui/icons-material/Dashboard";
import Topic from "@mui/icons-material/Topic";

import i18next from "i18n";
export const navigations_instructor = [
  {
    name: i18next.t("Quiz.list.title"),
    path: "/quiz/list",
    icon: ListAltIcon,
  },
  {
    name: i18next.t("Quiz.pending"),
    path: "/quiz/corrections",
    icon: CheckIcon,
  },
  {
    name: i18next.t("Quiz.add"),
    path: "/quiz/add",
    icon: AddCircleOutlineIcon,
  },
];
export const navigations = [
  {
    name: i18next.t("Dashboard.dashboard"),
    path: "/dashboard",
    icon: DashboardIcon,
  },
  {
    name: i18next.t("Curriculum.curriculum"),
    path: "/curriculum/list",
    icon: SchoolIcon,
  },
  {
    name: i18next.t("Module.module"),
    path: "/module/list",
    icon: ViewModuleIcon,
  },
  {
    name: i18next.t("Topic.topic"),
    path: "/topic/list",
    icon: Topic,
  },
  {
    name: i18next.t("Question.question"),
    path: "/question/list",
    icon: QuestionMarkIcon,
  },
  {
    name: i18next.t("Menu.training"),
    icon: LocalLibraryIcon,
    children: [
      {
        name: i18next.t("Firm.plural"),
        path: "/firm/list",
      },
      {
        name: i18next.t("Menu.instructors"),
        path: "/instructor/list",
      },
      {
        name: "Groupes de formation",
        path: "/training-group/list",
      },
    ],
  },
  {
    name: i18next.t("Reports.menu"),
    path: "/reports",
    icon: AssessmentIcon,
  },
  {
    type: "label",
    label: i18next.t("Menu.admin"),
  },
  {
    name: i18next.t("Menu.settings"),
    path: "/settings",
    icon: SettingsIcon,
  },
  {
    name: i18next.t("Menu.roles"),
    path: "/roles/",
    icon: ConstructionIcon,
  },
  {
    name: i18next.t("Menu.access_management"),
    path: "/access-management",
    icon: ManageAccountsIcon,
  },
];
