import api from "utils/api";
import { useLocation, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import Forbidden from "pages/forbidden";
import useAuth from "hooks/useAuth";
import { INSTRUCTOR_ROLE_ID } from "../../constants";

export default function RoutAuth({ children }) {
  const navigate = useNavigate();
  const location = useLocation();
  const [forbidden, setForbidden] = useState(null);
  const { role } = useAuth();

  useEffect(() => {
    getRouteAuth();
  }, []);

  useEffect(() => {
    if (
      role?.id == INSTRUCTOR_ROLE_ID &&
      (location.pathname == "/" || location.pathname == "/dashboard")
    ) {
      navigate("/quiz/list");
    }
  }, [role?.id, location.pathname]);

  const getRouteAuth = async () => {
    let result = await api.getRouteAuth(location.pathname);
    if (result.status !== 200) {
      setForbidden(true);
    } else {
      setForbidden(false);
    }
  };

  return (
    <div>
      {forbidden === true && <Forbidden />}
      {forbidden === false && <div>{children}</div>}
    </div>
  );
}
