import { Button, Card, Grid, RadioGroup, Stack, Switch } from "@mui/material";
import AppRadio from "components/AppRadio";
import { H3 } from "components/Typography";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import Remove from "icons/Remove";
import AppTextField from "components/input-fields/AppTextField";
import Add from "icons/Add";
import { useFormikContext } from "formik";
import uuid from "react-uuid";
import FlexBox from "components/flexbox/FlexBox";
import FlexBetween from "components/flexbox/FlexBetween";

export default function SingleChoice() {
  const { t } = useTranslation();
  const { values, setFieldValue } = useFormikContext();

  const [inputValue, setInputValue] = useState("");

  return (
    <Card
      sx={{
        padding: 3,
      }}
    >
      <Grid item sm={12}>
        <H3 pb={2}>{t("Question.answers")}</H3>
        <RadioGroup>
          {values.answers?.map((item, index) => (
            <Grid
              container
              justifyContent="space-between"
              alignItems="center"
              key={item.id}
            >
              <Grid item alignItems="center" xs={12} sm={7} md={8} mb={2}>
                <FlexBox sx={{ width: "100%" }}>
                  <AppRadio
                    checked={item.checked}
                    onChange={() =>
                      setFieldValue(
                        "answers",
                        values.answers.map((answer) =>
                          item.id === answer.id
                            ? { ...answer, checked: true }
                            : { ...answer, checked: false }
                        )
                      )
                    }
                  />
                  <AppTextField
                    fullWidth
                    value={item.description}
                    onChange={(e) =>
                      setFieldValue(
                        `answers.${index}.description`,
                        e.target.value
                      )
                    }
                    required
                  />
                </FlexBox>
              </Grid>

              <Grid item xs={12} sm={4} mb={2}>
                <Stack
                  direction="row"
                  alignItems="center"
                  columnGap={2}
                  sx={{ justifyContent: "end" }}
                >
                  <Stack direction="row" alignItems="center">
                    <Switch
                      size="small"
                      onChange={() =>
                        setFieldValue(
                          "answers",
                          values.answers.map((answer) =>
                            item.id === answer.id
                              ? { ...answer, active: !item.active }
                              : answer
                          )
                        )
                      }
                      checked={item.active}
                    />
                    <span>Active</span>
                  </Stack>
                  <Button
                    onClick={() =>
                      setFieldValue(
                        "answers",
                        values.answers.filter((answer) => answer !== item)
                      )
                    }
                    variant="outlined"
                    sx={{ flexShrink: 0 }}
                  >
                    <Remove />
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          ))}
        </RadioGroup>
        <form
          onSubmit={(e) => {
            e.preventDefault();
            setFieldValue("answers", [
              ...values.answers,
              {
                description: inputValue,
                checked: false,
                id: uuid(),
                active: true,
              },
            ]);
            setInputValue("");
          }}
        >
          <FlexBetween sx={{ gap: "12px", marginBottom: "12px" }}>
            <AppTextField
              fullWidth
              label={t("Question.answer")}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
              required
            />
            <Button variant="contained" sx={{ flexShrink: 0 }} type="submit">
              <Add />
            </Button>
          </FlexBetween>
        </form>
      </Grid>
    </Card>
  );
}
