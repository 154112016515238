import React, { useState, useEffect } from "react";
import { Card, Grid } from "@mui/material";
import { H4 } from "components/Typography";
import { useTranslation } from "react-i18next";
import AppTextField from "components/input-fields/AppTextField";
import { useFormik } from "formik";
import { KeyboardArrowDown } from "@mui/icons-material";
import { MenuItem } from "@mui/material";
import * as Yup from "yup";
import api from "utils/api";
import ExportExcel from "./exportExcel";
import { formatEmployeeGroup } from "utils/utils";
import useAuth from "hooks/useAuth";
import toast from "react-hot-toast";
import { MANAGER_ROLE_ID } from "../../constants";

const ReportPerQuiz = () => {
  const [curriculum, setCurriculum] = useState();
  const [module, setModule] = useState();
  const [group, setGroup] = useState();

  const { t, i18n } = useTranslation();
  const { user, role } = useAuth();
  const [data, setData] = useState();
  const [coverData, setCoverData] = useState();
  const [curriculumName, setCurriculumName] = useState();
  const [moduleName, setModuleName] = useState();
  const [selectedGroup, setSelectedGroup] = useState();
  const [disableExport, setDisableExport] = useState(true);

  const initialValues = {
    curriculum: "",
    module: "",
    group: "",
  };

  useEffect(() => {
    const fetchData = async () => {
      let curriculum = await api.getCurriculumWithQuiz();
      curriculum = curriculum.data?.map((c) => ({
        id: c.id,
        name: c.curriculum_trans[0]?.name,
      }));
      setCurriculum(curriculum);
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (selectedGroup) {
      selectGroup(values.module, selectedGroup.id);
    }
  }, [selectedGroup]);

  const validationSchema = Yup.object({
    curriculum: Yup.string().required("Curriculum is Required!"),
  });

  const { handleSubmit, touched, errors, values, setFieldValue } = useFormik({
    initialValues,
    validationSchema: validationSchema,
  });

  const handleChange = async (e) => {
    setFieldValue(e.target.name, e.target.value);
    if (e.target.name == "curriculum") {
      values.module = "";
      selectCurriculum(e.target.value);
      const currName = curriculum.filter((f) => {
        return f.id == e.target.value;
      })[0]?.name;
      setCurriculumName(currName);
    } else if (e.target.name == "module") {
      values.group = "";
      selectModule(e.target.value);
      const modName = module.filter((f) => {
        return f.id == e.target.value;
      })[0]?.name;
      setModuleName(modName);
    } else if (e.target.name == "group") {
      const selected_group = group.filter((f) => {
        return f.id == e.target.value;
      })[0];
      setSelectedGroup(selected_group);
    }
  };

  const setXLCoverData = (instructor, quiz) => {
    const instructorName = instructor?.instructor?.employee
      ? instructor.instructor?.employee.firstName +
        " " +
        instructor?.instructor?.employee.lastName
      : instructor?.instructor?.firstName +
        " " +
        instructor?.instructor?.lastName;
    const quizDate = quiz.startDate;
    setCoverData([
      {
        Cursus: curriculumName,
        Module: moduleName,
        Groupe: selectedGroup.name,
        Formateur: instructorName,
        Date: quizDate,
      },
    ]);
  };

  const getQuizGrade = (score, grades) => {
    const grade = grades?.filter((f) => {
      return score >= f.min && score <= f.max;
    })[0];
    const grade_name = grade?.quiz_grades_trans.find(
      (e) => e.locale.toLowerCase() == i18n.language.toLowerCase()
    )?.grade;

    return grade_name;
  };

  const selectCurriculum = async (curriculum) => {
    setDisableExport(true);
    let modules = await api.getModulesOfCurriculumWithQuiz(curriculum);
    if (modules?.data?.length == 0) {
      toast.error(t("Reports.errors.no_quiz_for_mod_cur"));
      return;
    }
    modules = modules.data?.map((c) => ({
      id: c.id,
      name: c.module_trans[0]?.name,
    }));
    setModule(modules);
  };

  const selectModule = async (curriculum) => {
    setDisableExport(true);
    let groups = await api.getTrainingGroupsByModule(curriculum);
    if (groups?.data?.length == 0) {
      toast.error(t("Reports.errors.no_quiz_for_mod"));
      return;
    }
    groups = groups.data?.map((c) => ({
      id: c.id,
      name: c.name,
    }));
    setGroup(groups);
  };

  const selectGroup = async (module, group) => {
    const result = await api.getReportByQuiz(module, group);
    if (result.status == 404) {
      setDisableExport(true);
      toast.error(t("Reports.errors.quiz_not_processed"));
      return;
    }
    setDisableExport(false);
    const quizGrades = await api.getQuizGrades({
      type: result?.data.quiz.typeId,
    });

    let resultsFormatted = generateReportData(result?.data, quizGrades);

    setData(resultsFormatted);
    setXLCoverData(result.data?.instructor, result?.data?.quiz);
  };

  const generateReportData = (data, quizGrades) => {
    let currentClassement = 1;
    let lastTotalScore = -1;

    return data?.report
      ?.sort((a, b) => b.total_score - a.total_score)
      .map((r, index) => {
        if (lastTotalScore !== r.total_score) {
          // normal classement
          currentClassement = index + 1;
        } else {
          // keep the same classement
        }
        // save the last total score to use it the next time
        lastTotalScore = r.total_score;

        // on va retourner l'employee dans deux cas:
        // si l'utilisateur authentifié n'a pas un manager
        // ou bien l'utilisateur authentifié est un manager et cet employé membre de ce manager
        if (
          role?.id !== MANAGER_ROLE_ID ||
          (role?.id === MANAGER_ROLE_ID &&
            !!r.employee.managerId &&
            user.id === r.employee?.managerId)
        ) {
          return {
            Nom: r.employee?.firstName + " " + r.employee?.lastName,
            Matricule: r.employee?.employee_id,
            Fonction: r.employee?.job_title?.job,
            Entite: formatEmployeeGroup(
              r.employee?.company_group,
              user?.rootGroupId
            ),
            Score: Math.round(r.total_score) + "%",
            Décision: getQuizGrade(r.total_score, quizGrades?.data),
            Classement: currentClassement,
          };
        } else {
          return undefined;
        }
      })
      .filter((item) => !!item);
  };

  return (
    <Grid item md={10} xs={12}>
      <Card sx={{ padding: 3 }}>
        <H4 pb={2}>{t("Reports.per_quiz_title")}</H4>
        <form onSubmit={handleSubmit}>
          <Grid
            container
            spacing={1}
            alignContent="center"
            alignItems={"center"}
          >
            <Grid item>
              <AppTextField
                select
                onChange={handleChange}
                name="curriculum"
                disabled={false}
                sx={{ width: 250 }}
                label={t("Reports.fields.curriculum")}
                SelectProps={{
                  IconComponent: KeyboardArrowDown,
                  value: values.curriculum,
                }}
                error={Boolean(touched.curriculum && errors.curriculum)}
                helperText={touched.curriculum && errors.curriculum}
              >
                <div></div>
                {curriculum?.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </AppTextField>
            </Grid>
            <Grid item>
              <AppTextField
                select
                name="module"
                onChange={handleChange}
                disabled={false}
                sx={{ width: 250 }}
                label={t("Reports.fields.module")}
                SelectProps={{
                  IconComponent: KeyboardArrowDown,
                  value: values.module,
                }}
                error={Boolean(touched.module && errors.module)}
                helperText={touched.module && errors.module}
              >
                <div></div>
                {module?.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </AppTextField>
            </Grid>
            <Grid item>
              <AppTextField
                select
                name="group"
                value="group"
                onChange={handleChange}
                disabled={false}
                sx={{ width: 250 }}
                label={t("Reports.fields.group")}
                SelectProps={{
                  IconComponent: KeyboardArrowDown,
                  value: values.group,
                }}
                error={Boolean(touched.group && errors.group)}
                helperText={touched.group && errors.group}
              >
                <div></div>
                {group?.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </AppTextField>
            </Grid>
            <Grid item>
              <ExportExcel
                excelData={data}
                coverData={coverData}
                fileName={t("Reports.per_quiz_title")}
                disabled={disableExport}
              />
            </Grid>
          </Grid>
        </form>
      </Card>
    </Grid>
  );
};

export default ReportPerQuiz;
