import { Grid } from "@mui/material";
import { useTranslation } from "react-i18next";
import * as illustrations from "assets/illustration";

export default function Illustration({ illustration, text }) {
  const { t } = useTranslation();

  return (
    <Grid
      container
      spacing={0}
      direction="column"
      alignItems="center"
      justifyContent="center"
      style={{ minHeight: "50vh" }}
    >
      <img
        src={illustrations[illustration]}
        width="40%"
        alt="Earnings"
        style={{
          objectFit: "cover",
          objectPosition: "bottom",
          opacity: 0.2,
        }}
      />
      <Grid item sx={{ marginTop: 5 }}>
        {t(text)}
      </Grid>
    </Grid>
  );
}
