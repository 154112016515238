import { KeyboardArrowDown } from "@mui/icons-material";
import { MenuItem } from "@mui/material";
import AppTextField from "components/input-fields/AppTextField";
import { useFormikContext } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";

export default function SelectDifficulty({ disabled = false }) {
  const { t } = useTranslation();

  const { handleChange, touched, errors, values } = useFormikContext();

  // const { data: difficulties, isLoading, isError } = useFetch(React.useCallback(() => api.getQuestionDifficulty(), []));

  const difficulties = [1, 2, 3];

  return (
    <AppTextField
      select
      name="difficulty"
      disabled={disabled}
      onChange={handleChange}
      sx={{ width: 150 }}
      label={t("Question.difficulty")}
      SelectProps={{
        IconComponent: KeyboardArrowDown,
        value: values.difficulty,
      }}
      error={Boolean(touched.difficulty && errors.difficulty)}
      helperText={touched.difficulty && errors.difficulty}
    >
      <div></div>
      {difficulties?.map((item) => (
        <MenuItem key={item} value={item}>
          {t(`General.difficulty.${item}`)}
        </MenuItem>
      ))}
    </AppTextField>
  );
}
