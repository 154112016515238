/* eslint-disable import/no-anonymous-default-export */
import axios from "axios";
import { getLocalStorageItem } from "./utils";

let config = require("./../config");
//const parseContentRange = require('parse-content-range-header');

const client = axios.create({
  baseURL: config.app.server,
  json: true,
});

export default {
  async execute(method, resource, data, params) {
    let token = getLocalStorageItem("token");
    let role;
    if (getLocalStorageItem("role")) role = getLocalStorageItem("role");

    return client({
      method: method,
      url: resource,
      data: data,
      params: params,
      headers: {
        "x-access-token": token,
        role: role?.id,
        // "Access-Control-Allow-Credentials": true,
      },
    })
      .then((req) => {
        /*if (req.headers['content-range']) {
        req.data.content_range = parseContentRange(req.headers['content-range']);
      }*/
        req.success = true;
        return req;
      })
      .catch((err) => {
        console.log(err);
        if (err.response?.status === 401) {
          if (err.response?.data?.auth == false) {
            // window.getApp.$router.push("/login");
            // window.location.reload();
          }
        } else if (err.response?.status === 403) {
          // window.getApp.$router.push("/403");
          window.location.reload();
        } else {
          return { status: err.response?.status, error: true, ...err };
        }
      });
  },

  // Auth
  getRouteAuth(route) {
    return this.execute("get", `/routeauth?route=${route}`);
  },

  // Employees----------------------------------------
  getEmployeeDirectory(params) {
    let queryURL = `/employeedirectory`;
    return this.execute("get", queryURL, undefined, {
      ...params,
      sort: "lastName",
    });
  },

  getEmployeesByRole(role) {
    return this.execute("get", "/employees?roleId=" + role);
  },
  getEmployeesExcludeRole(role) {
    return this.execute("get", "/employees?exclude_role=" + role);
  },
  getEmployee(id) {
    return this.execute("get", `/employees/${id}`);
  },

  // User management
  getAccessManagementEmployees(params) {
    let queryURL = `/employee/access-management`;
    return this.execute("get", queryURL, undefined, {
      ...params,
      sort: "lastName",
    });
  },

  forceResetPassword(data) {
    return this.execute("post", `/employee/force-reset-password`, data);
  },

  // Company Groups----------------------------------------
  getCompanyGroups(parentGroupId) {
    if (!parentGroupId) {
      return this.execute("get", "/groups");
    } else {
      return this.execute("get", "/groups?parentGroupId=" + parentGroupId);
    }
  },

  //  Company Settings----------------------------------------
  getCompanySettings() {
    return this.execute("get", "/companysettings");
  },

  updateCompanySettings(data) {
    return this.execute("patch", "/companysettings", data);
  },

  //  Skillup Settings----------------------------------------
  getSkillupSettings() {
    return this.execute("get", "/skillup-settings");
  },
  updateSkillupSettings(data) {
    return this.execute("patch", "/skillup-settings", data);
  },
  uploadCompanyLogo(data) {
    return this.execute("post", "/skillup-settings/upload-logo", data);
  },

  //  login----------------------------------------
  login(data) {
    //return this.execute("post", `/api/auth/login`, data);
    return this.execute("post", `/auth/login`, data);
  },

  resetPasswordInstructor(data) {
    return this.execute("post", `/auth/reset`, data);
  },

  verifyPasswordTokenInstructor(data) {
    return this.execute("post", `/auth/verify_password_token`, data);
  },

  updatePasswordInstructor(data) {
    return this.execute("post", `/auth/update_password`, data);
  },

  getAuthorizedPlatfoms(data) {
    return this.execute("post", `/auth/authorized-platforms`, data);
  },

  //  Password reset----------------------------------------
  resetPassword(data) {
    return axios.post(`${config.app.positeams_server}/auth/reset`, data);
  },

  loginMfa(data) {
    return this.execute("post", `/auth/mfalogin`, data);
  },

  updatePassword(data) {
    return axios.post(
      `${config.app.positeams_server}/auth/update_password`,
      data
    );
  },

  verifyPasswordToken(data) {
    return axios.post(
      `${config.app.positeams_server}/auth/verify_password_token`,
      data
    );
  },

  //  Roles----------------------------------------
  getRoles() {
    return this.execute("get", `/roles`);
  },
  addRole(data) {
    return this.execute("post", `/roles`, data);
  },
  addRoleMembers(data) {
    return this.execute("post", `/rolemembers`, data);
  },
  updateRole(id, data) {
    return this.execute("patch", `/roles/${id}`, data);
  },
  deleteRole(id) {
    return this.execute("delete", `/roles/${id}`);
  },
  deleteRoleMember(roleId, employeeId) {
    return this.execute("delete", `/roles/members/${roleId}/${employeeId}`);
  },
  // getRolePermissions(roleId) {
  //   return this.execute("get", `/rolepermissions/${roleId}`);
  // },
  getRoleMembers(roleId) {
    return this.execute("get", `/roles/members/${roleId}`);
  },
  updatePermission({ roleId, permissionId, data }) {
    return this.execute(
      "patch",
      `/rolepermissions/${roleId}/${permissionId}`,
      data
    );
  },

  //  Student----------------------------------------
  studentCheckAccount(data) {
    return this.execute("post", `/auth/student/check-account`, data);
  },
  studentCreatePassword(data) {
    return this.execute("post", `/auth/student/create-password`, data);
  },
  studentLogin(data) {
    //return this.execute("post", `/student/login`, data);
    return this.execute("post", `/auth/student/login`, data);
  },
  isValidQuiz(id) {
    return this.execute("get", `/student/quiz/${id}`);
  },
  isEligibleForQuiz(quizCode, employeeId) {
    return this.execute(
      "get",
      `/student/quizeligible?code=${quizCode}&employeeId=${employeeId}`
    );
  },

  getQuizDetails(code, locale) {
    return this.execute(
      "get",
      `/student/quizdetails?quiz_code=${code}&locale=${locale}`
    );
  },
  getQuizQuestions(code, locale) {
    return this.execute(
      "get",
      `/student/quizquestions/${code}?locale=${locale}`
    );
  },
  getStudentResults() {
    return this.execute("get", `/student/results`);
  },
  getStudentAnswers(quizId) {
    return this.execute("get", `/student/answers/${quizId}`);
  },
  startQuiz(data) {
    return this.execute("post", `/student/quizstart`, data);
  },
  endQuiz(data) {
    return this.execute("post", `/student/quizend`, data);
  },
  getCompanyId(code) {
    return this.execute("get", `/student/company/${code}`);
  },

  // Dashboard----------------------------------------

  getDashboardStats() {
    return this.execute("get", `/dashboard/stats`);
  },

  //  Curriculum----------------------------------------
  getCurriculum(id) {
    return this.execute("get", `/curriculum/${id}`);
  },
  getCurriculumList(params = { active: 1 }) {
    return this.execute("get", "/curriculum", undefined, params);
  },
  addCurriculum(data) {
    return this.execute("post", `/curriculum`, data);
  },
  importCurriculums(data) {
    return this.execute("post", `/curriculum/import`, data);
  },
  updateCurriculum({ id, data }) {
    return this.execute("patch", `/curriculum/${id}`, data);
  },
  deleteCurriculum(id) {
    return this.execute("delete", `/curriculum/${id}`);
  },

  // Firm----------------------------------------
  getFirm(id) {
    return this.execute("get", `/coaching-firm/${id}`);
  },
  getFirmList(params = { active: 1 }) {
    return this.execute("get", "/coaching-firm", undefined, params);
  },
  addFirm(data) {
    return this.execute("post", `/coaching-firm`, data);
  },
  updateFirm({ id, data }) {
    return this.execute("patch", `/coaching-firm/${id}`, data);
  },
  deleteFirm(id) {
    return this.execute("delete", `/coaching-firm/${id}`);
  },
  uploadFirmAvatar(data) {
    return this.execute("post", `/coaching-firm/upload-avatar`, data);
  },
  getFirmModules(params = {}) {
    return this.execute("get", `/coaching-firm-modules`, undefined, params);
  },
  addFirmModules({ data, firm }) {
    return this.execute("post", `/coaching-firm-modules/${firm}`, data);
  },
  deleteFirmModules(id) {
    return this.execute("delete", `/coaching-firm-modules/${id}`);
  },
  getFirmUnselectedModules(firmId) {
    return this.execute("get", `/coaching-firm-unselected-modules/${firmId}`);
  },

  // External Instructor----------------------------------------

  // get single external instructor by id
  getInstructor(id) {
    return this.execute("get", `/instructor/${id}`);
  },

  // get instructors list
  getInstructorList(params = {}) {
    return this.execute("get", "/instructor", undefined, params);
  },

  // create new instructor
  addInstructor(data) {
    return this.execute("post", `/instructor`, data);
  },

  // upload instructor avatar
  uploadInstructorAvatar(data) {
    return this.execute("post", `/instructor/upload-avatar`, data);
  },

  // create new instructor
  importInstructors(data) {
    return this.execute("post", `/instructor/import`, data);
  },

  // update an existing instructor
  updateInstructor({ id, data }) {
    return this.execute("patch", `/instructor/${id}`, data);
  },

  // delete an instructor
  deleteInstructor(id) {
    return this.execute("delete", `/instructor/${id}`);
  },

  // get specific instructor modules
  getInstructorModules(id = null) {
    return this.execute("get", `/instructor/modules`, undefined, {
      instructor: id,
    });
  },

  // get specific instructor modules by employee id
  getInstructorModulesByEmployeeId() {
    return this.execute("get", `/instructor/modules-by-employee-id`);
  },

  // get specific instructor groups by employee id
  getInstructorGroupsByEmployeeId(params = {}) {
    return this.execute(
      "get",
      `/instructor/groups-by-employee-id`,
      undefined,
      params
    );
  },

  // link modules to instructor
  addInstructorModules({ id, modules }) {
    return this.execute("post", `/instructor/${id}/modules`, modules);
  },

  // unlink module from instructor
  deleteInstructorModule(moduleId, instructorId) {
    return this.execute(
      "delete",
      `/instructor/modules/${moduleId}/${instructorId}`
    );
  },

  // get instructor unselected modules
  getInstructorUnselectedModules(id) {
    return this.execute("get", `/instructor/${id}/unselected-modules`);
  },

  //  Module----------------------------------------
  getModule(id) {
    return this.execute("get", `/module/${id}`);
  },
  getModuleList(params = { active: 1 }) {
    return this.execute("get", `/module`, undefined, params);
  },
  getModuleListByCurriculum(id) {
    return this.execute("get", `/module?curriculumId=${id}`);
  },
  getModuleCurriculums(id) {
    //get curriculums associated to this module
    return this.execute("get", `/modulecurriculums/${id}`);
  },
  getModuleInstructors(id) {
    //get instructors associated to this module
    return this.execute("get", `/moduleinstructors/${id}`);
  },

  // get modules for selector
  getModuleDirectory(params) {
    return this.execute("get", `/module-directory`, undefined, params);
  },

  addModule(data) {
    return this.execute("post", `/module`, data);
  },

  importModules(data) {
    return this.execute("post", `/module/import`, data);
  },

  importAssociationModuleCurriculum(data) {
    return this.execute("post", `/module/import-association`, data);
  },

  deleteModule(id) {
    return this.execute("delete", `/module/${id}`);
  },

  addModuleToCurriculum(data) {
    return this.execute("post", `/curriculummodule`, data);
  },
  deleteCurriculumModule(id) {
    return this.execute("delete", `/curriculummodule/${id}`);
  },
  updateModule(id, data) {
    return this.execute("patch", `/module/${id}`, data);
  },
  removeTopic(data) {
    return this.execute("post", `/module/remove-topic`, data);
  },
  addTopics(data) {
    return this.execute("post", `/module/add-topics`, data);
  },

  // topics ----------------------------------------
  getTopic(id) {
    return this.execute("get", `/topic/${id}`);
  },
  getTopicList(params = { active: 1 }) {
    return this.execute("get", `/topic`, undefined, params);
  },
  addTopic(data) {
    return this.execute("post", `/topic`, data);
  },
  importTopics(data) {
    return this.execute("post", `/topic/import`, data);
  },

  updateTopic(id, data) {
    return this.execute("patch", `/topic/${id}`, data);
  },
  deleteTopic(id) {
    return this.execute("delete", `/topic/${id}`);
  },

  // topics ----------------------------------------
  getTopic(id) {
    return this.execute("get", `/topic/${id}`);
  },
  getTopicList(params = { active: 1 }) {
    return this.execute("get", `/topic`, undefined, params);
  },
  addTopic(data) {
    return this.execute("post", `/topic`, data);
  },
  updateTopic(id, data) {
    return this.execute("patch", `/topic/${id}`, data);
  },
  deleteTopic(id) {
    return this.execute("delete", `/topic/${id}`);
  },

  //  Instructors----------------------------------------
  // getInstructorList() {
  //   return this.execute("get", "/instructor");
  // },
  addInstructorToModule(data) {
    return this.execute("post", `/moduleinstructors`, data);
  },
  deleteModuleInstructor(id) {
    return this.execute("delete", `/moduleinstructor/${id}`);
  },

  // ** Training groups
  // *** list
  getTrainingGroupList(params = { active: 1 }) {
    return this.execute("get", `/training-group`, undefined, params);
  },
  // *** insert
  addTrainingGroup(data) {
    return this.execute("post", `/training-group`, data);
  },
  // *** get single
  getTrainingGroup(id) {
    return this.execute("get", `/training-group/${id}`);
  },
  // *** update
  updateTrainingGroup({ id, data }) {
    return this.execute("patch", `/training-group/${id}`, data);
  },
  // *** delete
  deleteTrainingGroup(id) {
    return this.execute("delete", `/training-group/${id}`);
  },
  // *** remove module
  removeTrainingGroupModule(id) {
    return this.execute("delete", `/training-group/module/${id}`);
  },
  // *** remove module
  removeTrainingGroupParticipant(id) {
    return this.execute("delete", `/training-group/participant/${id}`);
  },
  // *** get directory
  getTrainingGroupDirectory(params) {
    return this.execute("get", `/training-group-directory`, undefined, params);
  },
  // *** get participants
  getTrainingGroupParticipants(id) {
    return this.execute("get", `/training-group/${id}/participants`);
  },

  // ** Quiz Corrections

  // *** list quizes with open questions awaiting correct
  getQuizListWithOpenQuestions(params = { active: 1 }) {
    return this.execute("get", `/corrections`, undefined, params);
  },
  // *** get anwers pending correction for a quiz
  getPendingOpenQuestions(id) {
    return this.execute("get", `/corrections/${id}`);
  },

  treatAnswer(id, data) {
    return this.execute("patch", `/corrections/${id}`, data);
  },

  // ** Quiz

  // *** list
  getQuizList(params = { active: 1 }) {
    return this.execute("get", `/quiz`, undefined, params);
  },

  // *** insert
  addQuiz(data) {
    return this.execute("post", `/quiz`, data);
  },
  // *** get single
  getQuiz(id) {
    return this.execute("get", `/quiz/${id}`);
  },
  // *** update a quiz
  updateQuiz({ id, data }) {
    return this.execute("patch", `/quiz/${id}`, data);
  },
  // *** delete
  deleteQuiz(id) {
    return this.execute("delete", `/quiz/${id}`);
  },

  // *** get quiz code
  getQuizCode(id) {
    return this.execute("get", `/quizcode/${id}`);
  },

  // *** get quiz types
  // getQuizTypes() {
  //   return this.execute("get", `/quiztypes`);
  // },

  // Reports----------------------------------------
  getCurriculumWithQuiz() {
    return this.execute("get", `/reports/curriculum`);
  },
  getModulesOfCurriculumWithQuiz(curriculum) {
    return this.execute("get", `/reports/module/${curriculum}`);
  },
  getTrainingGroupsByModule(module) {
    return this.execute("get", `/reports/traininggroup/${module}`);
  },
  getReportByQuiz(moduleId, trainingGroupId) {
    return this.execute(
      "get",
      `/reports/byquiz/${moduleId}/${trainingGroupId}`
    );
  },
  getReportByTrainingGroup(trainingGroupId) {
    return this.execute("get", `/reports/bytraininggroup/${trainingGroupId}`);
  },
  getReportByCurriculum(curriculumId) {
    return this.execute("get", `/reports/bycurriculum/${curriculumId}`);
  },

  //  Questions----------------------------------------
  addQuestion(data) {
    return this.execute("post", `/question`, data);
  },
  importQuestions(data) {
    return this.execute("post", `/question/import`, data);
  },
  updateQuestion({ id, data }) {
    return this.execute("patch", `/question/${id}`, data);
  },

  deleteQuestion(id) {
    return this.execute("delete", `/question/${id}`);
  },

  getQuestion(id) {
    return this.execute("get", `/question/${id}`);
  },

  getQuestionList(params = { active: 1 }) {
    return this.execute("get", `/question`, undefined, params);
  },

  getQuestionItems(id) {
    return this.execute("get", `/question/${id}/items`);
  },

  getQuestionsByModule(module_id) {
    return this.execute("get", `/questionsbymodule?moduleId=${module_id}`);
  },

  uploadResponseDocument(data, question) {
    return this.execute("post", `/upload/responsedocument/${question}`, data);
  },

  removeResponseDocument(data, question) {
    return this.execute("post", `/remove/responsedocument/${question}`, data);
  },

  //  Question Items----------------------------------------
  addQuestionItem(data) {
    return this.execute("post", `/questionitems`, data);
  },

  deleteQuestionItem(id) {
    return this.execute("delete", `/questionitems/${id}`);
  },

  //  Question Difficulty----------------------------------------
  getQuestionDifficulty(params = {}) {
    return this.execute("get", "/questiondifficulty", undefined, params);
  },

  getScore() {
    return this.execute("get", "/score");
  },

  updateScore(data) {
    return this.execute("put", "/score", data);
  },

  //  Question Distribution----------------------------------------
  getQuestionDistribution() {
    return this.execute("get", "/question-distribution");
  },
  getQuestionDistributionByDifficulty(difficultyId) {
    return this.execute("get", `/question-distribution/${difficultyId}`);
  },

  updateQuestionDistribution(data) {
    return this.execute("put", "/question-distribution", data);
  },

  // Quiz Grades----------------------------------------
  getQuizGrades(params) {
    return this.execute("get", "/quiz-grades", undefined, params);
  },

  updateQuizGrades({ data, type }) {
    return this.execute("put", "/quiz-grades", data, { type });
  },

  // Quiz types----------------------------------------
  getQuizTypes() {
    return this.execute("get", "/quiz-types");
  },

  //  Question Difficulty----------------------------------------
  // getQuestionDifficulty() {
  //   return this.execute("get", "/questiondifficulty");
  // },

  //  Response Extra Info----------------------------------------
  addResponseExtraInfo(data) {
    return this.execute("post", `/responseextrainfo`, data);
  },

  deleteResponseExtraInfo(id) {
    return this.execute("delete", `/responseextrainfo/${id}`);
  },

  updateResponseExtraInfo(data) {
    return this.execute("patch", `/responseextrainfo`, data);
  },

  updateResponseExtraInfoByQuestionId({ questionId, data }) {
    return this.execute("patch", `/responseextrainfo/${questionId}`, data);
  },
  //  Instructors----------------------------------------
  getQuestionTypes() {
    return this.execute("get", `/questiontypes`);
  },

  //  Notifications----------------------------------------
  getUserNotifications(employee_id, all) {
    if (all) {
      return this.execute(
        "get",
        `/usernotifications?employeeId=${employee_id}&sort=-createdAt`
      );
    } else {
      return this.execute(
        "get",
        `/usernotifications?employeeId=${employee_id}&sort=-createdAt&count=5&scope=new_notifications`
      );
    }
  },

  createUserNotification(data) {
    return this.execute("post", "/usernotifications", data);
  },

  clearUserNotification() {
    return this.execute("post", "/api/custom/clearusernotifications");
  },

  //  Role----------------------------------------
  getRole() {
    // return this.execute('get', '/role?sort=-is_default');
    return this.execute("get", "/api/custom/roles?sort=-is_default");
  },

  //  Permission list----------------------------------------
  getPermission() {
    return this.execute("get", "/permissions");
  },

  //  Role Permissions----------------------------------------
  getRolePermissions(role_id) {
    return this.execute("get", `/rolepermissions/${role_id}`);
  },
  createRolePermission(data) {
    return this.execute("post", `/rolepermissions`, data);
  },
  deleteRolePermission(id) {
    return this.execute("delete", `/rolepermissions/${id}`);
  },

  // Permissions call ----------
  hasPermission(data) {
    return this.execute("post", "/api/custom/haspermission", data);
  },

  getLevelScore() {
    return this.execute("get", `/questiondifficulty`);
  },
};
