// config.js
// const env = process.env.NODE_ENV; // 'development' or 'production'
// const env = "staging";
const env = "production";
// const env = "attijari";

const development = {
  app: {
    server: "http://localhost:9081",
    client: "http://localhost:3000/#",
    positeams_server: "http://localhost:8081/api",
    // server: "http://staging.positeams.com:9081",
  },
  mawaridSurveyPortalUrl: "https://mawaridsurvey.positeams.com",
};

const testing = {
  app: {
    server: "http://phrtestserver:9081",
  },
};

const production = {
  app: {
    server: "https://skillup.positeams.com:9443",
    client: "https://academy.positeams.com/#",
    positeams_server: "https://app.positeams.com/api",
  },
  mawaridSurveyPortalUrl: "https://hrsurvey.positeams.com",
};

const attijari = {
  app: {
    server: "https://skillup.positeams.com:9443",
    client: "https://hrsurvey.positeams.com/skillup/#",
    positeams_server: "https://app.positeams.com/api",
  },
  mawaridSurveyPortalUrl: "https://hrsurvey.positeams.com",
};

const staging = {
  app: {
    server: "http://staging.positeams.com:9081",
    client: "http://staging.positeams.com:91/#",
    positeams_server: "http://staging.positeams.com:8081/api",
  },
  mawaridSurveyPortalUrl: "http://staging.positeams.com:71",
};

const config = {
  development,
  testing,
  production,
  attijari,
  staging,
};

module.exports = config[env];
