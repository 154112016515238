let config = require("./config");

export const THEMES = {
  LIGHT: "light",
  DARK: "dark",
};
export const lightTheme = (theme) => theme.palette.mode === "light";
export const secondarySideBarWidth = 215;
export const secondarySideBarGap = 80;

// LOGIN
export const MFA_REQUIRED = 1;
export const ROLE_SELECTION_REQUIRED = 2;
export const HAS_NO_ROLE = 3;
export const EXTERNAL_INSTRUCTOR_INVALID_PASSWORD = 4;

// ROLES
export const ADMIN_ROLE_ID = 1;
export const INSTRUCTOR_ROLE_ID = 2;
export const MANAGER_ROLE_ID = 3;

/*-------------- Student constants ----------------------*/
// QUESTION TYPES
export const QUESTION_TYPE_OPEN = "OPEN";
export const QUESTION_TYPE_MULTIPLE = "MULTIPLE_CHOICE";
export const QUESTION_TYPE_SINGLE = "SINGLE_CHOICE";

// QUIZ TIMING
export const TIMING_PER_QUESTION = "PER_QUESTION";
export const TIMING_PER_QUIZ = "PER_QUIZ";

// QUIZ NAVIGATION DIRECTION
export const NAVIGATION_NEXT = "NEXT";
export const NAVIGATION_PREV = "PREV";

// URL'S
export const QUIZ_ROUTE = "/participant/quiz?code=";
export const RESULTS_ROUTE = "/participant?coid=";

// import templates paths
export const IMPORT_CURRICULUMS_PATH = `${config.app.server}/documents/ImportCurriculumsTempalte.xlsx`;
export const IMPORT_ASSOCIATIONS_MODULE_CURRICULUM_PATH = `${config.app.server}/documents/ImportAssociationsModuleCurriculumTemplate.xlsx`;
export const IMPORT_MODULES_PATH = `${config.app.server}/documents/ImportModulesTemplate.xlsx`;
export const IMPORT_TOPICS_PATH = `${config.app.server}/documents/ImportTopicsTemplate.xlsx`;
export const IMPORT_QUESTIONS_PATH = `${config.app.server}/documents/ImportQuestionsTemplate.xlsx`;
