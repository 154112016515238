import { Box, Button, IconButton, Paper, Stack, styled } from "@mui/material";
import AppModal from "components/AppModal";
import FlexBox from "components/flexbox/FlexBox";
import AppTextField from "components/input-fields/AppTextField";
import { H3, H6 } from "components/Typography";
import { useTranslation } from "react-i18next";
import SaveIcon from "@mui/icons-material/Save";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { red } from "@mui/material/colors";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import NotesIcon from "@mui/icons-material/Notes";
import FileUploadInput from "components/input-fields/FileUploadInput";
import { useFormikContext } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";

const StyledAppModal = styled(AppModal)(({ theme }) => ({
  width: 400,
  [theme.breakpoints.down(400)]: {
    width: 300,
  },
}));

const AdditionalInformation = ({ open, setOpen }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const { handleChange, touched, errors, setFieldValue, values } =
    useFormikContext();

  return (
    <StyledAppModal
      open={open}
      keepMounted={true}
      handleClose={(_e, reason) => {
        if (reason === "backdropClick" || reason === "escapeKeyDown") return;
      }}
    >
      <H3 sx={{ marginBottom: 2 }}>{t("Question.additional_information")}</H3>

      <Box mb={2}>
        <H6 mb={1}>
          <Stack direction="row" alignItems="center">
            <PictureAsPdfIcon color="action" sx={{ marginRight: 1 }} />
            Document
          </Stack>
        </H6>
        <FileUploadInput
          handleOnChange={(event) => {
            setFieldValue("attachments.file", [
              ...values.attachments.file,
              ...event.currentTarget.files,
            ]);
            event.target.value = "";
          }}
          accept=".xls,.xlsx,.pdf,.doc,.docx,.txt"
          text={t("General.choose-multiple-files")}
          multiple={true}
        />
        {!!values?.attachments?.file &&
          values?.attachments?.file?.at(0) instanceof File && (
            <>
              <br />
              <FlexBox mb={1} sx={{ justifyContent: "end" }}>
                <Button
                  variant="text"
                  endIcon={<CloseIcon />}
                  onClick={() => setFieldValue("attachments.file", [])}
                >
                  Clear All
                </Button>
              </FlexBox>
              <Box>
                <Stack
                  direction="column"
                  rowGap={1}
                  sx={{
                    overflowY: "auto",
                    background: theme.palette.background.default,
                  }}
                  maxHeight="200px"
                  p={1}
                  borderRadius={1}
                >
                  {Array.from(values.attachments?.file)?.map((file) => (
                    <Paper key={file.name} elevation={1}>
                      <Stack
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <H6 key={file.name} px={1} py={0.5}>
                          {file.name}
                        </H6>
                        <IconButton
                          variant="text"
                          onClick={() =>
                            setFieldValue(
                              "attachments.file",
                              values.attachments?.file.filter(
                                (f) => f.name !== file.name
                              )
                            )
                          }
                        >
                          <CloseIcon sx={{ fontSize: "18px" }} />
                        </IconButton>
                      </Stack>
                    </Paper>
                  ))}
                </Stack>
              </Box>
            </>
          )}
      </Box>

      <Box mb={2}>
        <H6 mb={1}>
          <Stack direction="row" alignItems="center">
            <YouTubeIcon sx={{ color: red[500], marginRight: 1 }} />
            Video
          </Stack>
        </H6>
        <AppTextField
          fullWidth
          size="small"
          placeholder={t("Question.attachment_video_url")}
          name="attachments.videoURL"
          onChange={handleChange}
          value={values.attachments?.videoURL}
          error={Boolean(
            touched.attachments?.videoURL && errors.attachments?.videoURL
          )}
          helperText={
            touched.attachments?.videoURL && errors.attachments?.videoURL
          }
        />
      </Box>

      <Box mb={2}>
        <H6 mb={1}>
          <Stack direction="row" alignItems="center">
            <NotesIcon sx={{ marginRight: 1 }} />
            Texte
          </Stack>
        </H6>
        <AppTextField
          rows={4}
          fullWidth
          multiline
          size="small"
          placeholder="Description"
          name="attachments.text"
          onChange={handleChange}
          value={values.attachments?.text}
          error={Boolean(touched.attachments?.text && errors.attachments?.text)}
          helperText={touched.attachments?.text && errors.attachments?.text}
        />
      </Box>

      <FlexBox mt={4} gap={2}>
        <Button variant="outlined" fullWidth onClick={() => setOpen(false)}>
          {t("General.close")}
        </Button>
        <Button
          variant="contained"
          fullWidth
          startIcon={<SaveIcon />}
          onClick={() => setOpen(false)}
        >
          {t("General.save")}
        </Button>
      </FlexBox>
    </StyledAppModal>
  );
};

export default AdditionalInformation;
