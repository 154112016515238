import { Card, Button, Stack, Box, Typography } from "@mui/material";
import AppTextField from "components/input-fields/AppTextField";
import useAuth from "hooks/useAuth";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-hot-toast";
import AuthenticationLayout from "page-sections/authentication/AuthenticationLayout";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  EXTERNAL_INSTRUCTOR_INVALID_PASSWORD,
  HAS_NO_ROLE,
  MFA_REQUIRED,
  ROLE_SELECTION_REQUIRED,
} from "../../constants";
import { useState, useEffect } from "react";
import api from "utils/api";
import InfoIcon from "@mui/icons-material/Info";
import { error, primary } from "theme/colors";
import config from "../../config";
import { Small } from "components/Typography";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import skillupLogo from "assets/skillup.png";
import PositeamsLogo from "assets/positeams-logo.png";
import useSettings from "hooks/useSettings";

const Login = () => {
  const { login } = useAuth();

  const { t } = useTranslation();
  let navigate = useNavigate();
  const { setThemeColor } = useSettings();

  useEffect(() => {
    if (window.location.hostname.includes("hrsurvey")) {
      setThemeColor("#e65640");
    }
  }, []);

  const [hasNoRole, setHasNoRole] = useState(false);
  const [mawaridSurveyUrl, setMawaridSurveyUrl] = useState("");
  const [instructorForgotPassword, setInstructorForgotPassword] =
    useState(false);

  const initialValues = {
    email: "",
    password: "",
  };

  const validationSchema = Yup.object().shape({
    email: Yup.string().required("email is Required!"),
    password: Yup.string().required("password is Required!"),
  });

  const { values, handleChange, handleSubmit } = useFormik({
    initialValues,
    validationSchema,
    onSubmit: async (values) => {
      setHasNoRole(false);

      const result = await login(
        values.email,
        values.password,
        values.profile,
        values.internal
      );

      if (result == EXTERNAL_INSTRUCTOR_INVALID_PASSWORD) {
        setInstructorForgotPassword(true);
      } else {
        setInstructorForgotPassword(false);
      }

      if (result == HAS_NO_ROLE) {
        const res = await api.getAuthorizedPlatfoms({ email: values.email });

        setHasNoRole(true);
        setMawaridSurveyUrl(
          config.mawaridSurveyPortalUrl +
            "?platforms=" +
            res.data.authorizedPlatforms.toString()
        );
      } else if (result == MFA_REQUIRED) {
        toast.success(t("Login.code_sent"));
        navigate("/mfalogin", {
          state: {
            email: values.email,
            profile: values.profile,
            internal: values.internal,
          },
        });
      } else if (result == ROLE_SELECTION_REQUIRED) {
        navigate("/selectrole");
      } else navigate("/");
    },
  });

  const forgotPasswordClick = () => {
    const redirectUrl = "/login";
    navigate(`/forget-password?redirectUrl=${redirectUrl}&instructor=1`);
  };

  return (
    <AuthenticationLayout
      route="/register"
      description="New Here?"
      title={t("Login.signin")}
      routeName="Create an account"
    >
      <Card sx={{ backgroundColor: "#fff", padding: 5, borderRadius: 3 }}>
        <form onSubmit={handleSubmit}>
          <Stack gap={2}>
            <Box align="center" mb={1}>
              {window.location?.hostname?.includes("hrsurvey") ? (
                <img alt="company logo" width="200" src={skillupLogo}></img>
              ) : (
                <img alt="company logo" width="200" src={PositeamsLogo}></img>
              )}
            </Box>

            <Box textAlign="center" mb={1}>
              <Typography variant="body1">
                Votre espace de validation des connaissances
              </Typography>
            </Box>

            <AppTextField
              fullWidth
              type="email"
              label="Email"
              name="email"
              onChange={handleChange}
              value={values.email}
            />
            <AppTextField
              fullWidth
              type="password"
              label="Password"
              name="password"
              onChange={handleChange}
              value={values.password}
            />

            <Button variant="contained" type="submit">
              {t("Login.signin")}
            </Button>
          </Stack>
          {hasNoRole && (
            <Box mt={3}>
              <Typography variant="body2" textAlign="left" color={error.dark}>
                <span>
                  <InfoIcon style={{ marginBottom: "-7px" }} />{" "}
                  <span>{t("Login.has_no_access")}</span>
                </span>{" "}
                <a
                  href={mawaridSurveyUrl}
                  target="_blank"
                  rel="noreferrer"
                  style={{ textDecoration: "underline", color: error.dark }}
                >
                  {t("Login.here")}
                </a>
              </Typography>
            </Box>
          )}

          {instructorForgotPassword && (
            <Box mt={1} textAlign="right">
              <span onClick={forgotPasswordClick}>
                <Small
                  color="secondary.red"
                  sx={{ color: primary.main, cursor: "pointer" }}
                >
                  {t("General.forgot_password")}
                </Small>
              </span>
            </Box>
          )}
        </form>
      </Card>

      <Box mt={1}>
        <Link to="/participant">
          <Small>{t("Student.login.check_your_results_here")}</Small>
          <KeyboardDoubleArrowRightIcon
            sx={{ fontSize: "16px", marginBottom: "-3px" }}
          />
        </Link>
      </Box>
    </AuthenticationLayout>
  );
};

export default Login;
