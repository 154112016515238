import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import FlexBetween from "components/flexbox/FlexBetween";
import i18n from "i18next";
import { H3 } from "components/Typography";
import api from "utils/api";
import {
  Backdrop,
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  Stack,
} from "@mui/material";
import AppTextField from "components/input-fields/AppTextField";
import { useFormik } from "formik";
import * as Yup from "yup"; // styled components
import SaveIcon from "@mui/icons-material/Save";
import useMutate from "hooks/useMutate";
import { toast } from "react-hot-toast";
import AddRoundedIcon from "@mui/icons-material/AddRounded";
import RouteAuth from "components/positeams/routeAuth";

export default function AddModule() {
  return (
    <RouteAuth>
      <Content />
    </RouteAuth>
  );
}
const Content = () => {
  const { t } = useTranslation();
  let navigate = useNavigate();

  const initialValues = {
    name: "",
    description: "",
  };

  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is Required!"),
    description: Yup.string().max(255),
  });

  const { mutate } = useMutate();

  const { values, errors, handleChange, handleSubmit, touched, isSubmitting } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: async (values) => {
        // await api.addModule({
        //   module: { active: true },
        //   module_trans: [{ locale: i18n.language.toUpperCase(), name: values.name, description: values.description }],
        // });
        try {
          await mutate(() =>
            api.addModule({
              module: { active: true },
              module_trans: [
                {
                  locale: i18n.language.toUpperCase(),
                  name: values.name,
                  description: values.description,
                },
              ],
            })
          );
          toast.success(t("General.message.create.success"));
          navigate("/module/list");
        } catch (e) {
          toast.error(t("General.message.create.fail"));
        }
      },
    });
  return (
    <Box pt={2} pb={4}>
      <Grid container spacing={3}>
        <Grid item md={8}>
          <FlexBetween>
            <Stack direction="row" alignItems="center">
              <AddRoundedIcon sx={{ fontSize: "30" }} />
              <H3>{t("Module.add_module")}</H3>
            </Stack>
          </FlexBetween>
        </Grid>
        <Grid item md={8} xs={12}>
          <Card
            sx={{
              padding: 3,
            }}
          >
            <form onSubmit={handleSubmit}>
              <Grid item sm={6}></Grid>
              <Grid container spacing={3} alignItems="center">
                <Grid item>
                  <AppTextField
                    fullWidth
                    name="name"
                    label={t("Module.module_name")}
                    value={values.name}
                    onChange={handleChange}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                  />
                </Grid>
                <Grid item sm={12}>
                  <AppTextField
                    multiline
                    fullWidth
                    rows={5}
                    name="description"
                    label={t("Module.module_description")}
                    value={values.description}
                    onChange={handleChange}
                    error={Boolean(touched.description && errors.description)}
                    helperText={touched.description && errors.description}
                    sx={{
                      "& .MuiOutlinedInput-root textarea": {
                        padding: 0,
                      },
                    }}
                  />
                </Grid>

                <Grid item>
                  <Button
                    variant="outlined"
                    onClick={() => navigate("/module/list")}
                  >
                    {t("General.cancel")}
                  </Button>
                </Grid>

                <Grid item>
                  <Button
                    type="submit"
                    variant="contained"
                    startIcon={<SaveIcon />}
                  >
                    {t("Module.create_module")}
                  </Button>
                </Grid>
              </Grid>
            </form>
          </Card>
        </Grid>
      </Grid>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isSubmitting}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
};
