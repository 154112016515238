import { FormControlLabel } from "@mui/material";
import AppCheckBox from "components/AppCheckBox";
import { useFormikContext } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";

export default function Required() {
  const { t } = useTranslation();

  const { handleChange, values } = useFormikContext();

  return (
    <FormControlLabel
      control={<AppCheckBox sx={{ mr: 1 }} onChange={handleChange} />}
      label={t("Question.required")}
      name="required"
      checked={values.required}
    />
  );
}
