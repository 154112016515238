import { Box, Button, IconButton, Paper, Stack, styled } from "@mui/material";
import AppModal from "components/AppModal";
import FlexBox from "components/flexbox/FlexBox";
import AppTextField from "components/input-fields/AppTextField";
import { H3, H6 } from "components/Typography";
import { useTranslation } from "react-i18next";
import SaveIcon from "@mui/icons-material/Save";
import YouTubeIcon from "@mui/icons-material/YouTube";
import { red } from "@mui/material/colors";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import NotesIcon from "@mui/icons-material/Notes";
import FileUploadInput from "components/input-fields/FileUploadInput";
import { useFormikContext } from "formik";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import api from "utils/api";
import { useLocation } from "react-router-dom";
import uuid from "react-uuid";

const StyledAppModal = styled(AppModal)(({ theme }) => ({
  width: 400,
  [theme.breakpoints.down(400)]: {
    width: 300,
  },
}));

const AdditionalInformation = ({ open, setOpen }) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const location = useLocation();

  const { handleChange, touched, errors, setFieldValue, values } =
    useFormikContext();

  const onUploadFiles = async (event) => {
    const newFiles = event.currentTarget.files;
    const formData = new FormData();

    for (let file of newFiles)
      formData.append("document", file, `${uuid()}.${file.name}`);

    const resp = await api.uploadResponseDocument(formData, location.state.id);
    const files = resp.data;

    setFieldValue(
      "attachments.file",
      files.map((file) => ({
        id: file.id,
        name:
          file.document_url.substring(file.document_url?.indexOf(".") + 1) ||
          null,
      }))
    );
    event.target.value = "";
  };

  const onRemoveFile = async (file) => {
    await api.removeResponseDocument({ ids: [file.id] }, location.state.id);

    setFieldValue(
      "attachments.file",
      values.attachments?.file.filter((f) => f.id !== file.id)
    );
  };

  const onRemoveAllFiles = async () => {
    const formValues = {
      ids: values.attachments?.file.map((f) => f.id),
    };
    await api.removeResponseDocument(formValues, location.state.id);

    setFieldValue("attachments.file", []);
  };

  return (
    <StyledAppModal
      open={open}
      keepMounted={true}
      handleClose={(_e, reason) => {
        if (reason === "backdropClick" || reason === "escapeKeyDown") return;
      }}
    >
      <H3 sx={{ marginBottom: 2 }}>{t("Question.additional_information")}</H3>

      <Box mb={2}>
        <H6 mb={1}>
          <Stack direction="row" alignItems="center">
            <PictureAsPdfIcon color="action" sx={{ marginRight: 1 }} />
            Document
          </Stack>
        </H6>
        <FileUploadInput
          // handleOnChange={(event) => setFieldValue("attachments.file", event.currentTarget.files[0])}
          handleOnChange={onUploadFiles}
          accept=".xls,.xlsx,.pdf,.doc,.docx,.txt"
          multiple={true}
          text={t("General.choose-a-file")}
        />
        {!!values?.attachments?.file?.length && (
          <>
            <br />
            <FlexBox mb={1} sx={{ justifyContent: "end" }}>
              <Button
                variant="text"
                endIcon={<CloseIcon />}
                onClick={onRemoveAllFiles}
              >
                Clear All
              </Button>
            </FlexBox>
            <Box>
              <Stack
                direction="column"
                rowGap={1}
                sx={{
                  overflowY: "auto",
                  background: theme.palette.background.default,
                }}
                maxHeight="250px"
                p={1}
                borderRadius={1}
              >
                {Array.from(values.attachments?.file)?.map((file) => (
                  <Paper key={file.id} elevation={1}>
                    <Stack
                      direction="row"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      <H6 px={1} py={0.5}>
                        {file.name}
                      </H6>
                      <IconButton
                        variant="text"
                        onClick={() => onRemoveFile(file)}
                      >
                        <CloseIcon sx={{ fontSize: "18px" }} />
                      </IconButton>
                    </Stack>
                  </Paper>
                ))}
              </Stack>
            </Box>
          </>
        )}
      </Box>

      <Box mb={2}>
        <H6 mb={1}>
          <Stack direction="row" alignItems="center">
            <YouTubeIcon sx={{ color: red[500], marginRight: 1 }} />
            Video
          </Stack>
        </H6>
        <AppTextField
          fullWidth
          size="small"
          placeholder={t("Question.attachment_video_url")}
          name="attachments.videoURL"
          onChange={handleChange}
          value={values.attachments?.videoURL}
          error={Boolean(
            touched.attachments?.videoURL && errors.attachments?.videoURL
          )}
          helperText={
            touched.attachments?.videoURL && errors.attachments?.videoURL
          }
        />
      </Box>

      <Box mb={2}>
        <H6 mb={1}>
          <Stack direction="row" alignItems="center">
            <NotesIcon sx={{ marginRight: 1 }} />
            Texte
          </Stack>
        </H6>
        <AppTextField
          rows={4}
          fullWidth
          multiline
          size="small"
          placeholder="Description"
          name="attachments.text"
          onChange={handleChange}
          value={values.attachments?.text}
          error={Boolean(touched.attachments?.text && errors.attachments?.text)}
          helperText={touched.attachments?.text && errors.attachments?.text}
        />
      </Box>

      <FlexBox mt={4} gap={2}>
        <Button variant="outlined" fullWidth onClick={() => setOpen(false)}>
          {t("General.close")}
        </Button>
        <Button
          variant="contained"
          fullWidth
          startIcon={<SaveIcon />}
          onClick={() => setOpen(false)}
        >
          {t("General.save")}
        </Button>
      </FlexBox>
    </StyledAppModal>
  );
};

export default AdditionalInformation;
