import AppTextField from "components/input-fields/AppTextField";
import { useFormikContext } from "formik";
import useFetch from "hooks/useFetch";
import React, { Fragment, useState } from "react";
import { useTranslation } from "react-i18next";
import api from "utils/api";
import { Autocomplete, CircularProgress } from "@mui/material";
import i18next from "i18next";
import { useEffect } from "react";

export default function SelectTopic() {
  const { t } = useTranslation();
  const [hack, setHack] = useState(true);

  const { touched, errors, values, setFieldValue } = useFormikContext();

  const { data: topics, isLoading } = useFetch(
    React.useCallback(
      () => api.getTopicList({ modules: [values?.moduleId] }),
      [values?.moduleId]
    )
  );

  useEffect(() => {
    if (values.topic === "") setHack((current) => !current);
  }, [values.topic]);

  return (
    <Autocomplete
      key={hack}
      name="topic"
      onChange={(_e, value, reason) => {
        if (reason === "clear") setFieldValue("topic", "");
        else setFieldValue("topic", value?.id);
      }}
      size="small"
      sx={{ width: 250 }}
      clearOnBlur={false}
      options={topics?.rows || []}
      loading={isLoading}
      getOptionLabel={(option) =>
        option?.topic_trans?.find(
          (m) => m.locale === i18next.language.toUpperCase()
        )?.name || ""
      }
      renderInput={(params) => (
        <AppTextField
          {...params}
          label={t("Topic.topic")}
          InputProps={{
            ...params.InputProps,
            style: { paddingRight: 40 },
            endAdornment: (
              <Fragment>
                {isLoading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </Fragment>
            ),
          }}
          error={Boolean(touched.topic && errors.topic)}
          helperText={touched.topic && errors.topic}
        />
      )}
    />
  );
}
