import { Box } from "@mui/material";
import { forwardRef } from "react";

const FlexBox = forwardRef(({ children, ...props }, ref) => (
  <Box ref={ref} display="flex" {...props}>
    {children}
  </Box>
));

export default FlexBox;
