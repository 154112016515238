import useLocalStorage from "hooks/useLocalStorage";
import { createContext, useEffect, useState } from "react";
import { THEMES } from "../constants";
import api from "utils/api";
const initialSettings = {
  activeLayout: "layout3",
  direction: "ltr",
  theme: THEMES.LIGHT,
  responsiveFontSizes: true,
};
export const SettingsContext = createContext({
  settings: initialSettings,
  saveSettings: (arg) => {},
  themeColor: "#39c1d5",
  logoUrl: "",
  refreshCompanySettings: () => {},
}); // component props type

const SettingsProvider = ({ children }) => {
  const { data: settings, storeData: setStoreSettings } = useLocalStorage(
    "settings",
    initialSettings
  );

  const saveSettings = (updateSettings) => {
    setStoreSettings(updateSettings);
  };

  const [themeColor, setThemeColor] = useState("");
  const [logoUrl, setLogoUrl] = useState("");

  const getCompanySettings = async () => {
    try {
      const response = await api.getSkillupSettings();
      setThemeColor(response.data?.theme_color || "");
      setLogoUrl(response.data?.logo_url || "");
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getCompanySettings();
  }, []);

  const refreshCompanySettings = async () => {
    await getCompanySettings();
  };

  return (
    <SettingsContext.Provider
      value={{
        settings,
        saveSettings,
        themeColor,
        logoUrl,
        refreshCompanySettings,
        setThemeColor,
      }}
    >
      {children}
    </SettingsContext.Provider>
  );
};

export default SettingsProvider;
