import React from "react";
import * as Yup from "yup";
import { Button, Stack } from "@mui/material";
import AppTextField from "components/input-fields/AppTextField";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import useAuth from "hooks/useAuth";
import { Box } from "@mui/system";
import { Small } from "components/Typography";
import { useLocation, useNavigate } from "react-router-dom";
import { primary } from "theme/colors";

const validationSchema = Yup.object().shape({
  password: Yup.string().required("password is required!"),
});

function LoginWithPassword({ user }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const { studentLogin } = useAuth();

  const handleSubmit = async (values) => {
    await studentLogin({
      login: user.email || user.employee_id,
      password: values.password,
    });
  };

  const forgotPasswordClick = () => {
    const redirectUrl = encodeURIComponent(location.pathname + location.search);

    navigate(`/forget-password?redirectUrl=${redirectUrl}`);
  };

  return (
    <Formik
      initialValues={{ password: "" }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, errors, handleChange, touched }) => (
        <Form>
          <Stack gap={2}>
            <AppTextField
              fullWidth
              type="password"
              label={t("Student.login.password")}
              name="password"
              onChange={handleChange}
              value={values.password}
              error={Boolean(touched.password && errors.password)}
              helperText={touched.password && errors.password}
            />

            <Button variant="contained" type="submit">
              {t("Login.signin")}
            </Button>
          </Stack>
          {user?.email && (
            <Box mt={1} textAlign="right">
              <span onClick={forgotPasswordClick}>
                <Small
                  color="secondary.red"
                  sx={{ color: primary.main, cursor: "pointer" }}
                >
                  {t("General.forgot_password")}
                </Small>
              </span>
            </Box>
          )}
        </Form>
      )}
    </Formik>
  );
}

export default LoginWithPassword;
