import * as React from "react";
import { TimePicker } from "@mui/x-date-pickers/TimePicker";
import AppTextField from "components/input-fields/AppTextField";
import { useTranslation } from "react-i18next";
import { useFormikContext } from "formik";

export default function Timing() {
  const { t } = useTranslation();
  const [hack, setHack] = React.useState(true);

  const { values, setFieldValue } = useFormikContext();

  React.useEffect(() => {
    if (values.duration === null) setHack((current) => !current);
  }, [values.duration]);

  return (
    <TimePicker
      key={hack}
      views={["minutes", "seconds"]}
      inputFormat="mm:ss"
      mask="__:__"
      ampm={false}
      label={t("Question.time")}
      value={null}
      disableOpenPicker
      onChange={(_, k) => {
        if (!!k) {
          const fields = k?.split(":");
          const duration = Number(fields[0]) * 60 + Number(fields[1]);
          setFieldValue("duration", duration);
        } else setFieldValue("duration", null);
      }}
      renderInput={(params) => (
        <AppTextField {...params} sx={{ width: "100px" }} />
      )}
    />
  );
}
