import { useTranslation } from "react-i18next";
import FlexBetween from "components/flexbox/FlexBetween";
import { H3 } from "components/Typography";
import SaveIcon from "@mui/icons-material/Save";
import {
  Backdrop,
  Box,
  Button,
  Card,
  CircularProgress,
  Grid,
  Stack,
} from "@mui/material";
import { Formik } from "formik";
import { useCallback, useEffect, useState } from "react";
import Edit from "icons/Edit";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import toast from "react-hot-toast";
import i18next from "i18next";
import useMutate from "hooks/useMutate";
import api from "utils/api";
import { useLocation, useNavigate } from "react-router-dom";
import { createQuestionSchema } from "schemas/question";
import * as Fields from "page-sections/question/edit";
import useFetch from "hooks/useFetch";
import FlexBox from "components/flexbox/FlexBox";
import DeleteIcon from "@mui/icons-material/Delete";
import DoneBy from "components/DoneBy";
import Illustration from "components/illustration";
import RouteAuth from "components/positeams/routeAuth";

export default function AddQuestion() {
  return (
    <RouteAuth>
      <Content />
    </RouteAuth>
  );
}

const Content = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const { mutate } = useMutate();

  const {
    data: question,
    isLoading,
    isError,
  } = useFetch(
    useCallback(() => api.getQuestion(location.state.id), [location.state.id])
  );

  const initialValues = {
    module: question?.topic?.moduleId,
    topic: question?.topicId,
    type: question?.typeId,
    quizTypeId: question?.quizTypeId,
    difficulty: question?.difficulty,
    duration: question?.duration,
    required: question?.required,
    active: question?.active,
    description:
      question?.question_trans?.find(
        (m) => m.locale === i18next.language.toUpperCase()
      )?.description || "",
    answers: question?.question_items?.map((item) => ({
      id: item.id,
      description:
        item?.question_items_trans?.find(
          (m) => m.locale === i18next.language.toUpperCase()
        )?.description || "",
      checked: item.is_answer,
      active: !!item.active,
    })),
    attachments: {
      text: question?.response_extra_infos?.find((info) => !!info.text)?.text,
      videoURL: question?.response_extra_infos?.find((info) => !!info.video_url)
        ?.video_url,
      file: question?.response_extra_infos
        ?.filter((info) => !!info.document_url)
        .map((info) => ({
          id: info.id,
          name:
            info.document_url?.substring(info.document_url?.indexOf(".") + 1) ||
            null,
        })),
      // {
      //   name:
      //     question?.response_extra_info?.document_url?.substring(
      //       question?.response_extra_info?.document_url?.indexOf(".") + 1
      //     ) || null,
      // }
    },
  };

  const handleSubmit = async (data, resetForm) => {
    // Submitting questions
    try {
      let {
        topic: topicId,
        // difficulty: difficultyId,
        difficulty,
        duration,
        required,
        description,
        answers,
        attachments,
        active,
        quizTypeId,
      } = data;

      const updateQuestion = async () => {
        let question = {
          topicId,
          difficulty,
          duration,
          required,
          active,
          quizTypeId,
        };
        let question_trans = {
          locale: i18next.language.toUpperCase(),
          description,
        };
        let question_object = { question, question_trans };

        await mutate(() =>
          api.updateQuestion({ id: location.state.id, data: question_object })
        ); // update question
      };
      await updateQuestion();

      // if (
      //   attachments.file?.at(0) instanceof File ||
      //   attachments.file?.length === 0
      // ) {
      //   const formData = new FormData();
      //   for (let file of attachments.file)
      //     formData.append("document", file, `${uuid()}.${file.name}`);
      //   await api.uploadResponseDocument(formData, location.state.id);
      // }

      if (
        attachments.text !==
        question?.response_extra_infos?.find((info) => !!info.text)?.text
      )
        await api.updateResponseExtraInfo({
          questionId: location.state.id,
          text: attachments.text || null,
          id:
            question?.response_extra_infos?.find((info) => !!info.text)?.id ||
            null,
        });

      if (
        attachments.videoURL !==
        question?.response_extra_infos?.find((info) => !!info.video_url)
          ?.video_url
      )
        await api.updateResponseExtraInfo({
          questionId: location.state.id,
          video_url: attachments.videoURL || null,
          id:
            question?.response_extra_infos?.find((info) => !!info.video_url)
              ?.id || null,
        });

      // Delete previous items
      for (let item of question?.question_items)
        await api.deleteQuestionItem(item.id);

      // Submitting question items if exist
      for (let answer of answers) {
        let question_item_object = {
          question_item: {
            is_answer: answer.checked,
            questionId: location.state.id,
            active: answer.active,
          },
          question_item_trans: [
            {
              locale: i18next.language.toUpperCase(),
              description: answer.description,
            },
          ],
        };
        await api.addQuestionItem(question_item_object);
      }
      toast.success(t("Question.update_success"));
      // refetch();
      navigate("/question/list");
    } catch (e) {
      console.log(e);
    }
    // resetForm();
  };

  // What to show while loading
  if (isLoading) return <CircularProgress />;
  // if there is an error
  else if (isError)
    return <Illustration illustration="error" text="General.error" />;

  return (
    <Box pt={2} pb={4}>
      <Formik
        initialValues={initialValues}
        validationSchema={createQuestionSchema}
        validateOnChange={false}
        validateOnBlur={false}
        onSubmit={async (values, { resetForm }) => {
          await handleSubmit(values, resetForm);
        }}
        // enableReinitialize={true}
      >
        {(props) => <Form {...props} />}
      </Formik>
    </Box>
  );
};

const Form = (props) => {
  const { t } = useTranslation();
  const { handleSubmit, values, errors, isSubmitting } = props;
  const [isOpenAdditionalInfo, setIsOpenAdditionalInfo] = useState(false);
  const [isOpenDeleteQuestion, setIsOpenDeleteQuestion] = useState(false);

  useEffect(() => {
    if (!isSubmitting && !!errors.answers) toast.error(errors.answers);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSubmitting]);

  const location = useLocation();

  const { data: question } = useFetch(
    useCallback(() => api.getQuestion(location.state.id), [])
  );

  return (
    <Grid container spacing={3}>
      <Fields.AdditionalInformation
        open={isOpenAdditionalInfo}
        setOpen={setIsOpenAdditionalInfo}
      />
      <Fields.Delete
        open={isOpenDeleteQuestion}
        toggle={() => setIsOpenDeleteQuestion((current) => !current)}
        id={location.state.id}
      />
      <Grid item md={9} xs={12}>
        <FlexBetween>
          <Stack>
            <Stack direction="row" alignItems="center">
              <Edit sx={{ fontSize: "30" }} />
              <H3>{t("Question.edit_question")}</H3>
            </Stack>
            <DoneBy
              person={question?.created_by}
              date={question?.createdAt}
              type="creation"
            />
            <DoneBy
              person={question?.updated_by}
              date={question?.updatedAt}
              type="modification"
            />
          </Stack>

          <FlexBox justifyContent="end">
            {/* <Button onClick={(e) => setDeleteWarning(true)} color="secondary" startIcon={<DeleteIcon/>}>{t('General.delete')}</Button> */}
            <Button
              onClick={() => setIsOpenDeleteQuestion((current) => !current)}
              color="secondary"
              startIcon={<DeleteIcon />}
            >
              {t("General.delete")}
            </Button>
          </FlexBox>
        </FlexBetween>
      </Grid>
      <Grid item md={9} xs={12}>
        <Card
          sx={{
            padding: 3,
            marginBottom: 2,
          }}
        >
          <form id="myform" onSubmit={handleSubmit}>
            <Grid container spacing={3} justify="center" alignItems="center">
              <Grid item>
                <Fields.Module />
              </Grid>
              <Grid item>
                <Fields.Topic />
              </Grid>
              <Grid item>
                {/* <SelectType /> */}
                <Fields.Type />
              </Grid>
              <Grid item>
                {/* <SelectDifficulty /> */}
                <Fields.Difficulty />
              </Grid>
              <Grid item>
                {/* <Timing /> */}
                <Fields.Timing />
              </Grid>
              <Grid item>
                {/* <QuizType /> */}
                <Fields.QuizType />
              </Grid>
              <Grid item>
                <Fields.Required />
              </Grid>
              <Grid item>
                <Fields.Active />
              </Grid>
              <Grid item sm={12}>
                <Fields.Description />
              </Grid>
              {/* Buttons */}
              <Grid
                container
                item
                sm={12}
                justify="flex-end"
                justifyContent="flex-end"
              >
                <Button
                  onClick={() => setIsOpenAdditionalInfo((current) => !current)}
                  variant="outlined"
                  startIcon={<AttachFileIcon />}
                  sx={{ marginRight: "10px" }}
                >
                  {t("Question.additional_information")}
                </Button>
                {/* <Button startIcon={<SaveIcon />} type="submit" variant="contained">
                  {t("Question.save")}
                </Button> */}

                <Button
                  type="submit"
                  startIcon={<SaveIcon />}
                  variant="contained"
                >
                  {t("Question.save")}
                </Button>
              </Grid>
            </Grid>
          </form>
        </Card>

        {/* {values.type === 1 && <MultiChoice />}
        {values.type === 2 && <SingleChoice />} */}
        {values.type === 1 && <Fields.MultiChoice />}
        {values.type === 2 && <Fields.SingleChoice />}
      </Grid>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isSubmitting}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Grid>
  );
};
