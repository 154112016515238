import LayoutBodyWrapper from "layouts/layout-parts/LayoutBodyWrapper";
import { Fragment } from "react";
import { Outlet } from "react-router";
import ProfilePopover from "./layout-parts/popovers/ProfilePopover";
import { Stack, Grid } from "@mui/material";
import { getCompanyLogo } from "utils/utils";
import useAuth from "hooks/useAuth";

const LayoutStudent = ({ children }) => {
  const user = useAuth();

  return (
    <Fragment>
      <LayoutBodyWrapper>
        <Stack direction="row" sx={{ backgroundColor: "#fff", padding: 2 }}>
          <Grid
            container
            alignItems="left"
            justifyContent="left"
            justifyItems="left"
            alignContent="center"
          >
            <img
              alt="company logo"
              width="200"
              src={getCompanyLogo(
                user?.user?.companyId,
                user?.user?.companyName
              )}
            ></img>
          </Grid>

          <Grid
            item
            container
            alignItems="right"
            justifyContent="right"
            justifyItems="right"
            alignContent="center"
          >
            <Grid item>
              <ProfilePopover />
            </Grid>
          </Grid>
        </Stack>
        {children || <Outlet />}
      </LayoutBodyWrapper>
    </Fragment>
  );
};

export default LayoutStudent;
