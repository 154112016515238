import { Save, Topic } from "@mui/icons-material";
import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  Stack,
} from "@mui/material";
import AppModal from "components/AppModal";
import { H3, H6 } from "components/Typography";
import FlexBetween from "components/flexbox/FlexBetween";
import FlexBox from "components/flexbox/FlexBox";
import { lightTheme } from "../../../constants";
import useFetch from "hooks/useFetch";
import { useCallback, useState } from "react";
import { useTranslation } from "react-i18next";
import api from "utils/api";
import toast from "react-hot-toast";
import useMutate from "hooks/useMutate";
import Illustration from "components/illustration";

export default function AddTopicsDialog({ open, setOpen, moduleId, refetch }) {
  const { t } = useTranslation();
  const { mutate } = useMutate();

  const [selectedTopics, setSelectedTopics] = useState([]);

  const {
    data: topicList,
    isLoading,
    isError,
  } = useFetch(
    useCallback(
      () => api.getTopicList({ moduleIsNull: true }),
      [moduleId, open]
    )
  );

  const oncancel = () => {
    setSelectedTopics([]);
    setOpen(false);
  };

  const handleSave = async () => {
    try {
      await mutate(() => api.addTopics({ topicIds: selectedTopics, moduleId }));
      toast.success(t("Topic.topics_added_success"));
      setSelectedTopics([]);
      setOpen(false);
      refetch();
    } catch (err) {
      toast.error(t("General.message.create.fail"));
    }
  };

  return (
    <AppModal
      open={open}
      handleClose={() => setOpen(false)}
      sx={{ maxWidth: 500 }}
    >
      {isLoading ? (
        <CircularProgress />
      ) : // if there is an error
      isError ? (
        <Illustration illustration="error" text="General.error" />
      ) : // if request is successful but there is no data
      !!topicList && topicList.count === 0 ? (
        <Illustration illustration="noResult" text="General.no_data" />
      ) : (
        <>
          <Stack direction="row" spacing={15} sx={{ marginBottom: 2 }}>
            <H3>{t("Topic.add_topics")}</H3>
          </Stack>
          <Grid container spacing={2} alignItems="center" mb={2}>
            <Grid item xs={12} sm={12}>
              <Box>
                {topicList?.rows?.map((item) => (
                  <FlexBetween
                    key={item.id}
                    sx={{
                      borderBottom: 1,
                      padding: "1rem 0rem",
                      borderColor: (theme) =>
                        lightTheme(theme) ? "grey.200" : "divider",
                      "&:last-of-type": {
                        borderBottom: 0,
                      },
                    }}
                  >
                    <FlexBox alignItems="center">
                      <Topic style={{ fontSize: "20" }}></Topic>

                      <Box ml={1}>
                        <H6>{item.topic_trans[0]?.name}</H6>
                      </Box>
                    </FlexBox>

                    <Checkbox
                      checked={
                        item.id === selectedTopics.find((i) => i === item.id)
                      }
                      onChange={(e) => {
                        if (e.target.checked) {
                          setSelectedTopics([...selectedTopics, item.id]);
                        } else {
                          setSelectedTopics(
                            selectedTopics.filter((i) => i !== item.id)
                          );
                        }
                      }}
                      value={item.id}
                    ></Checkbox>
                  </FlexBetween>
                ))}
              </Box>
            </Grid>
          </Grid>

          <FlexBox mt={4} gap={2} sx={{ justifyContent: "end" }}>
            <Button variant="outlined" onClick={oncancel}>
              {t("General.cancel")}
            </Button>
            <Button
              disabled={selectedTopics.length === 0}
              variant="contained"
              startIcon={<Save />}
              onClick={() => handleSave()}
            >
              {t("Add")}
            </Button>
          </FlexBox>
        </>
      )}
    </AppModal>
  );
}
