import React from "react";
import { Small } from "./Typography";
import Moment from "react-moment";
import { useTranslation } from "react-i18next";

export default function DoneBy({ person, date, type }) {
  const { t } = useTranslation();

  return (
    <Small color="text.secondary" fontWeight={400} fontSize={12}>
      <span>{`${t(`General.${type}`)}`} : </span>
      <span style={{ textTransform: "capitalize" }}>{`${person?.firstName} ${person?.lastName}`}, </span>
      <span>
        <Moment format="DD/MM/YYYY">{date}</Moment>
      </span>
    </Small>
  );
}
