import React from "react";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useTranslation } from "react-i18next";
import { toast } from "react-hot-toast";
import { Box, Button, Stack } from "@mui/material";
import api from "utils/api";
import AppTextField from "components/input-fields/AppTextField";
import { useLocation, useNavigate } from "react-router-dom";
import { Small } from "components/Typography";
import { primary } from "theme/colors";

const validationSchema = Yup.object().shape({
  login: Yup.string().required("Employee id or Email is required!"),
});

function FirstStep({ setUser, setLoginIsValide }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();

  const handleSubmit = async (values) => {
    const res = await api.studentCheckAccount(values);

    if (res.error) {
      toast.error(t("Login.invalid_login"));
      return;
    }
    const user = res.data;
    setUser(user);
    setLoginIsValide(true);
  };

  const forgotPasswordClick = () => {
    const redirectUrl = encodeURIComponent(location.pathname + location.search);

    navigate(`/forget-password?redirectUrl=${redirectUrl}`);
  };

  return (
    <Formik
      initialValues={{ login: "" }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, errors, handleChange, touched }) => (
        <Form>
          <Stack gap={2}>
            <AppTextField
              fullWidth
              label={t("Student.login.employee_id_or_email")}
              name="login"
              onChange={handleChange}
              value={values.login}
              error={Boolean(touched.login && errors.login)}
              helperText={touched.login && errors.login}
            />

            <Button variant="contained" type="submit">
              {t("General.next")}
            </Button>
          </Stack>

          <Box mt={1} textAlign="right">
            <span onClick={forgotPasswordClick}>
              <Small
                color="secondary.red"
                sx={{ color: primary.main, cursor: "pointer" }}
              >
                {t("General.forgot_password")}
              </Small>
            </span>
          </Box>
        </Form>
      )}
    </Formik>
  );
}

export default FirstStep;
