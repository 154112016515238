import { IconButton } from "@mui/material";
import FlexRowAlign from "components/flexbox/FlexRowAlign";
import { Small } from "components/Typography";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import React from "react";

const FileUploadInput = ({
  handleOnChange,
  accept = "image/*",
  Icon = AttachFileIcon,
  text = "Choose a file",
  multiple = false,
  onClick = () => {},
}) => {
  return (
    <label htmlFor="icon-button-file">
      <input
        type="file"
        accept={accept}
        id="icon-button-file"
        style={{
          display: "none",
        }}
        onChange={handleOnChange}
        multiple={multiple}
        onClick={onClick}
      />

      <IconButton
        disableRipple
        component="span"
        sx={{
          padding: 0,
          display: "block",
        }}
      >
        <FlexRowAlign
          gap={0.5}
          sx={{
            minHeight: 40,
            borderRadius: "8px",
            backgroundColor: "divider",
          }}
        >
          <Icon
            sx={{
              fontSize: 18,
              color: "text.disabled",
            }}
          />
          <Small color="text.disabled">{text}</Small>
        </FlexRowAlign>
      </IconButton>
    </label>
  );
};

export default FileUploadInput;
