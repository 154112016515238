import AppTextField from "components/input-fields/AppTextField";
import { useFormikContext } from "formik";
import React from "react";
import { useTranslation } from "react-i18next";

export default function Description() {
  const { t } = useTranslation();

  const { handleChange, touched, errors, values } = useFormikContext();

  return (
    <AppTextField
      multiline
      fullWidth
      rows={5}
      name="description"
      label={t("Question.description")}
      value={values.description}
      onChange={handleChange}
      error={Boolean(touched.description && errors.description)}
      helperText={touched.description && errors.description}
      sx={{
        "& .MuiOutlinedInput-root textarea": {
          padding: 0,
        },
      }}
    />
  );
}
