import LoadingScreen from "components/LoadingScreen";
import jwtDecode from "jwt-decode";
import { createContext, useEffect, useReducer } from "react";
import { toast } from "react-hot-toast";
import api from "utils/api";
import axios from "../utils/axios";
import { useTranslation } from "react-i18next";
import {
  EXTERNAL_INSTRUCTOR_INVALID_PASSWORD,
  HAS_NO_ROLE,
  MFA_REQUIRED,
  ROLE_SELECTION_REQUIRED,
} from "../constants";
import UserAvatar from "assets/avatar/user.png";

import User1Avatar from "assets/avatar/user1.png";
import User2Avatar from "assets/avatar/user2.png";
import User3Avatar from "assets/avatar/user3.png";
import User4Avatar from "assets/avatar/user4.png";
import User5Avatar from "assets/avatar/user5.png";
import User6Avatar from "assets/avatar/user6.png";
import {
  getLocalStorageItem,
  removeLocalStorageItem,
  setLocalStorageItem,
} from "utils/utils";

const userAvatars = [
  User1Avatar,
  User2Avatar,
  User3Avatar,
  User4Avatar,
  User5Avatar,
  User6Avatar,
];

const initialState = {
  isAuthenticated: false,
  isInitialized: false,
  user: null,
  role: null,
};

const isValidToken = (token) => {
  if (!token) return false;
  const decodedToken = jwtDecode(token);
  const currentTime = Date.now() / 1000;
  return decodedToken.exp > currentTime;
};

const setSession = (token) => {
  if (token) {
    setLocalStorageItem("token", token);
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
  } else {
    // removeLocalStorageItem('token');
    delete axios.defaults.headers.common.Authorization;
  }
};

const reducer = (state, action) => {
  switch (action.type) {
    case "INIT": {
      return {
        isInitialized: true,
        isAuthenticated: action.payload.isAuthenticated,
        user: action.payload.user,
        role: action.payload.role,
        roles: action.payload.roles,
      };
    }

    case "LOGIN": {
      setLocalStorageItem("user", action.payload.user);
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
      };
    }

    case "SET_ROLE": {
      setLocalStorageItem("role", action.payload);
      return { ...state, role: action.payload };
    }

    case "SET_ROLES": {
      setLocalStorageItem("roles", action.payload);
      return { ...state, roles: action.payload };
    }

    case "STUDENT_LOGIN": {
      setLocalStorageItem("user", action.payload.user);
      return { ...state, isAuthenticated: true, user: action.payload.user };
    }

    case "LOGOUT": {
      return { ...state, isAuthenticated: false };
    }

    case "REGISTER": {
      return { ...state, isAuthenticated: true };
    }

    default: {
      return state;
    }
  }
};

const AuthContext = createContext({
  ...initialState,
  method: "JWT",
  logout: () => {},
  login: (email, password, profile, internal) => Promise.resolve(),
  studenLogin: (employeeId, dob) => Promise.resolve(),
  login_mfa: (email, code, profile, internal) => Promise.resolve(),
  register: (email, password, username) => Promise.resolve(),
});
export const JWTAuthProvider = ({ children }) => {
  const { t, i18n } = useTranslation();
  const [state, dispatch] = useReducer(reducer, initialState);

  const setUserData = (data) => {
    dispatch({
      type: "LOGIN",
      payload: {
        user: {
          companyCode: data.companyCode,
          companyId: data.companyId,
          companyName: data.companyName,
          name: data.name,
          roles: data.roles,
          id: data.id,
          locale: data.locale,
          rootGroupId: data.rootGroup,
          uploadFolder: data.uploadFolder,
        },
      },
    });
  };

  const setCurrentRole = (data) => {
    dispatch({
      type: "SET_ROLE",
      payload: {
        id: data.roles[0]?.role?.id,
        name: data.roles[0]?.role?.roles_trans?.find(
          (t) => t.locale?.toLowerCase() === data.locale?.toLowerCase()
        )?.name,
        avatar: UserAvatar,
        permissions: data.roles[0]?.role?.role_permissions,
      },
    });
  };

  const setRoles = (data) => {
    let translatedRoles = [];
    let translatedRole;
    data.roles.forEach((role, index) => {
      translatedRole = {};
      translatedRole.id = role?.role?.id;
      translatedRole.name = role?.role?.roles_trans?.find(
        (e) => e.locale.toLowerCase() == data.locale.toLowerCase()
      )?.name;
      translatedRole.avatar = userAvatars[index + 1];
      translatedRole.permissions = role?.role?.role_permissions;

      translatedRoles.push(translatedRole);
    });
    dispatch({
      type: "SET_ROLES",
      payload: translatedRoles,
    });
  };

  const login = async (email, password, profile, internal) => {
    try {
      const result = await api.login({
        email,
        password,
        profile: profile,
        internal: internal,
      });

      if (result.error) {
        toast.error(t("Login.invalid_login"));

        if (result.response?.data?.externalInstructor) {
          return EXTERNAL_INSTRUCTOR_INVALID_PASSWORD;
        }
        return;
      }

      // this is used only for Attijari when the user has no role
      // check if the user has access to the others platforms and we generate mawardi survey URL.
      if (result.data.hasNoRole) {
        if (result.data.companyName.toLowerCase().includes("attijari")) {
          return HAS_NO_ROLE;
        } else {
          toast.error(t("Login.invalid_login"));
          return;
        }
      }

      if (result.data.auth && !result.data.token) {
        //if mfa_required, redirect to mfa page from login page
        return MFA_REQUIRED;
      } else if (result?.data?.roles?.length > 1) {
        //if multiple roles, let them choose
        setRoles(result.data);
        //also set a default role, so we don't get 401 errors
        //right after we login
        setCurrentRole(result.data);
        setSession(result.data.token);
        setUserData(result.data);
        return ROLE_SELECTION_REQUIRED;
      }

      setSession(result.data.token);
      setRoles(result.data);
      //use has only 1 role, set it
      setCurrentRole(result.data);
      setUserData(result.data);
    } catch (e) {
      console.log(e);
    }
  };

  const login_mfa = async (email, code, profile, internal) => {
    try {
      const result = await api.loginMfa({ email, code, profile, internal });
      if (result.error) {
        toast.error("Error:" + result.error);
        return;
      }
      //mfa success...
      if (result.data.auth && result.data.token) {
        setSession(result.data.token);
        setRoles(result.data);
        if (result?.data?.roles?.length > 1) {
          //if multiple roles, let them choose
          setUserData(result.data);
          return ROLE_SELECTION_REQUIRED;
        } else {
          //use has only 1 role, set it
          setCurrentRole(result.data);
          setUserData(result.data);
        }
      } else {
        // mfa failure...
        toast.error(t("Login.invalid_code"));
        return;
      }
    } catch (e) {
      toast.error("Error:" + e);
      console.log(e);
    }
  };

  const studentLogin = async (employeeId, dob) => {
    try {
      const result = await api.studentLogin(employeeId, dob);
      if (result.error) {
        toast.error(t("Login.invalid_login"));
        return;
      }

      setSession(result.data.token);
      i18n.changeLanguage(result.data.locale || "fr");

      dispatch({
        type: "STUDENT_LOGIN",
        payload: {
          user: {
            companyCode: result?.data?.companyCode,
            companyId: result?.data?.companyId,
            companyName: result?.data?.companyName,
            name: result.data.name,
            id: result?.data?.id,
            employeeId: employeeId,
            uploadFolder: result?.data?.uploadFolder,
          },
        },
      });
    } catch (e) {
      console.log(e);
    }
  };

  const setRole = (role) => {
    dispatch({
      type: "SET_ROLE",
      payload: role,
    });
  };

  const register = async (email, username, password) => {
    const { data } = await axios.post("/api/auth/register", {
      email,
      username,
      password,
    });
    setSession(data.token);
    dispatch({
      type: "REGISTER",
    });
  };

  const logout = () => {
    setSession(null);
    removeLocalStorageItem("token");
    removeLocalStorageItem("user");
    removeLocalStorageItem("role");
    removeLocalStorageItem("roles");
    dispatch({
      type: "LOGOUT",
    });
  };

  useEffect(() => {
    (async () => {
      try {
        const token = getLocalStorageItem("token");
        if (token && isValidToken(token)) {
          const usr = getLocalStorageItem("user");
          let storedUser = null;
          if (usr) storedUser = usr;

          const role = getLocalStorageItem("role");
          let storedRole = null;
          if (role) storedRole = role;

          const roles = getLocalStorageItem("roles");
          let storedRoles = null;
          if (roles) storedRoles = roles;

          setSession(token);
          dispatch({
            type: "INIT",
            payload: {
              isAuthenticated: true,
              user: storedUser,
              role: storedRole,
              roles: storedRoles,
            },
          });
        } else {
          removeLocalStorageItem("token");
          removeLocalStorageItem("user");
          dispatch({
            type: "INIT",
            payload: {
              isAuthenticated: false,
            },
          });
        }
      } catch (err) {
        console.error(err);
        removeLocalStorageItem("token");
        removeLocalStorageItem("user");
        dispatch({
          type: "INIT",
          payload: {
            isAuthenticated: false,
          },
        });
      }
    })();
  }, []); // show loading until not initialized

  if (!state.isInitialized) <LoadingScreen />;
  return (
    <AuthContext.Provider
      value={{
        ...state,
        method: "JWT",
        login,
        studentLogin,
        register,
        logout,
        login_mfa,
        setRole,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
export default AuthContext;
