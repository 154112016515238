import React from "react";
import LoginWithPassword from "./loginWithPassword";
import LoginWithDateOfBirth from "./loginWithDateOfBirth";

function SecondStep({ user }) {
  return (
    <>
      {user.password ? (
        <LoginWithPassword user={user} />
      ) : (
        <LoginWithDateOfBirth user={user} />
      )}
    </>
  );
}

export default SecondStep;
