import React, { useState, useEffect } from "react";
import { Card, Grid } from "@mui/material";
import { H4 } from "components/Typography";
import { useTranslation } from "react-i18next";
import AppTextField from "components/input-fields/AppTextField";
import { useFormik } from "formik";
import { KeyboardArrowDown } from "@mui/icons-material";
import { MenuItem } from "@mui/material";
import * as Yup from "yup"; // component props interface
import api from "utils/api";
import toast from "react-hot-toast";
import ExportExcel from "./exportExcel";

const ReportPerGroup = () => {
  const { t } = useTranslation();
  const [data, setData] = useState();
  const [group, setGroup] = useState();
  const [coverData, setCoverData] = useState();

  const initialValues = {
    group: "",
  };

  useEffect(() => {
    const fetchData = async () => {
      let result = await api.getTrainingGroupList();
      if (result.success) {
        setGroup(result.data.rows);
      } else {
        toast.error("server error");
        return;
      }
    };
    fetchData();
  }, []);

  const validationSchema = Yup.object({
    group: Yup.string().required("Group is Required!"),
  });

  const { handleSubmit, touched, errors, values, setFieldValue } = useFormik({
    initialValues,
    validationSchema: validationSchema,
  });

  const handleChange = (e) => {
    setFieldValue(e.target.name, e.target.value);
    selectGroup(e.target.value);
  };

  const selectGroup = async (group) => {
    const result = await api.getReportByTrainingGroup(group);
    if (result.status == 404) {
      toast.error(t("Reports.errors.quiz_not_processed"));
      return;
    }

    //discard modules with negative avg_scores
    //i.e contains questions not yet corrected.
    const report = result.data.report.filter((r) => {
      return r.avg_score_per_module > 0;
    });

    let resultsFormatted = report?.map((r, index) => ({
      Module: r.modulename,
      Date: r.quizstartdate,
      Score: Math.round(r.avg_score_per_module) + "%",
      Participation:
        Math.round(
          (r.participants / result?.data?.groupParticipants.employees) * 100
        ) + "%",
    }));
    setData(resultsFormatted);
    setXLCoverData(
      result?.data?.groupParticipants?.curriculum?.curriculum_trans[0]?.name,
      result?.data?.groupParticipants.name
    );
  };

  const setXLCoverData = (curriculumName, trainingGroupName) => {
    setCoverData([
      {
        Cursus: curriculumName,
        Groupe: trainingGroupName,
      },
    ]);
  };

  return (
    <Grid item md={10} xs={12}>
      <Card sx={{ padding: 3 }}>
        <H4 pb={2}>{t("Reports.per_group_title")}</H4>
        <form onSubmit={handleSubmit}>
          <Grid
            container
            spacing={1}
            alignContent="center"
            alignItems={"center"}
          >
            <Grid item>
              <AppTextField
                fullWidth
                select
                onChange={handleChange}
                name="group"
                disabled={false}
                sx={{ width: 300 }}
                label={t("Reports.fields.group")}
                SelectProps={{
                  IconComponent: KeyboardArrowDown,
                  value: values.group,
                }}
                error={Boolean(touched.group && errors.group)}
                helperText={touched.group && errors.group}
              >
                <div></div>
                {group?.map((item) => (
                  <MenuItem key={item.id} value={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </AppTextField>
            </Grid>
            <Grid item>
              <ExportExcel
                excelData={data}
                coverData={coverData}
                fileName={t("Reports.per_group_title")}
                disabled={values.group ? false : true}
              />
            </Grid>
          </Grid>
        </form>
      </Card>
    </Grid>
  );
};

export default ReportPerGroup;
