import {
  Tooltip,
  Grid,
  Badge,
  Box,
  ButtonBase,
  Divider,
  styled,
  useMediaQuery,
} from "@mui/material";
import AppAvatar from "components/avatars/AppAvatar";
import FlexBox from "components/flexbox/FlexBox";
import { H6, Small, Tiny } from "components/Typography";
import useAuth from "hooks/useAuth";
import { Fragment, useRef, useState } from "react";
import toast from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import PopoverLayout from "./PopoverLayout"; // styled components
import { useTranslation } from "react-i18next";
import LogoutIcon from "@mui/icons-material/Logout";
import { INSTRUCTOR_ROLE_ID } from "../../../constants";

const StyledButtonBase = styled(ButtonBase)(({ theme }) => ({
  padding: 5,
  marginLeft: 4,
  borderRadius: 30,
  border: `1px solid ${theme.palette.divider}`,
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));
const StyledSmall = styled(Small)(({ theme }) => ({
  display: "block",
  cursor: "pointer",
  padding: "5px 1rem",
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const ProfilePopover = () => {
  const anchorRef = useRef(null);
  const navigate = useNavigate();
  const { logout, user, role, roles, setRole } = useAuth();
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();

  const upSm = useMediaQuery((theme) => theme.breakpoints.up("sm"));

  const handleSetRole = (role) => {
    setRole({
      id: role?.id,
      name: role?.name,
      avatar: role?.avatar,
      permissions: role?.permissions,
    });
    setOpen(false);
    toast.success(t("ProfileMenu.switch_profile_success") + " " + role.name);
    if (role?.id === INSTRUCTOR_ROLE_ID) {
      navigate("/quiz/list");
    } else {
      navigate("/");
    }
  };

  const getOtherRoles = () => {
    return roles?.filter((r) => {
      return r.id != role?.id;
    });
  };
  return (
    <Fragment>
      <StyledButtonBase
        disableRipple
        ref={anchorRef}
        onClick={() => setOpen(true)}
      >
        <Badge
          overlap="circular"
          variant="dot"
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "right",
          }}
          sx={{
            alignItems: "center",
            "& .MuiBadge-badge": {
              width: 11,
              height: 11,
              right: "4%",
              borderRadius: "50%",
              border: "2px solid #fff",
              backgroundColor: "success.main",
            },
          }}
        >
          {upSm && (
            <Small mx={1} color="text.secondary">
              {t("Login.hi")},{" "}
              <Small fontWeight="600" display="inline">
                {user?.name || "----"}
              </Small>
            </Small>
          )}
          <AppAvatar
            src={role?.avatar}
            sx={{
              width: 28,
              height: 28,
            }}
          />
        </Badge>
      </StyledButtonBase>
      <PopoverLayout
        hiddenViewButton
        maxWidth={230}
        minWidth={200}
        popoverOpen={open}
        anchorRef={anchorRef}
        popoverClose={() => setOpen(false)}
        title={
          <FlexBox alignItems="center" gap={1}>
            <AppAvatar
              src={role?.avatar}
              sx={{
                width: 35,
                height: 35,
              }}
            />

            <Box>
              <H6>{user?.name || "---"}</H6>
              <Tiny display="block" fontWeight={500} color="text.disabled">
                {role?.name || "---"}
              </Tiny>
            </Box>
          </FlexBox>
        }
      >
        <Box pt={1}>
          {/* <StyledSmall onClick={() => handleMenuItem("/dashboards/profile")}>Set Status</StyledSmall>

          <StyledSmall onClick={() => handleMenuItem("/dashboards/profile")}>Profile & Account</StyledSmall>

          <StyledSmall onClick={() => handleMenuItem("/dashboards/account")}>Settings</StyledSmall>

          <StyledSmall onClick={() => handleMenuItem("/dashboards/team-member")}>Manage Team</StyledSmall> */}
          {getOtherRoles()?.map((otherRole, index) => (
            <Tooltip
              title={t("ProfileMenu.switch_profile")}
              placement="top"
              arrow
              key={index}
            >
              <FlexBox
                alignItems="center"
                gap={1}
                sx={{ cursor: "pointer", padding: "16px" }}
                onClick={() => handleSetRole(otherRole, index)}
              >
                <AppAvatar
                  src={otherRole?.avatar}
                  sx={{ width: 35, height: 35 }}
                />
                <Box>
                  <H6>{user?.name || "---"}</H6>
                  <Tiny display="block" fontWeight={500} color="text.disabled">
                    {otherRole?.name || "---"}
                  </Tiny>
                </Box>
              </FlexBox>
            </Tooltip>
          ))}
          <Divider
            sx={{
              my: 1,
            }}
          />

          <Grid container item alignItems="center" alignContent={"center"}>
            <Grid
              item
              container
              alignItems="center"
              alignContent={"center"}
              xs={10}
            >
              <StyledSmall
                onClick={() => {
                  logout();
                  toast.success("You Logout Successfully");
                }}
              >
                {t("Login.signout")}
              </StyledSmall>
            </Grid>
            <Grid
              item
              container
              justifyContent="right"
              alignItems="center"
              xs={2}
              pr={2}
            >
              <LogoutIcon
                sx={{ fontSize: "16px", display: "inline-block" }}
                onClick={() => {
                  logout();
                  toast.success("You Logout Successfully");
                }}
              />
            </Grid>
          </Grid>
        </Box>
      </PopoverLayout>
    </Fragment>
  );
};

export default ProfilePopover;
