import { CssBaseline, ThemeProvider } from "@mui/material";
import StyledEngineProvider from "@mui/material/StyledEngineProvider";
import RTL from "components/RTL";
import useSettings from "hooks/useSettings";
import { useRoutes } from "react-router-dom";
import routes from "routes";
import { createCustomTheme } from "theme";
import "./i18n";
import { Toaster } from "react-hot-toast";
import { useEffect } from "react";
import useAuth from "hooks/useAuth";
import { Helmet } from "react-helmet";
// import { QueryClient, QueryClientProvider } from "@tanstack/react-query";

// Create a client
// const queryClient = new QueryClient();

const App = () => {
  const content = useRoutes(routes());
  const { settings, themeColor, refreshCompanySettings } = useSettings();

  const { isAuthenticated } = useAuth();

  useEffect(() => {
    if (isAuthenticated) {
      refreshCompanySettings();
    }
  }, [isAuthenticated]);

  const theme = createCustomTheme({
    theme: settings.theme,
    direction: settings.direction,
    responsiveFontSizes: settings.responsiveFontSizes,
    themeColor: themeColor,
  });

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <RTL>
          {/* <QueryClientProvider client={queryClient}> */}
          <CssBaseline />
          <Toaster toastOptions={{ duration: 5000 }} />
          {window.location.hostname.includes("hrsurvey") && (
            <Helmet>
              <title>Positeams ASSESS</title>
            </Helmet>
          )}

          {content}
          {/* </QueryClientProvider> */}
        </RTL>
      </ThemeProvider>
    </StyledEngineProvider>
  );
};

export default App;
