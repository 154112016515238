import React from "react";
import * as FileSaver from "file-saver";
import XLSX from "sheetjs-style";
import { Button } from "@mui/material";
import FileDownloadIcon from "@mui/icons-material/FileDownload";
import { useTranslation } from "react-i18next";

const ExportExcel = ({ coverData, excelData, fileName, disabled }) => {
  const { t } = useTranslation();

  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; charset=UTF-8";
  const fileExtension = ".xlsx";
  const exportToExcel = async () => {
    const ws = XLSX.utils.json_to_sheet(excelData);
    const ws2 = XLSX.utils.json_to_sheet(coverData);
    const wb = {
      Sheets: { cover: ws2, data: ws },
      SheetNames: ["cover", "data"],
    };
    const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
  };

  return (
    <Button
      variant="contained"
      disabled={disabled}
      startIcon={<FileDownloadIcon />}
      onClick={() => exportToExcel(fileName)}
    >
      {t("Reports.export")}
    </Button>
  );
};

export default ExportExcel;
