import { Card, Grid, Stack, Box, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import AccountCheck from "page-sections/student/login/accountCheck";
import PasswordCheck from "page-sections/student/login/passwordCheck";
import skillupLogo from "assets/skillup.png";
import PositeamsLogo from "assets/positeams-logo.png";
import useSettings from "hooks/useSettings";

const StudentLogin = () => {
  const [LoginIsValide, setLoginIsValide] = useState(false); // LoginIsValide is 'true' means that the user has a valid employee_id or email
  const [user, setUser] = useState(null); // user informations
  const { setThemeColor } = useSettings();

  useEffect(() => {
    if (window.location.hostname.includes("hrsurvey")) {
      setThemeColor("#e65640");
    }
  }, []);

  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={12} md={4}>
        <Card sx={{ padding: 3, marginTop: 5 }}>
          <Stack gap={2} pt={2}>
            <div align="center">
              {window.location?.hostname?.includes("hrsurvey") ? (
                <img alt="company logo" width="200" src={skillupLogo}></img>
              ) : (
                <img alt="company logo" width="200" src={PositeamsLogo}></img>
              )}
            </div>

            <Box textAlign="center" mb={1}>
              <Typography variant="body1">
                Votre espace de validation des connaissances
              </Typography>
            </Box>
            {!LoginIsValide ? (
              <AccountCheck
                setLoginIsValide={setLoginIsValide}
                setUser={setUser}
              />
            ) : (
              <PasswordCheck user={user} />
            )}
          </Stack>
        </Card>
      </Grid>
    </Grid>
  );
};

export default StudentLogin;
