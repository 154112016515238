import LoadingScreen from "components/LoadingScreen";
import useSettings from "hooks/useSettings";
import DashboardLayoutV1 from "layouts/layout-v1/DashboardLayout";
import LayoutV2 from "layouts/layout-v2/LayoutV2";
import LayoutStudent from "layouts/student";
import LayoutStudentQuiz from "layouts/studentQuiz";
import DashboardLayoutV3 from "layouts/layout-v3/DashboardLayout";
import AuthGuard from "page-sections/authentication/AuthGuard";
import StudentAuthGuard from "page-sections/authentication/StudentAuthGuard";
import GuestGuard from "page-sections/authentication/GuestGuard";
import { lazy, Suspense } from "react";
import AddModule from "./pages/module/add";
import EditModule from "./pages/module/edit";
import AddQuestion from "./pages/questions/add";
import EditQuestion from "./pages/questions/edit";
import Reports from "./pages/reports/index";
import { Navigate } from "react-router-dom";

const Loadable = (Component) => (props) => {
  return (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );
}; // dashboards

//forbiden
const Forbidden = Loadable(lazy(() => import("./pages/forbidden/index")));

// student
const StudentLogin = Loadable(lazy(() => import("./pages/student/login")));
const QuizPostLogin = Loadable(
  lazy(() => import("./pages/student/quiz/postlogin"))
);
const QuizIntro = Loadable(lazy(() => import("./pages/student/quiz/intro")));
const QuizStart = Loadable(lazy(() => import("./pages/student/quiz/start")));
const StudentResults = Loadable(
  lazy(() => import("./pages/student/results/index"))
);
const StudentAnswers = Loadable(
  lazy(() => import("./pages/student/results/answers"))
);

// dashboard
const Dashboard = Loadable(lazy(() => import("./pages/dashboard/index")));

// curriculum
const CurriculumList = Loadable(lazy(() => import("./pages/curriculum/list")));
const AddCurriculum = Loadable(lazy(() => import("./pages/curriculum/add")));
const EditCurriculum = Loadable(lazy(() => import("./pages/curriculum/edit")));

// firm
const FirmList = Loadable(lazy(() => import("./pages/firm/list")));
const AddFirm = Loadable(lazy(() => import("./pages/firm/add")));
const EditFirm = Loadable(lazy(() => import("./pages/firm/edit")));

// instructor
const InstructorList = Loadable(lazy(() => import("./pages/instructor/list")));
const AddInstructor = Loadable(lazy(() => import("./pages/instructor/add")));
const EditInstructor = Loadable(lazy(() => import("./pages/instructor/edit")));
const EditInternalInstructor = Loadable(
  lazy(() => import("./pages/instructor/editInternal"))
);

// training group
const TrainingGroupList = Loadable(
  lazy(() => import("./pages/training-group/list"))
);
const AddTrainingGroup = Loadable(
  lazy(() => import("./pages/training-group/add"))
);
const EditTrainingGroup = Loadable(
  lazy(() => import("./pages/training-group/edit"))
);

// quiz
const QuizList = Loadable(lazy(() => import("./pages/quiz/list")));
const QuizCorrections = Loadable(
  lazy(() => import("./pages/corrections/corrections"))
);
const QuizResponses = Loadable(
  lazy(() => import("./pages/corrections/responses"))
);
const AddQuiz = Loadable(lazy(() => import("./pages/quiz/add")));
const EditQuiz = Loadable(lazy(() => import("./pages/quiz/edit")));

// modules
const ModuleList = Loadable(lazy(() => import("./pages/module/list")));

// topics
const TopicList = Loadable(lazy(() => import("./pages/topic/list")));
const AddTopic = Loadable(lazy(() => import("./pages/topic/add")));
const EditTopic = Loadable(lazy(() => import("./pages/topic/edit")));

// questions
const QuestionList = Loadable(lazy(() => import("./pages/questions/list")));

// settings
const Settings = Loadable(lazy(() => import("./pages/settings/settings"))); // account

// user manager
const AccessManagement = Loadable(
  lazy(() => import("./pages/access-management"))
); // account

// roles
const Roles = Loadable(lazy(() => import("./pages/roles/index")));

// login
const SelectRole = Loadable(
  lazy(() => import("./pages/authentication/selectrole"))
);

const Login = Loadable(lazy(() => import("./pages/authentication/login")));
const MFALogin = Loadable(
  lazy(() => import("./pages/authentication/mfalogin"))
);
const LoginV2 = Loadable(lazy(() => import("./pages/authentication/login-v2")));
const Register = Loadable(
  lazy(() => import("./pages/authentication/register"))
);
const RegisterV2 = Loadable(
  lazy(() => import("./pages/authentication/register-v2"))
);
const NewPassword = Loadable(
  lazy(() => import("./pages/student/new-password"))
);
const ForgetPassword = Loadable(
  lazy(() => import("./pages/student/forget-password"))
);
const ForgetPasswordV2 = Loadable(
  lazy(() => import("./pages/authentication/forget-password-v2"))
);
const TwoStepVerify = Loadable(
  lazy(() => import("./pages/authentication/two-step-verification"))
);

const Error = Loadable(lazy(() => import("./pages/404")));

const ActiveLayout = () => {
  const { settings } = useSettings(); // console.log(settings);

  switch (settings.activeLayout) {
    case "layout1":
      return <DashboardLayoutV1 />;

    case "layout2":
      return <LayoutV2 />;

    case "layout3":
      return <DashboardLayoutV3 />;

    default:
      return <DashboardLayoutV1 />;
  }
};

const routes = () => {
  return [
    ...authRoutes,
    {
      path: "/participant/quiz",
      element: (
        <StudentAuthGuard>
          <LayoutStudentQuiz />
        </StudentAuthGuard>
      ),
      children: studentQuizRoutes,
    },
    {
      path: "/participant",
      element: (
        <StudentAuthGuard>
          <LayoutStudent />
        </StudentAuthGuard>
      ),
      children: studentResultsRoutes,
    },

    {
      path: "/",
      element: (
        <AuthGuard>
          <ActiveLayout />
        </AuthGuard>
      ),
      children: [
        {
          path: "/",
          element: <Navigate replace to="/dashboard" />,
        },
        {
          path: "/dashboard",
          element: <Dashboard />,
        },
        {
          path: "forbidden",
          children: forbiddenRoutes,
        },
        {
          path: "curriculum",
          children: curriculumRoutes,
        },
        {
          path: "module",
          children: moduleRoutes,
        },
        {
          path: "topic",
          children: topicsRoutes,
        },
        {
          path: "roles",
          children: rolesRoutes,
        },
        {
          path: "firm",
          children: firmRoutes,
        },
        {
          path: "instructor",
          children: instructorRoutes,
        },
        {
          path: "training-group",
          children: trainingGroupRoutes,
        },
        {
          path: "quiz",
          children: quizRoutes,
        },
        {
          path: "question",
          children: questionRoutes,
        },
        {
          path: "reports",
          children: reportsRoutes,
        },
        {
          path: "settings",
          element: <Settings />,
        },
        {
          path: "access-management",
          element: <AccessManagement />,
        },
      ],
    },
    {
      path: "*",
      element: <Error />,
    },
  ];
};

const authRoutes = [
  {
    path: "login",
    element: (
      <GuestGuard>
        <Login />
      </GuestGuard>
    ),
  },
  {
    path: "mfalogin",
    element: (
      <GuestGuard>
        <MFALogin />
      </GuestGuard>
    ),
  },
  {
    path: "selectrole",
    element: <SelectRole />,
  },
  {
    path: "forget-password",
    element: <ForgetPassword />,
  },
  {
    path: "reset",
    element: <NewPassword />,
  },
  {
    path: "login-v2",
    element: <LoginV2 />,
  },
  {
    path: "register",
    element: <Register />,
  },
  {
    path: "register-v2",
    element: <RegisterV2 />,
  },

  {
    path: "forget-password-v2",
    element: <ForgetPasswordV2 />,
  },
  {
    path: "two-step-verification",
    element: <TwoStepVerify />,
  },
];

const reportsRoutes = [
  {
    path: "",
    element: <Reports />,
  },
];

const questionRoutes = [
  {
    path: "list",
    element: <QuestionList />,
  },
  {
    path: "add",
    element: <AddQuestion />,
  },
  {
    path: "edit",
    element: <EditQuestion />,
  },
];

const curriculumRoutes = [
  {
    path: "list",
    element: <CurriculumList />,
  },
  {
    path: "add",
    element: <AddCurriculum />,
  },
  {
    path: "edit",
    element: <EditCurriculum />,
  },
];

const forbiddenRoutes = [
  {
    path: "forbidden",
    element: <Forbidden />,
  },
];

const studentResultsRoutes = [
  {
    path: "",
    element: <StudentResults />,
  },
  {
    path: "answers",
    element: <StudentAnswers />,
  },
];

const studentQuizRoutes = [
  {
    path: "",
    element: <QuizPostLogin />,
  },
  {
    path: "intro",
    element: <QuizIntro />,
  },
  {
    path: "start",
    element: <QuizStart />,
  },
  {
    path: "results",
    element: <StudentResults />,
  },
  {
    path: "answers",
    element: <StudentAnswers />,
  },
];

const firmRoutes = [
  {
    path: "list",
    element: <FirmList />,
  },
  {
    path: "add",
    element: <AddFirm />,
  },
  {
    path: "edit",
    element: <EditFirm />,
  },
];

const instructorRoutes = [
  {
    path: "list",
    element: <InstructorList />,
  },
  {
    path: "add",
    element: <AddInstructor />,
  },
  {
    path: "edit",
    element: <EditInstructor />,
  },
  {
    path: "edit-internal",
    element: <EditInternalInstructor />,
  },
];

const trainingGroupRoutes = [
  {
    path: "list",
    element: <TrainingGroupList />,
  },
  {
    path: "add",
    element: <AddTrainingGroup />,
  },
  {
    path: "edit",
    element: <EditTrainingGroup />,
  },
];

const quizRoutes = [
  {
    path: "list",
    element: <QuizList />,
  },
  {
    path: "corrections",
    element: <QuizCorrections />,
  },
  {
    path: "responses",
    element: <QuizResponses />,
  },
  {
    path: "add",
    element: <AddQuiz />,
  },
  {
    path: "edit",
    element: <EditQuiz />,
  },
];

const moduleRoutes = [
  {
    path: "list",
    element: <ModuleList />,
  },
  {
    path: "add",
    element: <AddModule />,
  },
  {
    path: "edit",
    element: <EditModule />,
  },
];

const topicsRoutes = [
  {
    path: "list",
    element: <TopicList />,
  },
  {
    path: "add",
    element: <AddTopic />,
  },
  {
    path: "edit",
    element: <EditTopic />,
  },
];

const rolesRoutes = [
  {
    path: "",
    element: <Roles />,
  },
];

export default routes;
