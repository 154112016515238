import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import useMutate from "hooks/useMutate";
import DeleteIcon from "@mui/icons-material/Delete";
import toast from "react-hot-toast";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import api from "utils/api";

export default function Delete({ open, toggle, id }) {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const { mutate, isLoading } = useMutate();

  const handleDelete = async () => {
    try {
      await mutate(() => api.deleteQuestion(id));
      navigate("/question/list");
      toast.success(t("Question.delete.success"));
    } catch (e) {
      toast.error(t("Question.delete.fail"));
    }
  };

  return (
    <Dialog
      open={open}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {t("Question.delete_title")}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {t("Question.delete_description")}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        {/* <Button variant="outlined" onClick={toggle}>
          {t("General.cancel")}
        </Button>
        <Button variant="contained" onClick={handleDelete} autoFocus>
          {t("General.delete")}
        </Button> */}
        <Button variant="outlined" onClick={toggle}>
          {t("General.cancel")}
        </Button>
        <Button
          onClick={handleDelete}
          startIcon={<DeleteIcon />}
          variant="contained"
        >
          {t("General.delete")}
        </Button>
      </DialogActions>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isLoading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Dialog>
  );
}
