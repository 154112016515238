import Switch from "@mui/material/Switch";
import FormControlLabel from "@mui/material/FormControlLabel";
import { TabList, TabPanel, TabContext } from "@mui/lab";
import {
  Tab,
  Box,
  Button,
  Card,
  Grid,
  Checkbox,
  Stack,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import { H6, Tiny } from "components/Typography";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import FlexBetween from "components/flexbox/FlexBetween";
import { H3 } from "components/Typography";
import api from "utils/api";
import { stringAvatar } from "utils/utils";
import { lightTheme } from "../../constants";
import FlexBox from "components/flexbox/FlexBox";
import AppAvatar from "components/avatars/AppAvatar";
import Avatar from "@mui/material/Avatar";
import i18n from "i18next";
import AppTextField from "components/input-fields/AppTextField";
import DeleteIcon from "@mui/icons-material/Delete";
import { useFormik } from "formik";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import ModuleTopics from "page-sections/modules/edit/moduleTopics";
import Edit from "icons/Edit";
import toast, { Toaster } from "react-hot-toast";

import * as Yup from "yup"; // styled components
import DoneBy from "components/DoneBy";
import useMutate from "hooks/useMutate";
import RouteAuth from "components/positeams/routeAuth";

import ModulePng from "assets/illustration/curriculum.png";

export default function EditModule() {
  return (
    <RouteAuth>
      <Content />
    </RouteAuth>
  );
}

const Content = () => {
  const [active, setActive] = useState(false);
  const [curriculumList, setCurriculumList] = useState([]);
  const [instructorList, setInstructorList] = useState([]);
  const [tabValue, setTabValue] = useState("1");
  const [moduleTrans, setModuleTrans] = useState([]);
  const [deleteWarning, setDeleteWarning] = useState(false);
  const [currentModule, setCurrentModule] = useState(null);
  const [deleting, setDeleting] = useState(false);
  const location = useLocation();
  const { t } = useTranslation();
  let navigate = useNavigate();

  const { mutate } = useMutate();

  useEffect(() => {
    let cl;
    async function fetchCurriculumData() {
      //1- get curriculums associated with this module
      cl = await api.getModuleCurriculums(location.state.id);
      cl = cl.data.map((element) => {
        return {
          mcid: element.id,
          id: element.curriculum.id,
          active: element.curriculum.active,
        };
      });
      //get only active curricula
      cl = cl.filter((e) => {
        return e.active == true;
      });

      //2- get all curriculums
      let allCurriculums = await api.getCurriculumList();
      allCurriculums = allCurriculums.data?.rows.filter((e) => {
        return e.active == true;
      });

      allCurriculums = allCurriculums.map((e) => {
        return {
          ...e,
          name:
            e.curriculum_trans.find(
              (c) => c.locale == i18n.language.toUpperCase()
            )?.name || "",
        };
      });

      //3- go through all curriculums and update those associated with a property "associated"
      allCurriculums = allCurriculums.map((obj) => {
        return { ...obj, associated: false };
      });
      allCurriculums.forEach((cur) => {
        cl.forEach((c) => {
          if (cur.id == c.id) {
            cur.associated = true;
            cur.mcid = c.mcid;
          }
        });
      });
      setCurriculumList(allCurriculums);
    }

    // async function fetchInstructorData() {
    //   //1- get instructors associated with this module
    //   il = await api.getModuleInstructors(location.state.id);
    //   if (il.data.length == 0) return;
    //   il = il.data.map((element) => {
    //     return {
    //       miid: element.id,
    //       id: element.instructor.id,
    //       firstName: element.instructor.firstName,
    //       lastName: element.instructor.lastName,
    //       company_name: element.instructor.company_name,
    //       active: element.instructor.active,
    //     };
    //   });
    //   //get only active instructors
    //   il = il.filter((e) => {
    //     return e.active == true;
    //   });

    //   //2- get all instructors
    //   let allInstructors = await api.getInstructorList();
    //   allInstructors = allInstructors.data.filter((e) => {
    //     return e.active == true;
    //   });

    //   //3- go through all instructors and update those associated with a property "associated"
    //   allInstructors = allInstructors.map((obj) => {
    //     return { ...obj, associated: false };
    //   });
    //   allInstructors.forEach((cur) => {
    //     il.forEach((c) => {
    //       if (cur.id == c.id) {
    //         cur.associated = true;
    //         cur.miid = c.miid;
    //       }
    //     });
    //   });
    //   setInstructorList(allInstructors);
    // }

    fetchModuleData();
    fetchCurriculumData();
    // fetchInstructorData();
  }, []);

  const handleCloseDeleteWarning = () => {
    setDeleteWarning(false);
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  async function handleConfirmDelete() {
    setDeleting(true);
    try {
      await mutate(() => api.deleteModule(location.state.id));
      toast.success(t("General.message.delete.success"));
      navigate("/module/list");
    } catch (err) {
      toast.error(t("Module.delete_not_allowed"));
    }
    setDeleting(false);
  }

  async function fetchModuleData() {
    let mod = await api.getModule(location.state.id);
    setCurrentModule(mod.data);
    let module_trans = mod.data.module_trans.find(
      (t) => t.locale == i18n.language.toUpperCase()
    );
    // let module_trans = mod?.data?.module_trans?.find((m) => m.locale === i18n.language.toUpperCase())?.name || "";
    setModuleTrans(module_trans);
    initialValues.name = module_trans.name;
    initialValues.description = module_trans.description;
    setActive(mod.data.active);
  }
  async function handleCheckInstructor(e, name, miid) {
    if (e.target.checked) {
      let r = await api.addInstructorToModule({
        moduleId: location.state.id,
        instructorId: e.target.value,
      });
      r.success
        ? toast.success(`Instructor ${name} successfuly added to module`)
        : toast.error("A server error occurred");
      setInstructorList((current) =>
        current.map((obj) => {
          if (obj.id == e.target.value) {
            return { ...obj, associated: true, miid: r.data.id };
          }
          return obj;
        })
      );
    } else {
      setInstructorList((current) =>
        current.map((obj) => {
          if (obj.id == e.target.value) {
            return { ...obj, associated: false };
          }

          return obj;
        })
      );
      let r = await api.deleteModuleInstructor(miid);
      r.success
        ? toast.success(`Instructor ${name} successfuly removed from module`)
        : toast.error("A server error occurred");
    }
  }
  async function handleCheckCurriculum(e, mcid) {
    if (e.target.checked) {
      let r = await api.addModuleToCurriculum({
        moduleId: location.state.id,
        curriculumId: e.target.value,
      });
      r.success
        ? toast.success(`Module successfuly added to curriculum`)
        : toast.error("A server error occurred");
      setCurriculumList((current) =>
        current.map((obj) => {
          if (obj.id == e.target.value) {
            return { ...obj, associated: true, mcid: r.data.id };
          }

          return obj;
        })
      );
    } else {
      setCurriculumList((current) =>
        current.map((obj) => {
          if (obj.id == e.target.value) {
            return { ...obj, associated: false };
          }

          return obj;
        })
      );
      let r = await api.deleteCurriculumModule(mcid);
      r.success
        ? toast.success(`Module successfuly removed from curriculum`)
        : toast.error("A server error occurred");
    }
  }
  const initialValues = {
    name: "",
    description: "",
  };
  const validationSchema = Yup.object().shape({
    name: Yup.string().required("Name is Required!"),
  });

  const { values, errors, handleChange, handleSubmit, touched, isSubmitting } =
    useFormik({
      initialValues,
      validationSchema,
      onSubmit: async (values) => {
        try {
          await mutate(() =>
            api.updateModule(location.state.id, {
              module: { active: active },
              module_trans: [
                {
                  locale: i18n.language.toUpperCase(),
                  name: values.name,
                  description: values.description,
                  id: moduleTrans.id,
                },
              ],
            })
          );
          toast.success(t("General.message.update.success"));
          navigate("/module/list");
        } catch (e) {
          toast.error(t("General.message.update.fail"));
        }
      },
    });

  return (
    <Box pt={2} pb={4}>
      <Toaster />
      <Dialog
        open={deleteWarning}
        onClose={handleCloseDeleteWarning}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {t("Module.delete_title")}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("Module.delete_description")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" onClick={handleCloseDeleteWarning}>
            {t("General.cancel")}
          </Button>
          <Button variant="contained" onClick={handleConfirmDelete} autoFocus>
            {t("General.delete")}
          </Button>
        </DialogActions>
        <Backdrop
          sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
          open={deleting}
        >
          <CircularProgress color="inherit" />
        </Backdrop>
      </Dialog>

      <Grid container spacing={3}>
        <Grid item md={8}>
          <Stack>
            <Stack direction="row" alignItems="center">
              <Edit sx={{ fontSize: "30" }} />
              <H3>{t("Module.edit_module")}</H3>
            </Stack>
            <DoneBy
              person={currentModule?.created_by}
              date={currentModule?.createdAt}
              type="creation"
            />
            <DoneBy
              person={currentModule?.updated_by}
              date={currentModule?.updatedAt}
              type="modification"
            />
          </Stack>
        </Grid>
      </Grid>

      <Grid container pt={3} spacing={3}>
        <Grid item md={7} xs={12}>
          <Card
            sx={{
              padding: 3,
              mb: 3,
            }}
          >
            <form onSubmit={handleSubmit}>
              <Grid item sm={6}></Grid>
              <Grid container spacing={3} alignItems="center">
                <Grid item sm={6} xs={12}>
                  <AppTextField
                    fullWidth
                    name="name"
                    label={t("Module.module_name")}
                    value={values.name}
                    onChange={handleChange}
                    error={Boolean(touched.name && errors.name)}
                    helperText={touched.name && errors.name}
                  />
                </Grid>

                <Grid item md={2}>
                  <FormControlLabel
                    control={
                      <Switch
                        checked={active}
                        onChange={() => {
                          setActive(!active);
                        }}
                      />
                    }
                    label={t("General.active")}
                  />
                </Grid>

                <Grid item sm={8}>
                  <AppTextField
                    value={values.description}
                    multiline
                    fullWidth
                    rows={5}
                    name="description"
                    label={t("Module.module_description")}
                    onChange={handleChange}
                    error={Boolean(touched.description && errors.description)}
                    helperText={touched.description && errors.description}
                    sx={{
                      "& .MuiOutlinedInput-root textarea": {
                        padding: 0,
                      },
                    }}
                  />
                </Grid>

                <Grid container spacing={2} pt={3} justifyContent="right">
                  <Grid item>
                    <Button
                      variant="outlined"
                      startIcon={<DeleteIcon />}
                      onClick={() => setDeleteWarning(true)}
                    >
                      {t("General.delete")}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="outlined"
                      onClick={() => navigate("/module/list")}
                    >
                      {t("Cancel")}
                    </Button>
                  </Grid>
                  <Grid item>
                    <Button type="submit" variant="contained">
                      {t("General.update")}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </form>
          </Card>

          <ModuleTopics moduleId={location.state.id} />
        </Grid>

        <Grid item md={5} xs={12}>
          <Card sx={{ pb: 2 }}>
            <TabContext value={tabValue}>
              <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                <TabList
                  onChange={handleTabChange}
                  aria-label="lab API tabs example"
                >
                  <Tab label={t("Module.associated_curriculum")} value="1" />
                  {/* <Tab label={t("Module.associated_instructor")} value="2" /> */}
                </TabList>
              </Box>
              <TabPanel value="1">
                {curriculumList.map((item) => (
                  <FlexBetween
                    key={item.id}
                    sx={{
                      borderBottom: 1,
                      padding: "1rem 1.5rem",
                      borderColor: (theme) =>
                        lightTheme(theme) ? "grey.200" : "divider",
                      "&:last-of-type": {
                        borderBottom: 0,
                      },
                    }}
                  >
                    <FlexBox alignItems="center">
                      <AppAvatar
                        src={ModulePng}
                        sx={{
                          width: 30,
                          height: 30,
                        }}
                      />

                      <Box ml={1}>
                        <H6>{item.name}</H6>
                      </Box>
                    </FlexBox>

                    <Checkbox
                      checked={item.associated}
                      onChange={(event) =>
                        handleCheckCurriculum(event, item.mcid)
                      }
                      value={item.id}
                    ></Checkbox>
                  </FlexBetween>
                ))}
              </TabPanel>
              <TabPanel value="2">
                {instructorList.map((item) => (
                  <FlexBetween
                    key={item.id}
                    sx={{
                      borderBottom: 1,
                      padding: "1rem 1.5rem",
                      borderColor: (theme) =>
                        lightTheme(theme) ? "grey.200" : "divider",
                      "&:last-of-type": {
                        borderBottom: 0,
                      },
                    }}
                  >
                    <FlexBox alignItems="center">
                      <Avatar
                        {...stringAvatar(item.firstName + " " + item.lastName)}
                      />
                      <Box ml={1}>
                        <H6>{item.firstName + " " + item.lastName}</H6>
                        <Tiny fontWeight={500} mt={0.3}>
                          {item.company_name}
                        </Tiny>
                      </Box>
                    </FlexBox>

                    <Checkbox
                      checked={item.associated}
                      onChange={(event) =>
                        handleCheckInstructor(event, item.lastName, item.miid)
                      }
                      value={item.id}
                    ></Checkbox>
                  </FlexBetween>
                ))}
              </TabPanel>
            </TabContext>
          </Card>
        </Grid>
      </Grid>
      <Backdrop
        sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={isSubmitting}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </Box>
  );
};
