import React, { useRef, useState } from "react";
import * as Yup from "yup";
import { Button, Input, Stack, Typography } from "@mui/material";
import FlexBox from "components/flexbox/FlexBox";
import { useTranslation } from "react-i18next";
import { Form, Formik } from "formik";
import AppTextField from "components/input-fields/AppTextField";
import { toast } from "react-hot-toast";
import api from "utils/api";

const DateField = ({ values, handleChange, touched, errors }) => {
  const { t } = useTranslation();

  const dayInputRef = useRef();
  const monthInputRef = useRef(null);
  const yearInputRef = useRef();

  return (
    <FlexBox
      sx={{
        gap: "4px",
        alignItems: "center",
        fontWeight: 500,
        margin: "8px 0",
        padding: "2px 14px",
        border: "1px solid #8CA9CC",
        borderRadius: "8px",
        minHeight: "40px",
      }}
    >
      <span
        style={{
          color: "#8CA9CC",
        }}
      >
        {t("Student.login.dob")}
        {":"}
      </span>

      <Input
        inputRef={dayInputRef}
        type="number"
        placeholder="JJ"
        name="day"
        value={values.day}
        onChange={(e) => {
          if (e.target.value <= 31) {
            handleChange(e);

            if (e.target.value.toString().length > 1) {
              monthInputRef.current.focus();
            }
          }
        }}
        inputProps={{ style: { textAlign: "center", fontWeight: "500" } }}
        sx={{
          width: "30px",
        }}
        disableUnderline={true}
      />
      {"-"}
      <Input
        inputRef={monthInputRef}
        type="number"
        placeholder="MM"
        name="month"
        value={values.month}
        onChange={(e) => {
          if (e.target.value <= 12) {
            handleChange(e);

            if (e.target.value.toString().length > 1) {
              yearInputRef.current.focus();
            }
          }
        }}
        inputProps={{ style: { textAlign: "center", fontWeight: "500" } }}
        sx={{ width: "30px" }}
        disableUnderline={true}
      />
      {"-"}
      <Input
        inputRef={yearInputRef}
        type="number"
        placeholder="YYYY"
        name="year"
        value={values.year}
        onChange={(e) => {
          if (e.target.value <= 9999) {
            handleChange(e);
          }
        }}
        inputProps={{ style: { textAlign: "center", fontWeight: "500" } }}
        sx={{ width: "45px" }}
        disableUnderline={true}
      />
    </FlexBox>
  );
};

const DateOfBirthForm = ({ setDobIsValid, user }) => {
  const { t } = useTranslation();

  const handleSubmit = async (values) => {
    var date = new Date(
      `${values.year}/${values.month}/${values.day} GMT+00:00 (UTC)`
    );

    if (new Date(user.birth_date).toUTCString() === date.toUTCString()) {
      setDobIsValid(true);
    } else {
      toast.error(t("Login.invalid_login"));
    }
  };

  const validationSchema = Yup.object().shape({
    day: Yup.number().required("date of birth is required!"),
    month: Yup.number().required("date of birth is required!"),
    year: Yup.number().required("date of birth is required!"),
  });

  return (
    <Formik
      initialValues={{
        day: "",
        month: "",
        year: "",
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, errors, handleChange, touched }) => (
        <Form>
          <Stack gap={2}>
            <DateField
              values={values}
              handleChange={handleChange}
              errors={errors}
              touched={touched}
            />

            <Button variant="contained" type="submit">
              {t("General.next")}
            </Button>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

const CreatePasswordForm = ({ user }) => {
  const { t } = useTranslation();

  function refreshPage() {
    window.location.reload(false);
  }

  const handleSubmit = async (values) => {
    const res = await api.studentCreatePassword({
      employee_id: user.id,
      birth_date: user.birth_date,
      password: values.password,
    });

    if (!res.error) {
      toast.success(t("Student.login.password_created"));
      refreshPage();
    }
  };

  const validationSchema = Yup.object().shape({
    password: Yup.string()
      .required("")
      .matches(
        /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[~#?!@$%^&*-]).{8,}$/,
        "INVALID_REGEX"
      ),
    confirmPassword: Yup.string()
      .required("")
      .oneOf([Yup.ref("password"), null], "Passwords don't match."),
  });

  return (
    <Formik
      initialValues={{
        password: "",
        confirmPassword: "",
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
    >
      {({ values, errors, handleChange, touched }) => (
        <Form>
          <Stack gap={2}>
            <Typography variant="body2" sx={{ fontWeight: "500" }}>
              {t("Student.login.create_new_password")}
            </Typography>
            <AppTextField
              fullWidth
              type="password"
              label={t("Student.login.password")}
              name="password"
              onChange={handleChange}
              value={values.password}
              error={Boolean(
                touched.hasOwnProperty("password") &&
                  errors.hasOwnProperty("password")
              )}
            />
            <AppTextField
              fullWidth
              type="password"
              label={t("Student.login.confirm_password")}
              name="confirmPassword"
              onChange={handleChange}
              value={values.confirmPassword}
              error={Boolean(
                touched.hasOwnProperty("confirmPassword") &&
                  errors.hasOwnProperty("confirmPassword")
              )}
              helperText={touched.confirmPassword && errors.confirmPassword}
              sx={{ mb: 1 }}
            />
            {!!errors.password && errors.password === "INVALID_REGEX" && (
              <Typography variant="body2" align="center" color="red" mb={1}>
                {t("Student.login.password_error")}
              </Typography>
            )}
            <Button variant="contained" type="submit">
              {t("General.save")}
            </Button>
          </Stack>
        </Form>
      )}
    </Formik>
  );
};

function LoginWithDateOfBirth({ user }) {
  const [dobIsValid, setDobIsValid] = useState(false); // if the birth date is valid

  return (
    <>
      {!dobIsValid ? (
        <DateOfBirthForm user={user} setDobIsValid={setDobIsValid} />
      ) : (
        <CreatePasswordForm user={user} />
      )}
    </>
  );
}

export default LoginWithDateOfBirth;
